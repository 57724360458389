import React, { useEffect, useRef, useState } from "react";
import ProjectStatusBlogDonutChart from "./ProjectStatusBlogDonutChart";
import { Dropdown } from "react-bootstrap";

const TelecallerInfoCard = (props) => {
  const infoForm = useRef(null);
  const [timeline, setTimeline] = useState("TODAY");
  const [telecallers, setTelecallers] = useState([]);
  const [telecallerInfoData, setTelecallerInfoData] = useState({});
  const [chartData, setChartData] = useState([]);

  const telecallerLabels = [
    // {
    //   id: "total_tele_callers",
    //   title: "Total Tele callers",
    //   color: "#398CED",
    //   count: telecallerInfoData?.totalSalesPersonal,
    // },
    {
      id: "open_leads",
      title: "Open Leads",
      color: "#FF7A00",
      count: telecallerInfoData?.totalOpenLeads,
    },
    {
      id: "interested_leads",
      title: "Interested Leads",
      color: "#15C85C",
      count: telecallerInfoData?.totalInterestedLeads,
    },
    {
      id: "not_interested_leads",
      title: "Not Interested Leads",
      color: "#FFAB20",
      count: telecallerInfoData?.totalNotInterestedLeads,
    },
    {
      id: "not_reachable",
      title: "Not Reachable",
      color: "#920201",
      count: telecallerInfoData?.totalNotReachableLeads,
    },
    {
      id: "call_not_answered",
      title: "Call Not Answered",
      color: "#FDA5A5",
      count: telecallerInfoData?.totalCallNotAnsweredLeads,
    },
    {
      id: "followup",
      title: "Follow Up",
      color: "#C992E3",
      count: telecallerInfoData?.totalFollowUpLeads,
    },
  ];

  const getAllTelecallers = () => {
    props.apis.apiGetEmployee({ role: "SALES" }).then(({ data, total }) => {
      console.log({ data });
      setTelecallers(data.options("profileId", "name"));
    });
  };

  const getTelecallerInfo = (query, params) => {
    let data = {
      ...Object.except(query, "timeline"),
    };

    let payload = {};

    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    // console.log({ query, params, payload, data });
    props.apis.apiGetDashboardTelecallersInfo(data, payload).then((res) => {
      setTelecallerInfoData(res);
      let chartSeries = [];
      let arrMap = [
        // { index: 0, name: "totalSalesPersonal" },
        { index: 1, name: "totalOpenLeads" },
        { index: 2, name: "totalInterestedLeads" },
        { index: 3, name: "totalNotInterestedLeads" },
        { index: 4, name: "totalNotReachableLeads" },
        { index: 5, name: "totalCallNotAnsweredLeads" },
        { index: 6, name: "totalFollowUpLeads" },
      ];
      for (let i = 0; i < arrMap.length; i++) {
        let { index, name } = arrMap[i];
        chartSeries.splice(index, 0, res[name] || 0);
      }
      //   chartSeries.splice(0, 0, res?.totalSalesPersonal);
      //   chartSeries.splice(1, 0, res?.totalOpenLeads);
      //   chartSeries.splice(2, 0, res?.totalInterestedLeads);
      //   chartSeries.splice(3, 0, res?.totalNotInterestedLeads);
      //   chartSeries.splice(4, 0, res?.totalNotReachableLeads);
      //   chartSeries.splice(5, 0, res?.totalCallNotAnsweredLeads);
      //   chartSeries.splice(6, 0, res?.totalFollowUpLeads);
      setChartData(chartSeries);
    });
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = ["telecaller-column-start_date", "telecaller-column-end_date"];
    console.log({ val });
    setTimeline(val);
    if (val === "CUSTOM") {
      showInputs(inputs);
    } else {
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData;

    if (data?.timeline === "CUSTOM") {
      queryData = {
        ...data,
      };
    } else {
      queryData = {
        ...Object.except(data, ["start_date", "end_date"]),
      };
    }
    console.log({ data, queryData });
    getTelecallerInfo(queryData, timeline);
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = ["telecaller-column-start_date", "telecaller-column-end_date"];
    hideInputs(inputs);
    getTelecallerInfo({}, "TODAY");
  };

  useEffect(() => {
    getTelecallerInfo();
    getAllTelecallers();
  }, []);

  props.$setNode({
    props,
    chartData,
  });
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="mt-4 me-4">
            <AppForm
              ref={infoForm}
              onSubmit={(...args) => onSearch(...args)}
              className="justify-content-end"
            >
              <div
                className="col-12 col-md-3 col-lg-3 col-xl-2"
                id="column-profileId"
              >
                <AppInputFocus
                  type="select"
                  name="profileId"
                  label="Tele caller Name"
                  options={telecallers || []}
                  placeholder="Tele caller Name"
                ></AppInputFocus>
              </div>
              <div
                className="col-12 col-md-3 col-lg-3 col-xl-2"
                id="column-timeline"
              >
                <AppInputFocus
                  type="select"
                  name="timeline"
                  label="Time Line"
                  options={_enum.OVERALL_DETAIL}
                  onChange={(...args) => handleDateChange(...args)}
                  defaultValue={"TODAY"}
                  placeholder="Time Line"
                ></AppInputFocus>
              </div>
              <div
                className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
                id="telecaller-column-start_date"
              >
                <AppInputFocus
                  type="date"
                  name="start_date"
                  label="Start Date"
                  placeholder="Start Date"
                  required={true}
                ></AppInputFocus>
              </div>
              <div
                className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
                id="telecaller-column-end_date"
              >
                <AppInputFocus
                  type="date"
                  name="end_date"
                  label="End Date"
                  placeholder="End Date"
                  required={true}
                ></AppInputFocus>
              </div>
              <div
                className="col-2 col-md-1 col-lg-1 col-xl-1"
                id="telecaller-column-search_btn"
              >
                <button type="submit" className="btn btn-primary search_btn">
                  <i className="fa fa-search"></i>
                  <span>Search</span>
                </button>
              </div>
              <div
                className="col-2 col-md-1 col-lg-1 col-xl-1"
                id="telecaller-column-reset_btn"
              >
                <button
                  type="button"
                  className="btn btn-primary search_btn"
                  onClick={(...args) => resetSearchForm(...args)}
                >
                  <i class="fa-solid fa-filter-circle-xmark"></i>
                  <span>Search</span>
                </button>
              </div>
            </AppForm>
          </div>

          <div className="card-body d-flex flex-wrap align-items-center justify-content-center">
            {chartData && <ProjectStatusBlogDonutChart data={chartData} />}

            <div className="project-date">
              {telecallerLabels.map((data, i) => (
                <div className="project-media" key={i}>
                  <p className="mb-0">
                    <svg
                      className="me-2"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="0.5"
                        width="12"
                        height="12"
                        rx="3"
                        fill={data.color}
                      />
                    </svg>
                    {data.title}
                  </p>
                  <div className="count">
                    <span>
                      {data?.id === "total_tele_callers"
                        ? `${data?.count}`
                        : `${
                            data?.count === undefined
                              ? "-"
                              : `${data?.count} Leads`
                          }`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default constore(TelecallerInfoCard);
