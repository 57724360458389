import React from 'react'

function AppCard(props) {
    return (
        <div className={Object.className({
            'appcard card flex-fill':true,
            [props?.className||'']:true
        })}>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}

export default AppCard