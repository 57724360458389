export const CollectionsMenuList = [
  {
    title: "Dashboard",
    iconStyle: imagepaths.Dashboard,
    iconActive: imagepaths.DashboardActive,
    to: "/app/home",
    id: ["home"],
  },
  {
    title: "Loan Details",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loandetails",
    id: ["loandetails"]
  },
];
