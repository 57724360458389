import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { InterestRateModal } from "./InterestRateModal";

const InterestRate = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Bank Name",
      name: "bankName",
      type: "select",
      options: [],
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Product Type",
      name: "productType",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Interest Type",
      name: "interestType",
      type: "select",
      options: _enum.INTEREST_TYPES,
      placeholder: "Please Enter",
    },
    {
      label: "Interest Rate",
      name: "interestRate",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Max Loan Amt",
      name: "loanAmount",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Processing Fee",
      name: "processingFee",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Max Tenure",
      name: "tenure",
      type: "number",
      placeholder: "Please Enter",
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [bankData, setBankData] = useState([]);
  const [targetFileId, setTargetFileId] = useState("");

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return <>{record?.bankDetails?.bankName}</>;
      },
    },
    {
      dataIndex: "productType",
      title: "Product Type",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "interestRate",
      title: "Interest Rate",
    },
    {
      dataIndex: "interestType",
      title: "Interest Type",
    },
    {
      dataIndex: "processingFee",
      title: "Processing Fee",
      render: (text) => {
        return <>{text.cur()}</>;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Max Loan Amt",
      render: (text) => {
        return <>{text.cur()}</>;
      },
    },
    {
      dataIndex: "tenure",
      title: "Max Tenure",
    },
  ];

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getBankNames = () => {
    let payload = {
      ...search,
    };
    // props.apis.apiGetBanknames(payload).then(({ data, count }) => {
    //   // console.log({apiGetBanknames: data});
    //   let bankNameField = modalFields.find((o) => o.name == "bankName");
    //   bankNameField.options = data.options("bankId", "bankName");
    //   setModalFields([...modalFields]);
    //   setBankData(data);
    //   setRowcount(count);
    // });
  };

  const getProductTypes = () => {
    // props.apis.apiGetProductTypes().then((res) => {
    //   let productType = modalFields.find((o) => o.name == "productType");
    //   productType.options = res.options("productType");
    //   setModalFields([...modalFields]);
    // });
  };

  const getInterestRates = () => {
    let payload = {
      ...search,
    };
    // props.apis.apiGetInterestRates(payload).then(({ data, count }) => {
    //   console.log({ apiGetInterestRates: data });
    //   setTableData(data);
    //   setIsFetching(false);
    // });
    setTableData([]);
    setIsFetching(false);
  };
  const viewInterestRate = (record) => {
    openModal();
    setEditmode(false);
    setViewmode(true);
    // GET INTEREST-RATES BY ID
    // props.apis
    //   .apiGetInterestRateById({ interestRateId: record.interestRateId })
    //   .then((data) => {
    //     console.log({ apiGetInterestRateById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ data, modalFields, filteredData });
    //     filteredData.bankName = data?.bankId;
    //     setPopupdata(filteredData);
    //     setEditData(data);
    //   });
  };

  const editInterestRate = (record) => {
    console.log({ editInterestRate: record.interestRateId });
    setEditmode(true);
    setViewmode(false);

    // GET INTEREST RATE BY ID
    // props.apis
    //   .apiGetInterestRateById({ interestRateId: record.interestRateId })
    //   .then((data) => {
    //     console.log({ apiGetInterestRateById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ data, modalFields, filteredData });
    //     filteredData.bankName = data?.bankId;
    //     setPopupdata(filteredData);
    //     setEditData(data);
    //     openModal();
    //   });
  };

  const deleteInterestRate = (record) => {
    console.log({ deleteInterestRate: record.interestRateId });
    swal2
      .fire({
        title: "Are you sure you want to delete this location?",
        icon: "warning",
        iconColor: "#FF4D4D",
        showCancelButton: true,
        confirmButtonColor: "#FF4D4D",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // props.apis
          //   .apiDeleteInterestRate({ interestRateId: record.interestRateId })
          //   .then((res) => {
          //     console.log({ apiDeleteInterestRate: res });
          //     swal2.fire({
          //       title: "Interest Rate Deleted",
          //       text: "Interest Rate deleted successfully !!",
          //       icon: "success",
          //       iconColor: "#FF4D4D",
          //       timer: 4000,
          //     });
          //     getInterestRates();
          //   });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    // console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };

    console.log({ payload });

    // if (editmode) {
    //   props.apis
    //     .apiUpdateInterestRate(payload, {
    //       interestRateId: editData?.interestRateId,
    //     })
    //     .then((res) => {
    //       console.log({ apiUpdateInterestRate: res });
    //       swal2.fire({
    //         title: "Interest Rate updated successfully.",
    //         icon: "success",
    //         iconColor: "#FF4D4D",
    //         timer: 4000,
    //       });
    //       closeModal();
    //       getInterestRates();
    //     });
    // } else {
    //   let addPayload = {
    //     ...Object.except(formdata, ["bankName", "fileId"]),
    //   };
    //   addPayload.bankId = Number(data.bankName);

    //   console.log({ bankData });

    //   // let targetobj = bankData?.filter(
    //   //   (v) => v.bankId === Number(data.bankName)
    //   // );
    //   // let selectedId = targetobj[0].fileId;
    //   // let selectedBank = targetobj[0].bankName;

    //   // console.log({ targetobj, selectedId });

    //   // addPayload.fileId = selectedId;

    //   console.log({ apiAddInterestRate: addPayload });

    //   props.apis.apiAddInterestRate(addPayload).then((res) => {
    //     console.log({ apiAddInterestRate: res });
    //     swal2.fire({
    //       title: "Interest Rate added successfully.",
    //       icon: "success",
    //       iconColor: "#FF4D4D",
    //       timer: 4000,
    //     });
    //     closeModal();
    //     getInterestRates();
    //   });
    // }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  useEffect(() => {
    getBankNames();
  }, [openCanvas]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getInterestRates();
    getBankNames();
    getProductTypes();
  }, []);

  props.$setNode({
    props,
    bankData,
    targetFileId,
  });

  return (
    <>
      <div className="page_header mb-3 text-end">
        <Link
          to={"#"}
          className="btn btn-primary btn-sm ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          + Add New
        </Link>
      </div>
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(...args) => editInterestRate(...args)}
        viewAction={(e) => viewInterestRate(e)}
        deleteAction={(e) => deleteInterestRate(e)}
        loading={isFetching}
      ></AppTable>

      <InterestRateModal
        id="interestrateCanvas"
        title={`${
          editmode
            ? "Edit Interest Rate"
            : viewmode
            ? "View Interest Rate"
            : "Add Interest Rate"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Add"}`}
        disableallfields={viewmode ? true : false}
      />
    </>
  );
};

export default constore(InterestRate);
