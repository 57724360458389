import React, { useEffect, useState } from "react";
import FlexCard from "../../LoanApplications/Helpers/FlexCard";
import { Link } from "react-router-dom";
import IncomeDetailsModal from "./IncomeDetailsModal";
import { Button, Form, Modal } from "react-bootstrap";

const IncomeDetails = (props) => {
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  var tabStatus = props.urlparams.params.status;
  const frompath = props?.urlparams?.params?.frompath;
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  const [incomedetails, setIncomedetails] = useState({});
  const [bsaReportURL, setBsaReportURL] = useState(null);
  const [bsaReportData, setBsaReportData] = useState(null);
  const [summaryScoreData, setSummaryScoreData] = useState(null);
  const [show, setShow] = useState(false);
  const [payslipfiles, setPayslipfiles] = useState([]);
  const [bankStatementFiles, setBankStatementFiles] = useState([]);
  const [passcheck, setPasscheck] = useState(false);
  const [defaultCompanies, setDefaultCompanies] = useState([]);

  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [bsaTableData, setBsaTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [bsaRowcount, setBsaRowcount] = useState(0);
  const bsaColumns = [
    {
      dataIndex: "bankname",
      title: "Bank Name",
    },
    {
      dataIndex: "statementUploaded",
      title: "Statement Uploaded",
      render: (text, record) => {
        if (record?.fileId) {
          return (
            <div>
              <a href={`${BASE_URL}${record?.fileId}`} className="text-primary">
                Download
              </a>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      dataIndex: "filePassword",
      title: "File Password",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "startDate",
      title: "From Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        if (record?.startDate) {
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "endDate",
      title: "To Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        if (record?.endDate) {
          return date;
        } else {
          return "-";
        }
      },
    },
  ];
  const payslipFields = [
    {
      name: "payslipDate",
      label: "Select Month",
      type: "month",
    },
    {
      name: "fileId",
      label: "Upload",
      type: "file",
      fileLabel: "Upload pdf",
      accept: "application/pdf",
      foldername: "payslipDocs",
    },
  ];

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setPasscheck(false);
    setShow(false);
  };

  const handlePasswordCheck = (e) => {
    let value = e.target.checked;
    setPasscheck(value);
  };

  const deleteField = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    let data = [...payslipfiles];
    data.splice(index, 1);
    console.log({ index, data });
    setPayslipfiles(data);
  };

  const handlePayslipSubmit = (e, data) => {
    let paydate = new Date(data?.payslipDate);
    let payslipDate = new Date(paydate);
    payslipDate.setMonth(payslipDate.getMonth() + 1);

    let payload = {
      ...data,
      payslipDate: new Date(payslipDate).toISOString(),
    };
    console.log({ data, payload });

    let mokeData = [
      {
        payslipDate: "2024-03-01T00:00:00.000Z",
        fileId: "9c08b7cf-ff3f-4904-a6c3-202124d2e561",
        filename: "Month 4 - Pay Slip",
        filePassword: null,
      },
    ];
    setPayslipfiles((prevState) => [...prevState, ...mokeData]);
    handleClose();
  };
  // ----------------------------------------------------------------

  const [modalFields, setModalFields] = useState([
    {
      name: "companyname",
      label: "Company Name",
      type: "select",
      options: [],
      searchFn: (value) => handleCompanyNameSearch(value),
    },
    {
      name: "salaryMode",
      label: "Mode of Salary",
      type: "select",
      options: _enum.MODE_OF_SALARY,
    },
    {
      name: "monthlySalary",
      label: "Monthly Gross Salary",
      type: "number",
    },
    {
      name: "officialEmail",
      label: "Office Email ID",
      type: "text",
    },
    {
      name: "companyCategory",
      label: "Company category ",
      type: "select",
      options: _enum.COMPANY_CATEGORY,
    },
    {
      name: "applicantJobProfile",
      label: "Applicant's Designation",
      type: "text",
    },
    {
      name: "doj",
      label: "DOJ",
      type: "date",
    },
    {
      name: "pfDeducted",
      label: "PF/Others Deducted ",
      type: "select",
      onChange: (...args) => handlePfDeducted(...args),
      options: _enum.YES_OR_NO_BOOLEAN,
    },
    {
      name: "pfAmountDeducted",
      label: "PF/Others Amount Deducted ",
      type: "number",
    },
    {
      name: "monthlyNetTakeSalary",
      label: "Monthly Net Take Salary ",
      type: "number",
    },
    {
      name: "bankName",
      label: "Bank Name (as per Pay Slip)",
      type: "text",
    },
    {
      name: "finalNetSalary",
      label: "Final Net Salary",
      type: "number",
    },
    {
      name: "salaryCreditDate",
      label: "Salary Credit Date",
      type: "date",
    },
    {
      name: "avgMonthlyBalance",
      label: "Avg. Monthly Balance",
      type: "number",
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "text",
      customcolspan: 12,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });

  const openModal = (modalname) => {
    setOpenCanvas(true);
    addModalFields(modalname);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const addModalFields = (modalname) => {
    let fields;
    if (modalname === "companyModal") {
      fields = (modalFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
      );
    }
    if (modalname === "bankAccountModal") {
      fields = (bankAccountFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
      );
    }

    let formdata = Object.map(fields, (v, k) => "");
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const editSalaryInfo = (e, record) => {
    setEditmode(true);
    setViewmode(false);

    console.log({ record });

    let filteredData = Object.only(
      record,
      modalFields.map((v) => v.name)
    );
    console.log({ record, filteredData });

    setPopupdata(filteredData);
    setEditData(record);
    let updatedFields = modalFields?.map((field) => {
      if (field.name === "pfAmountDeducted") {
        if (filteredData?.pfDeducted === false) {
          return {
            ...field,
            defaultValue: "",
            disabled: true,
          };
        } else {
          return {
            ...field,
            defaultValue: filteredData[field?.name],
          };
        }
      }
      if (field.name === "companyname" || field.name === "salaryMode") {
        if (
          !field.options.find(
            (option) => option.key === filteredData[field?.name]
          )
        ) {
          field.options = [
            ...field.options,
            {
              key: filteredData[field?.name],
              label: filteredData[field?.name],
            },
          ];
        }
        return {
          ...field,
          defaultValue: filteredData[field?.name],
          disabled: true,
        };
      }
      return {
        ...field,
        defaultValue: filteredData[field?.name],
      };
    });
    setModalFields([...updatedFields]);
    openModal("companyModal");
  };

  useEffect(() => {
    // console.log({ popupfields });

    // let companyNameInput = document.querySelector(
    //   "[name='appinput-companyname'].form-group"
    // );
    // console.log(companyNameInput);

    let filteredData = Object.only(
      editData,
      modalFields.map((v) => v.name)
    );
    let updatedFields = modalFields?.map((field) => {
      if (field.name === "companyname") {
        let companyNameInput = document.querySelector(
          "[name='appinput-companyname'].form-group"
        );
        if (companyNameInput) {
          let companySelect = companyNameInput?.vnode.refs.select;
          // console.log(popupdata, companyNameInput, companySelect);

          if (companySelect && companySelect?.state?.values?.length === 0) {
            let value = filteredData[field.name];
            let payload = {
              companyName: value,
            };
            if (value?.length >= 3) {
              props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
                if (data) {
                  let companyNameInput = document.querySelector(
                    "[name='appinput-companyname'].form-group"
                  );
                  let companySelect = companyNameInput.vnode.refs.select;
                  let { searchResults } = companySelect.state;
                  let datawithkey = data?.options("companyName");
                  // console.log({ searchResults, datawithkey });
                  searchResults = datawithkey;

                  companySelect.setState(
                    {
                      searchResults,
                      values: datawithkey,
                    },
                    () => {
                      // reset search results with default company names
                      companySelect.setState({
                        searchResults: defaultCompanies.options("companyName"),
                        values: datawithkey,
                      });
                    }
                  );
                }
              });
            }
          }
        }
      }
      return {
        ...field,
        defaultValue: filteredData[field?.name],
      };
    });
    setModalFields([...updatedFields]);
  }, [popupfields]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  const handleCompanyNameSearch = (params) => {
    console.log({ handleCompanyNameSearch: params });
    let value = params?.state?.search;
    let payload = {
      companyName: value,
    };
    if (value?.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-companyname'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            console.log({ searchResults, datawithkey });
            searchResults = datawithkey;

            companySelect.setState({ searchResults });
          }
        });
      }, 1000);
    }
  };

  const handlePfDeducted = (val) => {
    let value = val[0]?.key;
    // console.log({ value });
    let input = document.querySelector('[name="pfAmountDeducted"]');
    if (value === false) {
      input.disabled = true;
      input.value = 0;
    } else {
      console.log(editData);
      input.disabled = false;
      input.value = "";
      // input.value = editData?.pfAmountDeducted;
      // input.defaultValue = editData?.pfAmountDeducted;
    }
  };

  const getCompanies = () => {
    props.apis.apiGetMasterCompanies().then(({ data }) => {
      // console.log({ data });
      if (data) {
        setDefaultCompanies(data);
        let companyNameField = modalFields.find(
          (field) => field.name === "companyname"
        );
        companyNameField.options = data.options("companyName");
        setModalFields([...modalFields]);
      }
    });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...Object.except(data, ["pfAmountDeducted"]),
      pfDeducted: data?.pfDeducted === "true" ? true : false,
      doj: new Date(data.doj).toISOString(),
      salaryCreditDate: new Date(data.salaryCreditDate).toISOString(),
    };
    if (data?.pfDeducted === "true") {
      payload.pfAmountDeducted = data?.pfAmountDeducted;
    } else {
      // delete payload.pfAmountDeducted;
      payload.pfAmountDeducted = "0";
    }
    console.log({ data, payload, editData });

    props.apis
      .apiUpdateUnderWriterApplicationIncome(payload, {
        applicationSalaryId: editData?.applicationSalaryId,
      })
      .then((res) => {
        console.log({ apiUpdateUnderWriterApplicationIncome: res });
        swal2.fire({
          title: "Income Details updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getUnderwriterInfo();
      })
      .catch((error) => console.log(error));
  };
  // ----------------------------------------------------------------
  const [bankAccountFields, setBankAccountFields] = useState([
    {
      name: "bankName",
      label: "Bank Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "accountNumber",
      label: "Account No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "accountHolderName",
      label: "Account Holder Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "accountType",
      label: "Account Type",
      type: "text",
      readOnly: true,
    },
    {
      name: "bankStatementPeriodFrom",
      label: "Bank Statement Period (From)",
      type: "date",
    },
    {
      name: "bankStatementPeriodTo",
      label: "Bank Statement Period (To)",
      type: "date",
    },
    {
      name: "creditSeen",
      label: "Credit Seen",
      type: "select",
      options: [],
    },
    {
      name: "modeOfCredit",
      label: "Mode Of Credit",
      type: "select",
      options: [],
    },
  ]);

  const editBankInfo = (e, record) => {
    setEditmode(true);
    setViewmode(false);

    setBankAccountFields([...bankAccountFields]);
    openModal("bankAccountModal");
    // setEditmode(true);
    // setViewmode(false);
    // setModalFields([...modalFields]);
    // openModal();
    // props.apis
    //   .apiGetEmployeeById({ profileId: record.profileId })
    //   .then((data) => {
    //     console.log({ apiGetEmployeeById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ filteredData });
    //     setPopupdata(filteredData);
    //     setEditData(data);
    //     let nameField = modalFields.find((o) => o.name == "name");
    //     nameField.disabled = true;
    //     setModalFields([...modalFields]);
    //     openModal();
    //   });
  };

  // ----------------------------------------------------------------
  const [showbankmodal, setShowbankmodal] = useState(false);
  const [showBankStatement, setShowBankStatement] = useState(false);
  const [activeBankStatement, setActiveBankStatement] = useState({});
  const [bankPassCheck, setBankPassCheck] = useState(false);
  const [bankstatementfiles, setBankstatementfiles] = useState({
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
  });
  const [queryinfo, setQueryinfo] = useState([]);

  const addBankStatement = () => {
    setShowBankStatement(true);
  };

  const bankModalBtns = [
    {
      name: "bank_statement_1",
      label: "Bank Statement - 1",
      type: "modalFile",
      index: 1,
      modalTitle: "Month 1 - Bank Statement",
      placeholder: "Month 1 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "bank_statement_2",
      label: "Bank Statement - 2",
      type: "modalFile",
      index: 2,
      modalTitle: "Month 2 - Bank Statement",
      placeholder: "Month 2 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "bank_statement_3",
      label: "Bank Statement - 3",
      type: "modalFile",
      index: 3,
      modalTitle: "Month 3 - Bank Statement",
      placeholder: "Month 3 - Upload pdf",
      customcolspan: 4,
    },
  ];
  const bankFields = [
    {
      name: "bankname",
      label: "Bank Name",
      type: "select",
      options: _enum.STATIC_BANK_NAMES,
    },
    {
      name: "startDate",
      label: "Select Date",
      type: "date",
      placeholder: "From Date",
    },
    {
      name: "fileId",
      label: "Upload",
      type: "defaultfile",
      fileLabel: "Upload pdf",
      foldername: "bankStatementDocs",
      accept: "application/pdf",
    },
  ];

  const handleBSPasswordCheck = (e) => {
    let value = e.target.checked;
    setBankPassCheck(value);
  };

  const handleBankShow = (e, record) => {
    console.log({ record });
    setActiveBankStatement(record);
    setShowbankmodal(true);
  };

  const handleBSClose = (e) => {
    setActiveBankStatement({});
    setShowbankmodal(false);
    setBankPassCheck(false);
    // remove uploaded file from form
    // let fileInput = document.querySelector(
    //   `[name="appinput-fileId"] .filevalue_container`
    // );
    // console.log(editmode, fileInput);
    // if (editmode === false) {
    //   console.log("clear upload data");
    //   if (fileInput && fileInput.value.length > 0) {
    //     props.apis
    //       .apiFileRemover({ fileId: fileInput.value })
    //       .then((res) => {
    //         console.log({ apiFileRemover: res });
    //       })
    //       .catch((err) => {
    //         console.log({ err });
    //       });
    //   }
    // }
  };

  const handleBankStatementSubmit = (e, data) => {
    let payload = {
      ...Object.except(data, ["fileName", "startDate", "endDate"]),
      index: activeBankStatement?.index,
      filename: data?.fileId?.name?.filename() || "",
      startDate: new Date(data?.startDate).toISOString(),
    };
    console.log({ data, payload });
    setBankstatementfiles((prevState) => ({
      ...prevState,
      [`bankStatement${activeBankStatement?.index}`]: payload,
    }));
    setBankPassCheck(true);
    handleBSClose();
  };

  // ----------------------------------------------------------------

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        let obj = Object.groupBy(res, (item) => {
          return item.category;
        });

        setQueryinfo(obj["INCOME_DTLS"] || []);
        // prepareSummaryInfo(res);
      });
  };

  // ----------------------------------------------------------------

  const handleApplicationSubmit = (e, data) => {
    console.log({ data });

    let monthlystatements = [
      bankstatementfiles.bankStatement1,
      bankstatementfiles.bankStatement2,
      bankstatementfiles.bankStatement3,
    ];

    let bankstatements = [...monthlystatements];

    let allKeysHaveValue = [...bankstatements].every((statements) => {
      return (
        Object.keys(statements).length > 0 &&
        Object.values(statements).every(
          (value) => value !== undefined && value !== ""
        )
      );
    });

    console.log({ allKeysHaveValue, bankstatements });

    if (!allKeysHaveValue) {
      console.log("Some keys do not have value in all objects");
      swal2.fire({
        title: "Please fill all required fields",
        icon: "error",
        iconColor: "#DE3232",
        timer: 3000,
        showCancelButton: false,
      });
    } else {
      console.log("All keys have value in all objects");

      // Call the function
      fetchValues(bankstatements, "bankstatement").then((bankstatementData) => {
        console.log({ bankstatementData });
        swal2
          .fire({
            title: "Loan Applied Successfully",
            icon: "success",
            iconColor: "#DE3232",
            confirmButtonColor: "#DE3232",
            confirmButtonText: "Close",
          })
          .then(() => {
            getUnderwriterInfo();
          });
      });
    }
  };

  // convert files to etag and
  async function fetchValues(objects, type) {
    const newObjects = [];

    for (const obj of objects) {
      let reqdata = {
        file: obj.fileId,
      };
      let filePayload = {};
      if (type === "bankstatement") {
        filePayload.foldername = "bankStatementDocs";
        props.apis
          .apiAddCustomerBSA(
            { file: obj.fileId },
            { applicationId: parseInt(props.urlparams.params.id) }
          )
          .then((res) => {})
          .catch((err) => {
            if (err) {
              console.log({ err });
              return;
            }
          });
      }

      const response = await props.apis
        .apiFileUploader(reqdata, filePayload)
        .catch((err) => {
          if (err) {
            console.log({ err });
          }
        });
      const data = await response;

      // Assuming the API response is an object with a single key
      const key = Object.keys(data)[0];
      const value = data[key];

      // Add a new property to the object with the fetched value
      const newObj = { ...obj, fileId: value };
      console.log({ newObj });

      var payload = {
        ...newObj,
      };
      if (type === "bankstatement") {
        const postresponse = await props.apis
          .apiAddCustomerBankStatament(payload)
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
        console.log(postresponse);
      }

      newObjects.push(newObj);
    }
    // console.log(`${type}:`, newObjects);
    return newObjects;
  }
  // ----------------------------------------------------------------

  const getUnderwriterInfo = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res.applicationPayslip.length > 0) {
          setPayslipfiles(res?.applicationPayslip);
        }
        setIncomedetails(res?.ApplicationSalaryInfo[0]);
        if (res.applicationbankStatement.length > 0) {
          setBankStatementFiles(res?.applicationbankStatement);
        }
        if (res.applicationPayslip.length > 0) {
          setPayslipfiles(res.applicationPayslip);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setBsaTableData(bankStatementFiles);
    setIsFetching(false);
    setBsaRowcount(0);
  }, [bankStatementFiles]);

  // ----------------------------------------------------------------
  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };
  const generateReport = () => {
    props.apis
      .apiUnderWriterGenerateBsaReport({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        swal2.fire({
          title: "BSA Report generation started! \n Please check in sometime",
          icon: "success",
          iconColor: "#FF4D4D",
          // timer: 2000,
        });
      })
      .catch((err) => {});
  };

  const fetchBSAStatus = () => {
    props.apis
      .apiUnderWriterVerifyBsaStatus({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res?.report?.reportStatus === "ANALYSED") {
          fetchBSASummary();
          let accountData = Object.only(res?.reportAccounts?.[0]?.account, [
            "accountName",
            "accountNumber",
            "bankName",
            "ifscCode",
            "accountTypeName",
            "startDate",
            "endDate",
          ]);
          setSummaryScoreData({
            ...accountData,
            bankScore: res.report.bankScore,
          });
        } else if (res?.status === "ANALYSIS ON HOLD") {
          swal2.fire({
            title: "BSA Analysis On-Hold! \n Please check with team",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
        } else if (res?.status === "IN PROGRESS") {
          swal2.fire({
            title: "BSA generation In-progress! \n Please check in sometime",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
        } else if (res?.status === "pending") {
          generateReport();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          generateReport();
        }
      });
  };

  const fetchBSASummary = () => {
    props.apis
      .apiUnderWriterGetBsaReport({
        applicationNumber: props.urlparams.params.id,
        // isDownload: false,
      })
      .then((res) => {
        setBsaReportURL(res?.result?.excel);
        // let result = Object.fromEntries(
        //   Object.entries(res?.result?.json).filter(([key, value]) =>
        //     key.startsWith("Summary")
        //   )
        // );
        // result["Summary - Fixed Income / Obligation"] = result[
        //   "Summary - Fixed Income / Obligation"
        // ].filter((summary) => {
        //   if (summary.Type !== "EMI/LOAN") {
        //     return summary;
        //   }
        // });
        // result["Summary - Credit Card"] = result[
        //   "Summary - Credit Card"
        // ].filter((summary) => {
        //   if (summary.Item !== "Payment Due") {
        //     return summary;
        //   }
        // });
        // let data = result["Summary - Scorecard"].reduce((acc, curr) => {
        //   acc[curr.Item] = curr.Details;
        //   return acc;
        // }, {});
        // setBsaReportData(
        //   Object.except(result, [
        //     "Summary - Scorecard",
        //     "Summary - Meta Remarks",
        //   ])
        // );
        // setSummaryScoreData(Object.except(data, ["Account Type", "MICR Code"]));
      })
      .catch((err) => {});
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    getUnderwriterInfo();
    getCompanies();
    getQueries();
    fetchBSAStatus();
  }, []);

  const handleDecision = (e, data) => {
    let parsedPayload = parseInput(data);
    // console.log("formData", data, parsedPayload);
    let payload = {
      ...parsedPayload,
      applicationId: parseInt(props.urlparams.params.id),
      category: "INCOME_DTLS",
    };
    console.log({ payload });
    props.apis
      .apiUnderWriterCreateApplicationQuery(payload)
      .then((res) => {
        swal2.fire({
          title: "Underwriter Decision Updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        props.changeTab(e, "cibil");
      })
      .catch((error) => {});
  };

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  props.$setNode({
    props,
    payslipfiles,
    modalFields,
    editData,
  });

  console.log({ incomedetails });

  return (
    <>
      <div className="card p-4">
        <FlexCard
          title="Salary Info."
          data={{
            applicationId: incomedetails?.applicationSalaryId,
            ...Object.except(incomedetails, [
              "netMonthlyIncome",
              "remarks",
              "finalNetSalary",
              "salaryCreditDate",
              "applicationSalaryId",
            ]),
            pfDeducted: incomedetails?.pfDeducted ? "Yes" : "No",
            pfAmountDeducted:
              incomedetails?.pfDeducted === true
                ? incomedetails?.pfAmountDeducted?.cur()
                : "-",
            monthlySalary: incomedetails?.monthlySalary?.cur(),
            monthlyNetTakeSalary: incomedetails?.monthlyNetTakeSalary?.cur(),
            doj:
              incomedetails?.doj !== null
                ? new Date(incomedetails?.doj).dateToDDMMYYYY(
                    new Date(incomedetails?.doj)
                  )
                : "-",
            finalNetSalary: incomedetails?.finalNetSalary?.cur(),
            salaryCreditDate: incomedetails?.salaryCreditDate
              ? new Date(incomedetails?.salaryCreditDate).dateToDDMMYYYY(
                  new Date(incomedetails?.salaryCreditDate)
                )
              : "-",
            avgMonthlyBalance: incomedetails?.avgMonthlyBalance?.cur(),
            remarks: incomedetails?.remarks,
          }}
          editable={
            isReadonly ? false : tabStatus !== "inprogress" ? false : true
          }
          onEdit={(...args) => editSalaryInfo(...args, incomedetails)}
        />
        <hr />
        <div className="form_group_card px-0">
          <div className="d-flex align-items-start justify-content-between">
            <h2 className="mb-3 theme_text_sm">Last 3 months pay slip</h2>
            <button
              type="button"
              className={Object.className({
                "btn btn-primary-transparent": true,
                "d-none": isReadonly,
              })}
              onClick={(...args) => handleShow(...args)}
            >
              + Add Payslip
            </button>
          </div>
          <div className="row g-4">
            {payslipfiles &&
              payslipfiles.map((file, i) => {
                return (
                  <>
                    <div className="col-12 col-md-4 file_download_box">
                      <p className="label_text">Pay slip - {i + 1}</p>
                      <a
                        href={`${BASE_URL}${file?.fileId}`}
                        className="file_download_btn position-relative"
                      >
                        <span>Month {i + 1} - Download pdf</span>
                        <i className="fa fa-download"></i>
                        {i >= 3 && (
                          <button
                            onClick={(...args) => deleteField(...args, i)}
                            className="btn transparent_trash_btn"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        )}
                      </a>
                      {file?.filePassword && (
                        <p className="mt-1">
                          File Password: {file?.filePassword}
                        </p>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      {/* Bank Statement Analyzer */}
      <div className="card p-4">
        <div className="form_group_card px-0">
          <div className="d-flex align-items-start justify-content-between">
            <h2 className="mb-3 theme_text_sm">Bank Statement Analyzer</h2>
            <div className="action_btn_group d-flex align-items-start">
              <button
                type="button"
                className={Object.className({
                  "btn btn-primary-transparent py-0": true,
                  "border-right-0": isReadonly,
                })}
              >
                <i className="fa fa-download me-2"></i>
                Download All
              </button>
              <button
                type="button"
                className={Object.className({
                  "btn btn-primary-transparent py-0": true,
                  "d-none": isReadonly,
                })}
                onClick={(...args) => addBankStatement(...args)}
              >
                + Add Bank Statement
              </button>
            </div>
          </div>
          {/* <div className="row g-4 px-0 mt-3">
            {bankStatementFiles.length > 0 &&
              bankStatementFiles?.map((file, i) => {
                return (
                  <>
                    <div className="col-12 col-md-4 file_download_box">
                      <p className="label_text">Bank Statement - {i + 1}</p>
                      <a
                        href={`${BASE_URL}${file?.fileId}`}
                        className="file_download_btn"
                      >
                        <span>{file.filename}</span>
                        <i className="fa fa-download"></i>
                      </a>
                    </div>
                  </>
                );
              })}
          </div> */}

          <div>
            <AppTable
              data={bsaTableData}
              columns={bsaColumns}
              onChange={(...arg) => onPageChange(...arg)}
              total={bsaRowcount}
              reorder={true}
              editable={false}
              viewable={false}
              deletable={false}
              // actionBtn={true}
              // actionBtnAction={(...args) => fetchBSAStatus(...args)}
              // actionBtnText="Get Report"
              loading={isFetching}
            ></AppTable>
          </div>
        </div>
        <div className="card-footer border-0 text-end">
          <button
            type="button"
            className={Object.className({
              "btn btn-primary": true,
              "d-none": bsaReportData,
            })}
            onClick={(...args) => fetchBSAStatus(...args)}
          >
            Get Report
          </button>
        </div>

        <div
          className={Object.className({
            "card-body p-0": true,
            "d-none": !bsaReportURL,
          })}
        >
          <div className="action_btn_group text-end">
            <button type="button" className="btn btn-primary-transparent">
              <a
                href={`${bsaReportURL}`}
                className="file_download_btn text-primary"
              >
                <i className="fa fa-download me-2"></i>
                Download Excel
              </a>
            </button>
          </div>

          {/* <FlexCard
            className="grey_bordered"
            title="Bank Account Info."
            data={bankinfo}
            editable={
              isReadonly ? false : tabStatus !== "inprogress" ? false : true
            }
            onEdit={(...args) => editBankInfo(...args)}
          /> */}

          {summaryScoreData && (
            <div className="col-12">
              <FlexCard
                className="grey_bordered darker"
                title="Summary – Scorecard"
                data={summaryScoreData}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={Object.className({
          "card p-4": true,
          "d-none": !showBankStatement,
        })}
      >
        <AppForm
          onSubmit={handleApplicationSubmit}
          className="bank_modal_btns_container"
        >
          {bankModalBtns?.map((field, i) => {
            return (
              <div
                className={`col-12 col-md-${field?.customcolspan || 6}`}
                key={i}
              >
                <div className="modal_file_container">
                  <label>
                    {field?.label}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  {Object.keys(bankstatementfiles[`bankStatement${i + 1}`])
                    .length > 0 ? (
                    <>
                      <Button
                        variant="dark"
                        onClick={(...args) => handleBankShow(...args, field)}
                        className="w-100 text-left"
                      >
                        {
                          bankstatementfiles[`bankStatement${i + 1}`][
                            "filename"
                          ]
                        }
                      </Button>
                      {/* <button
                            type="button"
                            onClick={(...args) =>
                              handleFileDownload(
                                ...args,
                                editdata?.applicationPayslip[i]
                              )
                            }
                            className="file_name_btn"
                          >
                            {bankstatementfiles[`bankStatement${i + 1}`]["filename"]}
                            <i class="fa-solid fa-download"></i>
                          </button> */}
                    </>
                  ) : (
                    <Button
                      variant="dark"
                      onClick={(...args) => handleBankShow(...args, field)}
                      className="w-100 text-left"
                    >
                      {field.placeholder}
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
          <div className="card-footer border-0 text-end">
            <button type="submit" className="btn btn-primary">
              Generate Report
            </button>
          </div>
        </AppForm>
      </div>
      {isReadonly ? (
        <div className="nav_container mt-5">
          <Link to={getBankLink()} className="btn btn-primary-lite me-3">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "cibil")}
          >
            Next
          </button>
        </div>
      ) : !isReadonly && tabStatus === "sanctioned" ? (
        <>
          <div className="nav_container mt-5">
            <Link to={getBankLink()} className="btn btn-primary-lite me-3">
              Cancel
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) => props.changeTab(...args, "cibil")}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div
          className={Object.className({
            "d-none": tabStatus === "sanctioned",
          })}
        >
          {queryinfo
            .filter((q) => !q.responseId)
            .map((item) => (
              <>
                <div className="row">
                  <AppInput
                    name="uwDecision"
                    label="Underwriter Decision"
                    type="text"
                    defaultValue={item.uwDecision}
                    outerclass="col-12 col-md-3"
                    readOnly="true"
                  ></AppInput>

                  <AppInput
                    name="messageText"
                    label="Remarks"
                    readOnly="true"
                    defaultValue={item.messageText}
                    type="textarea"
                    outerclass="col-12 col-md-9 mb-3"
                  ></AppInput>
                </div>
              </>
            ))}
          <AppForm
            className={Object.className({
              "mx-auto": true,
              "d-none": isAdmin,
            })}
            onSubmit={(...args) => handleDecision(...args)}
          >
            <AppInput
              name="uwDecision"
              label="Underwriter Decision"
              type="select"
              options={_enum.UNDER_WRITER_DECISION_DISCREPANCY}
              outerclass="col-12 col-md-3"
              required="true"
            ></AppInput>

            <AppInput
              name="messageText"
              label="Remarks"
              type="textarea"
              outerclass="col-12 col-md-9 mb-3"
            ></AppInput>
            <div className="nav_container mt-5">
              <Link
                to={`/app/loanapplications?activetab=${tabStatus}`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      )}
      <IncomeDetailsModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Company Details"
            : viewmode
            ? "View Company Details"
            : "Add Company Details"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />

      {/* PAYSLIP MODAL */}
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Month {payslipfiles.length + 1} - Pay Slip</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handlePayslipSubmit}>
            {payslipFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Form.Check
              type="checkbox"
              id={`default-1`}
              label="File password protected"
              className="ms-3"
              onChange={(...args) => handlePasswordCheck(...args)}
            />
            {passcheck ? (
              <AppInput
                type="text"
                label="Password"
                name="filePassword"
              ></AppInput>
            ) : null}
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>

      {/* BANK-STATEMENT MODAL */}
      <Modal
        show={showbankmodal}
        onHide={(...args) => handleBSClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{activeBankStatement?.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleBankStatementSubmit}>
            {bankFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Form.Check
              type="checkbox"
              id={`default-1`}
              label="File password protected"
              className="ms-3"
              onChange={(...args) => handleBSPasswordCheck(...args)}
            />
            {bankPassCheck ? (
              <AppInput
                type="text"
                label="Password"
                name="filePassword"
              ></AppInput>
            ) : null}
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={(...args) => handleBSClose(...args)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(IncomeDetails);
