import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import DocsCard from "./DocsCard";
import IncomeCard from "./IncomeCard";
import InfoCard from "./InfoCard";
import KycCard from "./KycCard";
import ProfileModal from "./ProfileModal";

const MyProfile = (props) => {
  const [openCanvas, setOpenCanvas] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [myinfo, setMyinfo] = useState(null);
  const { isAadharVerified, isPanVerified } = props?.$store.user;
  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    getInfo();
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    closeModal();
  };

  const handleSubmit = (e) => {
    closeModal();
    swal2.fire({
      title: "Profile Completed successfully",
      icon: "success",
      iconColor: "#FF4D4D",
      timer: 4000,
    });
    navigate("/app/services");
  };

  const onEdit = (e, route) => {
    console.log({ route });
    openModal();
    route === "pan"
      ? setGoSteps(2)
      : route === "aadhar"
      ? setGoSteps(1)
      : route === "incomedetails"
      ? setGoSteps(1)
      : route === "profiledetails"
      ? setGoSteps(0)
      : setGoSteps(0);
  };

  const getInfo = () => {
    props.apis.apiGetCustomerInfo().then((res) => {
      console.log({ res });
      setMyinfo(res);
      if (res?.status !== "COMPLETED") {
        swal2
          .fire({
            title: "Please update your profile",
            icon: "info",
            iconColor: "#FF4D4D",
            confirmButtonText: "Update",
            showCancelButton: true,
            cancelButtonText: "Skip for now",
          })
          .then((result) => {
            if (result.isConfirmed) {
              openModal();
              setGoSteps(0);
            }
          });
      }
    });
  };

  useEffect(() => {
    getInfo();
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="My Profile"
        pageTitle={"My Profile"}
        parentTitle={"Home"}
        pageIcon={imagepaths.LeadsIconActive}
      />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-xl-12">
            <InfoCard data={myinfo} onEdit={(...args) => onEdit(...args)} />
            <div className="row mx-auto g-0">
              <div className="col-12 col-lg-4">
                <IncomeCard
                  data={myinfo}
                  onEdit={(...args) => onEdit(...args)}
                />
              </div>
              <div className="col-12 col-lg-8">
                <KycCard data={myinfo} onEdit={(...args) => onEdit(...args)} />
              </div>
              <div className="col-12">
                <DocsCard data={myinfo} onEdit={(...args) => onEdit(...args)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal
        id="profileCanvas"
        className="profile_canvas"
        title="Please Verify Below Information"
        show={openCanvas}
        handleSubmit={(...args) => handleSubmit(...args)}
        setGoSteps={setGoSteps}
        goSteps={goSteps}
        editdata={myinfo}
        onClose={() => onModalClose()}
      />
    </>
  );
};

export default constore(MyProfile);
