import React, { Component } from "react";

export class OpenRouteModel extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.OpenRouteModel = this;
  }
  render() {
    let { props } = this;
    let {
      id,
      title,
      onClose,
      modalSize,
      modaltype,
      hidecancelbtn,
      footer,
      okText,
    } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={onClose}
        modalSize={modalSize}
        hidecancelbtn={hidecancelbtn}
        footer={footer}
        okText={okText}
      >
        <div>
          <h4 className="mb-4">Terms of Conditions:</h4>
          <ul className="numbered">
            <li>
              Agreement: By accessing or using Aayan Finserve India Pvt. Ltd.
              ("Aayan Finserve") website, products, or services, you agree to be
              bound by these terms and conditions.
            </li>
            <li>
              Services: Aayan Finserve provides non-banking financial services
              and products subject to the terms outlined herein.
            </li>
            <li>
              Eligibility: You must be eligible under applicable laws to access
              and use our services. Aayan Finserve reserves the right to refuse
              service to anyone for any reason at any time.
            </li>
            <li>
              Accuracy of Information: While we strive to ensure the accuracy of
              information provided, Aayan Finserve does not warrant the
              completeness, reliability, or timeliness of information on its
              website or services.
            </li>
            <li>
              Privacy: By using our services, you consent to the collection and
              use of your personal information as outlined in our Privacy
              Policy.
            </li>
            <li>
              Intellectual Property: All content on the Aayan Finserve website,
              including but not limited to text, graphics, logos, images, and
              software, is the property of Aayan Finserve and protected by
              applicable copyright and other intellectual property laws.
            </li>
            <li>
              Limitation of Liability: Aayan Finserve shall not be liable for
              any direct, indirect, incidental, special, consequential, or
              punitive damages arising out of or related to the use of its
              website or services.
            </li>
            <li>
              Governing Law: These terms and conditions shall be governed by and
              construed in accordance with the laws of India.
            </li>
          </ul>
          <h4 className="mt-3 mb-4">Privacy Policy:</h4>
          <ul className="numbered">
            <li>
              Collection of Information: Aayan Finserve collects personal
              information such as name, address, contact details, and financial
              information for the purpose of providing financial services. We
              may also collect non-personal information such as IP addresses and
              browsing behavior.
            </li>
            <li>
              Use of Information: Personal information collected by Aayan
              Finserve is used to facilitate financial transactions, improve our
              services, and communicate with customers. We do not sell or rent
              personal information to third parties.
            </li>
            <li>
              Security: Aayan Finserve employs industry-standard security
              measures to protect the confidentiality and integrity of personal
              information. However, no method of transmission over the internet
              or electronic storage is 100% secure.
            </li>
            <li>
              Cookies: Aayan Finserve may use cookies and similar technologies
              to enhance user experience and track usage patterns. Users may
              choose to disable cookies in their browser settings, but this may
              affect the functionality of our website.
            </li>
            <li>
              Third-Party Links: Our website may contain links to third-party
              websites or services. Aayan Finserve is not responsible for the
              privacy practices or content of these third parties.
            </li>
            <li>
              Updates to Privacy Policy: Aayan Finserve reserves the right to
              update or modify this Privacy Policy at any time. We encourage
              users to review this policy periodically for changes.
            </li>
            <li>
              Contact Us: If you have any questions or concerns about our
              Privacy Policy, please contact us at [contact information].
            </li>
          </ul>
        </div>
      </AppModalBs>
    );
  }
}

export default OpenRouteModel;
