import React, { useEffect, useState } from "react";

const IncomeDetails = (props) => {
  const toastConfig = {
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: true,
  };
  const [search, setSearch] = useState({
    // page: 1,
    // limit: 10,
  });
  const hasEditData = props.editdata?.officialEmail?.length > 0 ? true : false;
  const [checkValue, setCheckValue] = useState("SALARIED");
  const [defaultCompanies, setDefaultCompanies] = useState([]);

  const [salariedFields, setSalariedFields] = useState([
    {
      name: "companyname",
      label: "Company Name",
      type: "select",
      options: [],
      searchFn: (value) => handleCompanyNameSearch(value),
      onChange: (...args) => handleCompanyChange(...args),
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "newcompanyname",
      label: "Name",
      type: "text",
      placeholder: "Please enter",
      outercolclass: "d-none",
    },
    {
      name: "monthlySalary",
      label: "Monthly Salary",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "salaryMode",
      label: "Mode of Salary",
      type: "select",
      options: _enum.MODE_OF_SALARY,
      defaultValue: props?.editdata?.salaryMode || "",
      placeholder: "Please enter",
    },
    {
      name: "officialEmail",
      label: "Office Email",
      type: "email",
      placeholder: "Please enter",
      required: true,
    },
  ]);

  const [selfEmployedFields, setSelfEmployedFields] = useState([
    {
      name: "netMonthlyIncome",
      label: "Net Monthly Income",
      type: "number",
      placeholder: "Please enter",
    },
    {
      name: "natureOfBusiness",
      label: "Nature of Business",
      type: "text",
      placeholder: "Please enter",
    },
  ]);

  const handleEmploymentType = (e) => {
    e.persist();
    let value = e.target.value;

    setCheckValue(value);
    if (value === "SELF_EMPLOYED") {
      toast.info(
        "We are Sorry! Currently we are catering to the salaried person only. Thank you for your interest",
        toastConfig
      );
    }
  };

  useEffect(() => {
    // console.log(checkValue);
    if (hasEditData) {
      // console.log(props.editdata);
      if (checkValue === "SALARIED") {
        // if (props.editdata?.isSalaried) {
        let updatedFields = updateFields(salariedFields, props.editdata);
        setSalariedFields([...updatedFields]);
      } else {
        let updatedFields = updateFields(selfEmployedFields, props.editdata);
        setSelfEmployedFields([...updatedFields]);
      }
    }
  }, [checkValue]);

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      // console.log(field, data[field?.name]);

      let filteredData = Object.only(
        data,
        fields.map((v) => v.name)
      );

      if (field.name === "companyname") {
        var companyNameInput = document.querySelector(
          "[name='appinput-companyname'].form-group"
        );
        var newCompanyNameInput = document.querySelector(
          "[name='newcompanyname']"
        );

        if (companyNameInput) {
          var companySelect = companyNameInput?.vnode.refs.select;
          // console.log({ companySelect });

          if (companySelect && companySelect?.state?.values?.length === 0) {
            let value = filteredData[field.name];
            let payload = {
              companyName: value,
            };
            if (value?.length >= 3) {
              props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
                if (data) {
                  // console.log({ "search data": data });
                  if (companySelect) {
                    companySelect = companyNameInput.vnode.refs.select;
                    let { searchResults } = companySelect?.state || {};
                    let datawithkey = data?.options("companyName");
                    // console.log({ searchResults, datawithkey });

                    if (data.length === 0) {
                      companySelect?.setState(
                        {
                          searchResults: [{ key: "OTHERS", label: "Others" }],
                          values: [{ key: "OTHERS", label: "Others" }],
                        },
                        () => {
                          companySelect?.setState({
                            searchResults,
                            values: [{ key: "OTHERS", label: "Others" }],
                          });
                        }
                      );
                      newCompanyNameInput.value = value;
                    } else {
                      companySelect?.setState({
                        searchResults,
                        values: [
                          {
                            key: datawithkey[0].key,
                            label: datawithkey[0].label,
                          },
                        ],
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
      return {
        ...field,
        defaultValue: data[field?.name],
        // disabled: true,
      };
    });
    // console.log(result);
    return result;
  };

  const handleCompanyNameSearch = (params) => {
    // console.log(params);
    let value = params?.state?.search;
    let payload = {
      companyName: value,
    };
    if (value.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-companyname'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            // console.log({ searchResults, datawithkey });
            searchResults = datawithkey;

            if (searchResults.length === 0) {
              companySelect.setState({
                searchResults: [{ key: "OTHERS", label: "Others" }],
              });
            } else {
              companySelect.setState({ searchResults });
            }
          }
        });
      }, 1000);
    }
  };

  const handleCompanyChange = (value) => {
    let key = value[0]?.key;

    let newField = {
      name: "newcompanyname",
      label: "Name",
      type: "text",
      placeholder: "Please enter",
    };
    var newCompanyInputOuter = document.querySelector("#input-newcompanyname");
    if (key === "OTHERS") {
      // let newSalariedFields = [...salariedFields];
      // newSalariedFields.splice(1, 0, newField);
      // console.log({ newSalariedFields });
      // setSalariedFields([...newSalariedFields]);
      //
      newCompanyInputOuter.classList.remove("d-none");
    } else {
      // setSalariedFields([...salariedFields]);
      newCompanyInputOuter.classList.add("d-none");
    }
  };

  const getCompanies = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
      // console.log({ data });
      if (data) {
        setDefaultCompanies(data);
        let companyNameField = salariedFields.find(
          (field) => field.name === "companyname"
        );
        companyNameField.options = data.options("companyName");
        setSalariedFields([...salariedFields]);
      }
    });
  };

  useEffect(() => {
    getCompanies();
    if (hasEditData) {
      props?.editdata?.isSalaried
        ? setCheckValue("SALARIED")
        : setCheckValue("SELF_EMPLOYED");
    }
  }, []);

  props.$setNode({
    props,
    salariedFields,
    defaultCompanies,
  });
  return (
    <>
      <h2 className="form_title mb-3">Employment Type</h2>
      <AppForm onSubmit={props.onIncomeSubmit}>
        <div
          className={Object.className({
            "checkbox_container mb-4": true,
            // "pe-none": hasEditData,
          })}
        >
          <div class="form-check me-3">
            <input
              class="form-check-input"
              type="radio"
              value="SALARIED"
              name="employmentType"
              id="SALARIED"
              onChange={(...args) => handleEmploymentType(...args)}
              checked={checkValue === "SALARIED"}
            />
            <label class="form-check-label" for="SALARIED">
              Salaried
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              value="SELF_EMPLOYED"
              name="employmentType"
              id="SELF_EMPLOYED"
              onChange={(...args) => handleEmploymentType(...args)}
              checked={checkValue === "SELF_EMPLOYED"}
            />
            <label class="form-check-label" for="SELF_EMPLOYED">
              Self Employed
            </label>
          </div>
        </div>
        {checkValue === "SALARIED" &&
          salariedFields?.map((field, i) => {
            return (
              <div
                className={`col-12 col-md-${field?.customcolspan || 6} ${
                  field?.outercolclass || ""
                }`}
                key={i}
                id={`input-${field.name}`}
              >
                <AppInput {...field}></AppInput>
              </div>
            );
          })}
        {checkValue === "SELF_EMPLOYED" &&
          selfEmployedFields?.map((field, i) => {
            return (
              <div
                className={`col-12 col-md-${field?.customcolspan || 6}`}
                key={i}
              >
                <AppInput {...field}></AppInput>
              </div>
            );
          })}

        <div className="col-12 pt-3 pb-5">
          <div className="alert_note">
            <span className="mandatory text-danger">*</span>
            <p>
              <span>Note:</span>
              <b className="ms-2">
                Loans are not provided to the employees of Proprietorship &
                Partnership Firms.
              </b>
            </p>
          </div>
        </div>
        <div className="nav_container">
          <button
            type="button"
            className="btn btn-primary-lite me-3"
            onClick={(...args) => props.skipToStep(0)}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(...args) => props.onIncomeSubmit}
          >
            {hasEditData ? "Update & Next" : "Submit"}
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(IncomeDetails);
