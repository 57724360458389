import React, { useEffect, useState } from "react";
import RoleCard from "./RoleCard/RoleCard";

const Roles = (props) => {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    getRoles();
    getDepartments();
    getDesignations();
  }, []);

  const getRoles = () => {
    // props.apis.apiGetMasterRoles().then((res) => {
    //   setRoles(res);
    // });
  };

  const getDepartments = () => {
    // props.apis.apiGetMasterDepartments().then((res) => {
    //   setDepartments(res);
    // });
  };

  const getDesignations = () => {
    // props.apis.apiGetMasterDesignations().then((res) => {
    //   setDesignations(res);
    // });
  };

  const handleRole = (e) => {
    console.log({ handleRoleSubmit: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW ROLE
    // props.apis.apiAddMasterRole(payload).then((res) => {
    //   console.log({ apiAddMasterRole: res });
    //   getRoles();
    //   document.getElementById("role-role-input").value = "";
    // });
  };

  const handleDepartment = (e) => {
    console.log({ handleDepartment: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW DEPARTMENT
    // props.apis.apiAddMasterDepartments(payload).then((res) => {
    //   console.log({ apiAddMasterDepartments: res });
    //   getDepartments();
    //   document.getElementById("role-department-input").value = "";
    // });
  };

  const handleDesignation = (e) => {
    console.log({ handleDesignation: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW DESIGNATION
    // props.apis.apiAddMasterDesignations(payload).then((res) => {
    //   console.log({ apiAddMasterDesignations: res });
    //   getDesignations();
    //   document.getElementById("role-designation-input").value = "";
    // });
  };

  // DELETE MASTER ROLE
  const handleDeleteRole = (e, data) => {
    let payload = {
      role: data.role,
    };
    // props.apis.apiDeleteMasterRole(payload).then(() => {
    //   getRoles();
    // });
  };

  // DELETE MASTER DEPARTMENT
  const handleDeleteDepartment = (e, data) => {
    let payload = {
      department: data.department,
    };
    console.log({ handleDeleteDepartment: e, data });
    // props.apis.apiDeleteMasterDepartment(payload).then(() => {
    //   getDepartments();
    // });
  };

  // DELETE MASTER DESIGNATION
  const handleDeleteDesignation = (e, data) => {
    let payload = {
      designation: data.designation,
    };
    console.log({ handleDeleteDesignation: e, data });
    // props.apis.apiDeleteMasterDesignation(payload).then(() => {
    //   getDesignations();
    // });
  };

  props.$setNode({
    props,
  });

  return (
    <div className="role_card_container row p-3">
      <div className="col-12 col-md-4 col-xl-3">
        <RoleCard
          name="department"
          data={departments}
          onSubmit={handleDepartment}
          onDelete={handleDeleteDepartment}
          crudOperation={false}
        />
      </div>
      <div className="col-12 col-md-4 col-xl-3">
        <RoleCard
          name="designation"
          data={designations}
          onSubmit={handleDesignation}
          onDelete={handleDeleteDesignation}
          crudOperation={false}
        />
      </div>
      <div className="col-12 col-md-4 col-xl-3">
        <RoleCard
          title="Roles (persona)"
          name="role"
          data={roles}
          onSubmit={handleRole}
          onDelete={handleDeleteRole}
          crudOperation={false}
          hideFooter={true}
        />
      </div>
    </div>
  );
};

export default constore(Roles);
