import api from "./apisetup";
const files = require.context('./', true, /\.js$/i);
const file_keys = files.keys().filter(key=>["./api.base.js"].includes(key));
const apifiles = require.context('../../jsx/pages', true, /\.api\.js$/i);
const apifilekeys = apifiles.keys();
export const apis = {
    ...file_keys.map(key=>files(key).default).reduce((o,n)=>({...o,...n}),{}),
    ...apifilekeys.map(key=>apifiles(key).default).reduce((o,n)=>({...o,...n}),{})
}
window.api = api;
window.apis = apis;
export default apis;