import React from "react";
import { styled } from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import AppInput from "./AppInput";
import AppForm from "./AppForm";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: ${(props) => (props.display == "true" ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: #0000007a;
  z-index: 1100;
  overflow: auto;
`;

const MessageCard = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  min-height: 150px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 1px 5px black;
  & .title {
    font-size: x-large;
    font-weight: 500;
    text-align: left;
    padding: 10px;
  }
  & > .grow {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: calc(100vw - 100px);
    max-height: calc(100vh - 100px);
    ${(p) => (p.hasscroll ? "overflow: auto;" : "")}
    width:100%
  }
  & > .footer {
    display: flex;
    gap: 10px;
    padding: 5px;
    & > button {
      padding: 5px 10px;
      background-color: var(--primary-color);
      border-radius: 5px;
      min-width: 100px;
      color: white;
    }
  }
`;

function AppLoader(props) {
  let [resolve, setResolve] = useState(() => "");
  let [reject, setReject] = useState(() => "");
  const defaultMessage = {
    content: "",
    title: "",
    hasFooter: false,
    hasscroll: false,
    btnOk: "OK",
    btnCancel: "CANCEL",
  };
  const [message, setMessage] = useState(defaultMessage);
  const active = !!message.content;
  const footerBtnClick = (action, data = null, event = null) => {
    console.log("footerBtnClick", { action }, data);
    if (action == "ok" && resolve) {
      resolve(data);
    } else if (reject) {
      reject(event);
    }
    setMessage(defaultMessage);
  };
  useEffect(() => {
    globalThis.alert = (
      message = "",
      config = { hasFooter: true, btnCancel: "" }
    ) => {
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
        setResolve(() => res);
        setReject(() => rej);
      });
      globalThis.emit.trigger("modalMessage", { content: message, ...config });
      return {
        promise,
        close: () => footerBtnClick("cancel"),
      };
    };
    globalThis.prompt = (message, fields = []) => {
      return globalThis.alert(
        <div style={{ width: "100%" }} className="px-4 py-2">
          {message && <h3 style={{ textAlign: "center" }}>{message}</h3>}
          <div className="default-form flex flex-col">
            {fields.map((props, index) => (
              <div className="mb-4" key={"field-" + index}>
                <AppInput type="text" {...props}></AppInput>
              </div>
            ))}
          </div>
        </div>,
        { hasFooter: true, hasscroll: fields.length > 4 }
      );
    };
    globalThis.confirm = (message, options = {}) => {
      return globalThis.alert(message, { ...options, hasFooter: true });
    };
    globalThis.loader = (message = "") => {
      return globalThis.alert(<div>Loading please wait ...</div>, {
        hasFooter: false,
      });
    };
  }, []);
  emit.onTrigger("modalMessage", props.$ns, (data) => {
    setMessage({
      ...message,
      ...data,
    });
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    footerBtnClick("ok", event.target.getData(), event);
  };
  props.$setNode({
    props,
    message,
    setMessage,
  });
  return (
    <Overlay className="app-loader" display={(!!active).toString()}>
      <MessageCard
        onSubmit={(...args) => handleSubmit(...args)}
        hasscroll={!!message?.hasscroll ? 1 : 0}
      >
        {message.title && (
          <div className="">
            <div className="col title">{message.title}</div>
          </div>
        )}
        <div
          className={Object.className({
            grow: true,
            flex: typeof message.content == "string",
          })}
        >
          {message.content}
        </div>
        {message.hasFooter && (
          <div className="footer">
            {message.btnOk && <button type="submit">{message.btnOk}</button>}
            {message.btnCancel && (
              <button type="button" onClick={() => footerBtnClick("cancel")}>
                {message.btnCancel}
              </button>
            )}
          </div>
        )}
      </MessageCard>
    </Overlay>
  );
}

export default constore(AppLoader);
