/// Menu
import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from 'react-bootstrap/Collapse';
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { AdminMenuList } from './Menulist/AdminMenu';
import { CustomerMenuList } from "./Menulist/CustomerMenu";
import { SalesMenuList } from "./Menulist/SalesMenu";
import { OpsMenuList } from "./Menulist/OpsMenu";
import { UnderwriterMenuList } from "./Menulist/UnderwriterMenu";
import { CollectionsMenuList } from "./Menulist/CollectionsMenu";


const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = (props) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [sidemenuData, setSidemenuData] = useState([]);

  // Function to find and update the title based on ID
  function updateTitleById(array, id, newTitle) {
    const updatedArray = array.map(obj => {
      if (obj.id === id) {
        return { ...obj, title: newTitle };
      }
      return obj;
    });
    return updatedArray;
  }

  // Function to find and update titles based on an object mapping IDs to titles
  function updateColumns(col, idMap) {
    // 1.filter out the columns
    let targetId = "id";
    // let filteredColumns = columns.only(col, targetId);

    // 2.change columns title
    const updatedArray = col.map((obj) => {
      if (idMap.hasOwnProperty(obj[targetId])) {
        return { ...obj, title: idMap[obj[targetId]] };
      }
      return obj;
    });
    return updatedArray;
  }

  useEffect(() => {
    const role = props?.$store?.user?.role;

    if (props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
      setSidemenuData(CustomerMenuList);
    } else {
      if (role !== _enum.PERSONAS.admin) {

        let updatedArray;

        if (role === _enum.PERSONAS.sales) {
          updatedArray = updateColumns(SalesMenuList, { applications: "Assigned to Opps" })

        } else if (role === _enum.PERSONAS.ops) {
          updatedArray = updateColumns(OpsMenuList, { leads: "Assigned Leads", applications: "My Applications" })

        } else if (role === _enum.PERSONAS.underwriter) {
          updatedArray = UnderwriterMenuList

        } else if (role === _enum.PERSONAS.collections) {
          updatedArray = CollectionsMenuList
        }
        else {
          updatedArray = AdminMenuList
        }

        let filteredMenus = updatedArray.filter(
          (v) => v.id !== "employees" && v.id !== "masterData" && v.id !== "dashboard" && v.id !== "settings"
        );
        // console.log({ updatedArray, filteredMenus });
        setSidemenuData(filteredMenus);
      } else {
        setSidemenuData(AdminMenuList);
      }
    }

  }, []);

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {
    console.log({ STATUS: status });
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })

    }
  }

  const checkActive = (data, param, hasInnerPath) => {
    // console.log({ data, param });
    let bool;
    if (param === 'outer' && data?.content && data?.content?.length > 0) {
      let ids = data.id
      bool = ids.includes(getPath())
    } else if (param === 'outer') {
      if (hasInnerPath !== undefined && hasInnerPath === true) {
        // console.log(data.id, data.innerpaths, getPathQuery("path"));
        let ids = data.innerpaths
        bool = ids.includes(getPathQuery("path"))
      }
      if (!getPathQuery("path")) {
        let ids = data.id;
        bool = ids.includes(getPath())
      }
    }
    else if (param === 'inner' && data?.id && data?.id?.length > 0) {
      let ids = data.id
      bool = ids.includes(getPath())
    } else {
      (getPath() === data?.id?.replace(/\s/g, '').toLowerCase()) ? bool = true : bool = false;
    }
    return bool;
  };

  const checkNestedPath = () => {

  };

  const rbiPolicy = () => {
    window.open('https://sachet.rbi.org.in/', '_blank')
  }
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const getPath = () => {
    // return window.location.pathname.replace(/\s/g, "").replace(/[\s/]/g, "").toLowerCase()
    return window.location.pathname.replace("/app/", "").toLowerCase()
  };
  const getPathQuery = (value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get(value);
    return query
  };
  props.$setNode({
    props
  })
  return (
    <div
      className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {sidemenuData.map((data, index) => {
            let menuClass = data.classChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else if (menuClass === "menu-popup") {
              return (
                <li className={menuClass} key={index}>
                  <a href="#" data-bs-toggle="modal" data-bs-target={`#${data.id}`} >
                    <div className="menu-icon">
                      <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                      <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                    </div>
                    <span className="hide-title sidebar-title">{data.title}</span>
                  </a>
                </li>
              )
            } else if (menuClass === "menu-link") {
              return (
                <li className={menuClass} key={index} onClick={() => rbiPolicy()}>
                  <a href="#">
                    <div className="menu-icon">
                      <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                      <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                    </div>
                    <span className="hide-title sidebar-title">{data.title}</span>
                  </a>
                </li>
              )
            } else {
              return (
                <li
                  // className={`${checkActive(data, 'outer', data?.hasInnerPath) ? 'mm-active' : data.classChange === "mm-collapse" ? 'has_nested_list' : ''}`}
                  className={Object.className({
                    'mm-active': checkActive(data, 'outer', data?.hasInnerPath),
                    'has_nested_list': data.classChange === "mm-collapse"
                  })}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <>
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.id) }}
                      >
                        <div>
                          <div className="menu-icon">
                            <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                            <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                          </div>
                          <span className="nav-text sidebar-title">{data.title}
                            {
                              data.update && data.update.length > 0 ?
                                <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                                :
                                ''
                            }
                          </span>
                        </div>
                        <i className={`fa fa-angle-${state.active === data.id ? 'up' : 'down'}`}></i>
                      </Link>
                      <Collapse in={state.active === data.id ? true : false}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                          {data.content && data.content.map((innerdata, index) => {
                            return (
                              <li key={index}
                                className={`${innerdata?.id} ${checkActive(innerdata, 'inner') ? 'mm-active' : ''}`}
                                onClick={() => { handleSubmenuActive(innerdata.id) }}
                              >
                                {innerdata.content && innerdata.content.length > 0 ?
                                  <>
                                    <Link to={innerdata.to} className="d-flex" >
                                      {innerdata.title}
                                    </Link>
                                  </>
                                  :
                                  <Link to={innerdata.to} className="d-flex" >
                                    <div className="menu-icon level_2">
                                      <img src={innerdata.iconStyle} className={`menu-icon-img ${innerdata.imgclass}`} width="15px" alt="" />
                                      <img src={innerdata.iconActive} className="menu-icon-img active" width="15px" alt="" />
                                    </div>
                                    <span className="nav-text sidebar-title">
                                      {innerdata.title}
                                    </span>
                                  </Link>
                                }

                              </li>
                            )
                          })}
                        </ul>
                      </Collapse>
                    </>
                    :
                    <Link to={data.to} onClick={() => { handleMenuActive(data.id) }}>
                      <div className="menu-icon">
                        <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                        <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                      </div>
                      {" "}<span className="nav-text sidebar-title">{data.title}</span>
                      {
                        data.update && data.update.length > 0 ?
                          <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                          :
                          ''
                      }
                    </Link>
                  }

                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default constore(SideBar);