import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import DupeCheckSearch from "./DupeCheckSearch/DupeCheckSearch";
import { Dropdown } from "react-bootstrap";

const DupeCheck = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([
    {
      dataIndex: "customerName",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "sanctionedLoanAmt",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedIRR",
      title: "ROI",
      render: (text, record) => {
        if (text) {
          return <div>{text}%</div>;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "sanctionedTenure",
      title: "Tenure",
      render: (text, record) => {
        if (text) {
          return <div>{text}Days</div>;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.status?.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ]);

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getDupeData();
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getDupeData(data, reset);
    }
  };

  const getDupeData = (params = {}, reset) => {
    let payload = {
      ...params,
    };
    const hasAnyValue = Object.values(payload).some((value) => value !== "");
    if (!hasAnyValue || reset) {
      return;
    } else {
      props.apis.apiGetUnderwriterApplicationDedupCheck(payload).then((res) => {
        setTableData(res);
        setRowcount(0);
      });
    }
  };

  useEffect(() => {
    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  const view = (record) => {
    console.log({ record });
    let { applicationId } = record;
    let path = `/app/loanapplicationsview?id=${applicationId}&frompath=dupecheck&readonly=true`;
    navigate(path);
  };

  return (
    <>
      <MainPagetitle
        mainTitle="Dedup Check"
        parentTitle={"Home"}
        pageIcon={imagepaths.DupeCheckIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <DupeCheckSearch onSearch={(...arg) => onSearch(...arg)} />
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(e) => view(e)}
                  editable={false}
                  deletable={false}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(DupeCheck);
