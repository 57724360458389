export const menu = [
    {
        label: 'Dashboard',
        icon: '/home-icon.svg',
        path:"/dashboard"
    },
    {
        label: 'Expenses', 
        icon: '/expense-icon.svg',
        path:"/expenselist"
    },
    {
        label: 'Requests',
        icon: '/request-icon.svg',
        path:"/requests"
    },
    {
        label: 'Advance',
        icon: '/advance-icon.svg',
        path:"/advance"
    },
    {
        label: 'Cards',
        icon: '/card-icon.svg',
        path:"/cards"
    }
]

export default menu;