import { configureStore } from "@reduxjs/toolkit";
import initialState from "./state";
let localInitState = initialState;
if (typeof window !== "undefined" && window?.localStorage) {
    // localInitState = window.localStorage.getItem("redux") || {};
    let redux = window.localStorage.getItem("redux") || "{}";
    let restored = false;
    globalThis.hasReduxRestored = () => (restored);
    globalThis.getStoredRedux = (app) => {
        restored = true;
        return JSON.parse(redux);
    };
    try {
        if(redux && JSON.parse(redux)) {
            localInitState = {
                ...initialState,
                ...globalThis.getStoredRedux()
            };
        }   
    } catch (error) {
        console.log({error});
    }
}

function deepCopy(obj, hash = new WeakMap()) {
    return structuredClone(obj);
    // return JSON.parse(JSON.stringify(obj));
};

const setStore = (state, action) => {
    // console.log({action});
    let payload = action.payload;
    let storeObject = deepCopy(state);
    if (!globalThis.hasReduxRestored()) {
        storeObject = globalThis.getStoredRedux();
    }
    let isset = (val, def = undefined) => {
        return val === undefined ? def : val;
    };
    let watch = {};
    let updateStore = (payload, storeObject) => {
        let key = payload.name;
        let watchme = watch[key] || ((v) => (v));
        let arr = payload.name.split(".");
        let value = typeof payload.value === "function" ? payload.value(state, action) : payload.value;
        if (arr.length > 1) {
            let tmp = storeObject, key = "", old = null;
            arr.forEach(k => {
                old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
            });
            old[key] = isset(watchme(value), value);
        } else {
            if (key) {
                storeObject[key] = isset(watchme(value), value);
            } else {
                storeObject = isset(watchme(value), value);
            }
        }
        return storeObject;
    }
    // console.log('setStore',{payload});
    if (payload.list) {
        Object.entries(payload.list).map(([name, value]) => {
            storeObject = updateStore({ name, value }, storeObject);
        })
    } else {
        storeObject = updateStore(payload, storeObject);
    }

    localStorage.setItem("redux", JSON.stringify(storeObject));
    return storeObject;
};

const mainState = {
    ...initialState,
    ...localInitState
};

export const store = configureStore({
    reducer: {
        store: (state, action) => {
            let newstate = (state || mainState);
            // console.log('reducer',{action});
            if (action.type == "store") {
                return setStore(newstate, action);
            }
            return newstate;
        },
    },
    preloadedState: {
        store: mainState
    },
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});