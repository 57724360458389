import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Success(props) {
  useEffect(() => {
    let timerInterval;
    swal2
      .fire({
        title: "Aadhar Verified Successfully!",
        icon: "success",
        iconColor: "#FF4D4D",
        html: "Redirecting to homepage in <b></b> seconds.",
        timer: 6000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          swal2.showLoading();
          const timer = swal2.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.ceil(swal2.getTimerLeft() / 1000)}`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })
      .then((result) => {
        let reqId = props.urlparams.params.requestId;

        // trigger event from child once we get request id from API
        if (reqId) {
          // console.log(reqId);
          window.opener.postMessage(
            reqId,
            "http://localhost:3000/app/customerhome"
          );
        }

        if (result.dismiss === swal2.DismissReason.timer) {
          window.close();
        }
      });
  }, []);

  props.$setNode({
    props,
  });

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
        >
          <div className="parent-container row gx-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="logo_header pt-5 ps-5">
                <Link to={"/open/login"} className="logo">
                  <img src={imagepaths.loginWhiteBg} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default constore(Success);
