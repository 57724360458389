const getapi = function () {
    let api = window.api;
    api = window.getpathkey(api);
    api = {
        ...api,
        ...window.getpathkey(api)
    };
    return api;
};
export const commonService = {
    api: getapi,
    apiSuccess(resolve = ((v) => (v)), id = 0, responce = {}) {
        return resolve(responce.data);
    },
    applogout() {
        console.log("DESTROY_SESSION");
        app?.props?.store("auth", false);
        app?.props?.store("user", false);
        localStorage.clear();

        if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
            window.navigate("/open/customer/login");
            console.log("CUSTOMER");
            app?.props?.store("persona", "");
        }

        if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.admin) {
            window.navigate("/open/login");
            console.log("ADMIN");
            app?.props?.store("persona", "");
        }

        if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.sales ||
            app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.ops ||
            app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.underwriter) {
            window.navigate("/open/login");
            console.log(app?.props?.$store?.persona);
            app?.props?.store("persona", "");
        }
        // if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
        //     if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
        //         window.navigate("/open/customer/login");
        //         console.log("CUSTOMER");
        //     } else {
        //         window.navigate("/open/login");
        //         console.log("ADMIN");
        //     }
        // } else {
        //     if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
        //         window.navigate("/open/customer/login");
        //         console.log("CUSTOMER2");
        //     } else {
        //         window.navigate("/open/login");
        //         console.log("ADMIN2");
        //     }
        // }
    },
    apiFailure({ reject, resolve }, id, error) {
        let app = window?.app;
        let msgObj = {
            icon: "error",
            iconColor: "#FF4D4D",
            // timer: 4000,
        }
        if ([400].includes(error?.response?.status)) {
            let message = (
                error.response?.data?.issues?.error ||
                error.response?.data?.issues?.message ||
                error.response?.data?.issues?.data.error ||
                error.response?.data?.message ||
                error.response?.data?.msg
            )
            swal2.fire({ ...msgObj, title: message })
        } else if (error?.response?.status == 500) {
            let message = (
                error.response?.data?.issues?.error ||
                error.response?.data?.message
            )
            swal2.fire({ ...msgObj, title: message })
        } else if (error?.response?.status == 502 || error?.response?.status == 503 || error?.response?.status == 404 || error?.response?.status == 403 || error?.response?.status == 409 || error?.response?.status == 412) {
            let message = (
                error.response?.data?.message ||
                error?.response?.message
            )
            swal2.fire({ ...msgObj, title: message })
        }
        else if (error?.response?.status == 401) {
            console.log("UNAUTHORIZED");
            const resolveApiFailure = () => {
                console.log("DESTROY_SESSION");
                app?.props?.store("auth", false);
                app?.props?.store("user", null);
                localStorage.clear();

                if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
                    window.navigate("/open/customer/login");
                    console.log("CUSTOMER");
                    app?.props?.store("persona", "");
                }

                if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.admin) {
                    window.navigate("/open/login");
                    console.log("ADMIN");
                    app?.props?.store("persona", "");
                }

                if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.sales ||
                    app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.ops ||
                    app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.underwriter) {
                    window.navigate("/open/login");
                    console.log(app?.props?.$store?.persona);
                    app?.props?.store("persona", "");
                }
            }
            let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
            refreshTokenAttemp = refreshTokenAttemp || 0;
            if (refreshTokenAttemp <= 1) {
                mixins.debounce(() => {
                    localStorage.setItem("refreshTokenAttemp", +refreshTokenAttemp + 1);
                    console.log({ refreshTokenAttemp });
                    app?.props?.apis
                        .apiRefreshToken()
                        .then(() => {
                            window.location.reload();
                        }
                        )
                        .catch(() => {
                            resolveApiFailure()
                        });
                });
            } else {
                swal2.fire({
                    title: "Oops!",
                    text: "Token is invalid or expired",
                    icon: "error",
                    iconColor: "#FF4D4D",
                    timer: 3000,
                    showCancelButton: false,
                    confirmButtonColor: "#FF4D4D",
                    confirmButtonText: "Ok",
                })
                resolveApiFailure()
            }
        }
        let globalLoader = document.getElementById("globalLoader");
        globalLoader?.classList.add("d-none");
        console.log({ error }, error?.response?.status);
        reject(error);
    },
    endpoint2url(endpoint) {
        let api = getapi();
        return endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
    },
    filterPayload(obj, inc = []) {
        const filter = (obj, predicate) =>
            Object.keys(obj)
                .filter((key) => predicate(obj[key], key, obj))
                .reduce((res, key) => {
                    res[key] = obj[key];
                    return res;
                }, {});
        return filter(obj, (v, k) => inc.includes(k));
    },
    request(endobj = "", post = {}, header = {}, postQueryParams = {}) {
        let endpoint = endobj.url;
        let objmethod = endobj.method;
        let payload = Object.keys(endobj.payload || {}).length ? endobj.payload : post;
        let data = payload;
        let axios = window.axios;
        let api = getapi();
        let globalLoader = document.getElementById("globalLoader");
        //console.log("request",window?.app?.props?.$store);
        let authorization = (window?.app?.props?.$store?.auth || localStorage?.redux?.parse()?.auth || "")
        let refresh = (window?.app?.props?.$store?.user?.refresh || localStorage?.redux?.parse()?.user?.refresh || "")
        let inHeaders = {
            headers: {
                authorization: "Bearer " + authorization,
                refresh,
                ...header
            },
            params: { ...postQueryParams }
        };

        let bundel = {
            payload: {},
            urltransform: (data = app.mixin.params(), apiendobj = endobj) => {
                let func = apiendobj.url;
                if (func instanceof Function) {
                    endpoint = func(data);
                } else {
                    endpoint = Object.entries(data).reduce((url, [key, val]) => {
                        return url.split(`{${key}}`).join(val);
                    }, apiendobj.url);
                }
                bundel.url = endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
                return bundel;
            },
            multipart: () => {
                inHeaders.headers = {
                    ...(inHeaders.headers || {}),
                    "Content-Type": "multipart/form-data"
                };
                return bundel;
            },
            responsetype: (type) => {
                inHeaders.responseType = type;
                return bundel;
            },
            config: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders) : reqconfig;
                inHeaders = {
                    ...inHeaders,
                    ...temp
                }
                return bundel;
            },
            headers: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders.headers) : reqconfig;
                inHeaders.headers = {
                    ...inHeaders.headers,
                    ...temp
                }
                return bundel;
            },
            transform(callback = ((v) => (v))) {
                data = callback(post);
                return bundel;
            },
            geturl(data = {}) {
                return bundel.url + Object.encodeuri(data)
            },
            exec: (message = false, method = "get") => {
                globalLoader?.classList.remove("d-none");
                bundel.results = new Promise((resolve, reject) => {
                    let app = window?.app;
                    let id = null;
                    let loaded = { close: () => ('') };
                    if (window.loader) {
                        loaded = loader();
                    }
                    if (message) {
                        id = Math.random().toString().slice(2);
                        // app.store(`loader.${id}`, { message, id, type: "loader" });
                        // app.mixin.pushRequest({ message, id, type: "loader" });
                    }
                    method = objmethod || method || (data instanceof FormData ? "post" : "get");
                    let url = this.endpoint2url(endpoint);
                    let isGet = method == "get";
                    let isDelete = method == "delete";
                    url = isGet ? url + Object.encodeuri(data) : url;
                    let requestarr = isGet ? ({ url, inHeaders }) : ({ url, data, inHeaders });
                    requestarr = isDelete ? ({
                        url, inHeaders: {
                            ...inHeaders,
                            data
                        }
                    }) : requestarr;
                    bundel.payload.messageId = id;
                    axios[method](...Object.values(requestarr))
                        .then((...arg) => this.apiSuccess(resolve, id, ...arg))
                        .catch((...arg) => this.apiFailure({ reject, resolve }, id, ...arg))
                        .finally(() => loaded.close());
                });
                return bundel;
            },
            log() {
                // console.log({ endobj, post, data, Headers: inHeaders, responce: bundel.results });
                return bundel;
            },
            mapresults(callback = ((v) => (v))) {
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        let resultdata = callback(data);
                        if (resultdata instanceof Promise) {
                            resultdata = await resultdata;
                        }
                        resolve(resultdata)
                    }).catch(reject);
                });
                bundel.results = newpromise;
                return bundel;
            },
            get() {
                let app = window?.app;
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        resolve(data)
                        globalLoader?.classList.add("d-none");
                    }).catch((...args) => {
                        console.log("bundel error", ...args);
                        reject(...args);
                    }).then(() => {
                        // let loaders = app.$store.loader;
                        // if (bundel.payload.messageId) {
                        //     delete loaders[bundel.payload.messageId];
                        // }
                        // app.store(`loader`, loaders);
                        // this.payload.messageId && app.mixin.popRequest(this.payload.messageId);
                        this.payload.messageId && swal2.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                        });
                    });
                });
                return newpromise;
            }
        };
        return bundel;
    },
    mokeapi(callback = (() => ('')), message = "Getting data please wait...", delay = 1000) {
        return new Promise((resolve, reject) => {
            // let app = window?.app;
            // let id = Math.random().toString().slice(2);
            // message && app.mixin.pushRequest({ message, id });
            message && swal2.fire({
                title: message,
                showConfirmButton: false,
            })
            setTimeout(() => {
                resolve(callback());
                // message && app.mixin.popRequest(id);
                swal2.closeModal();
            }, delay);
        });
    }
}

export default commonService;