import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Accordion, Card, Dropdown, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const LoanCalculation = (props) => {
  const fromPath = props?.urlparams?.params?.path;
  const [loanamount, setLoanamount] = useState(5000);
  const [loanduration, setLoanduration] = useState(1);
  const [interest, setInterest] = useState(0);
  const [totalamount, setTotalamount] = useState(0);
  const [processingfee, setProcessingfee] = useState(0);
  const [togglePage, setTogglePage] = useState(false);
  const editmode = props.urlparams.params.id ? true : false;
  const applicationId = props?.urlparams?.params?.id;

  const handleAmount = (e, targetname) => {
    let value = e.target.value;
    // console.log(value);
    if (parseInt(value) > 50000) {
      value = 50000;
    }

    setLoanamount(Number(value));
    renderColor(e, targetname);
  };

  const handleDuration = (e, targetname) => {
    let value = e.target.value;
    // console.log(value);
    if (parseInt(value) > 45) {
      value = 45;
    }
    setLoanduration(Number(value));
    renderColor(e, targetname);
  };

  const renderColor = (e, targetname) => {
    let value = e.target.value;
    let min = e.target.min;
    let max = e.target.max;
    var colorvalue = ((value - min) / (max - min)) * 100;
    let elem = document.querySelector(`[name="${targetname}"]`);

    // console.log(elem, targetname);
    elem.style.background =
      "linear-gradient(to right, #D32B2B 0%, #D32B2B " +
      colorvalue +
      "%, #E4D9D9 " +
      colorvalue +
      "%, #E4D9D9 100%)";
  };

  const loanCalculate = () => {
    const interestRate = 0.01; // 1%
    const processingFeeRate = 0.1; // 10%

    const interestAmount = loanamount * interestRate * loanduration;
    const processingFee = loanamount * processingFeeRate;
    const totalAmount = (loanamount + interestAmount).toFixed(2);
    setInterest(interestAmount);
    setTotalamount(totalAmount);
    setProcessingfee(processingFee);
    console.log({
      Principal: loanamount,
      "Interest Amount:": interestAmount,
      "Processing Fee:": processingFee,
      "Total Amount to be Repaid:": totalAmount,
    });
  };

  useEffect(() => {
    mixins.debounce(() => {
      loanCalculate();
    }, 1000);
  }, [loanamount, loanduration]);

  // ----------------------------------------------------------------
  const handleApply = (e) => {
    props.apis.apiGetCustomerInfo().then((res) => {
      console.log({ res });
      // props.$store?.user?.status !== "COMPLETED"
      if (res?.status !== "COMPLETED") {
        swal2.fire({
          title: "Please complete your profile before applying for any loans",
          icon: "error",
          iconColor: "#FF4D4D",
          showCancelButton: false,
          timer: 4000,
        });
      } else {
        let payload = {
          loanAmount: loanamount,
          duration: loanduration,
          interest: interest,
          totalAmount: Number(totalamount),
          // bankStatementType: "MONTHLY",
        };
        console.log({ payload });
        props.apis.apiAddCustomerApplication(payload).then((res) => {
          console.log({ res });
          if (res) {
            navigate(
              `/app/loancalculationdocs?id=${res?.applicationId}&path=${fromPath}`
            );
          }
        });
      }
    });
  };

  const getPageIcon = () => {
    let icon;
    if (fromPath === "customerhome") {
      icon = imagepaths.DashboardActive;
    }
    if (fromPath === "services") {
      icon = imagepaths.serviceHomeIconActive;
    }
    return icon;
  };

  const getMainTitle = () => {
    let mainTitle = "";
    if (fromPath === "customerhome") {
      mainTitle = "Dashboard";
    }
    if (fromPath === "services") {
      mainTitle = "Service";
    }
    return mainTitle;
  };

  const getBreadCums = () => {
    let pagetitle = "";
    if (fromPath === "customerhome") {
      pagetitle = "Loans Rs. 50,000";
    }
    if (fromPath === "services") {
      pagetitle = "Loans Rs. 50,000";
    }
    return pagetitle;
  };

  const getBackLink = () => {
    let url;
    if (fromPath === "customerhome") {
      url = "/app/customerhome";
    }
    if (fromPath === "services") {
      url = "/app/services";
    }
    return url;
  };

  props.$setNode({
    props,
  });

  return (
    <div>
      <MainPagetitle
        parentTitle={"Home"}
        mainTitle={getMainTitle()}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={getPageIcon()}
      />

      <div className="container-fluid">
        {/* ACCORDION */}
        <Card className="loan_calculation_wrapper">
          <Card.Body className="p-0">
            <div className="row row_box">
              <div className="col-12 col-lg-6 col-xl-8 white_column">
                <div className="grey_card">
                  <h2>Dhanvriddhi Short-Term Loans Upto Rs. 50,000</h2>
                  <p>
                    Short-term loans are small-scale financial arrangements
                    typically repaid within a short period.
                  </p>
                </div>
                <div className="row">
                  {/* <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                    <Accordion defaultActiveKey="0" className="grey_accordion">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Features & Benefits (Single EMI)
                          <div className="btn_arrow_container">
                            <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                            <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="content">
                            <ul className="custom_ul">
                              <li>Bulleted/Single one-time repayment </li>
                              <li>Loan from 5,000 to 50,000</li>
                              <li>Transparent Process</li>
                              <li>Speedy Disbursal</li>
                              <li>Completely Digital Journey</li>
                              <li>Can Help Build Your Credit Score</li>
                              <li>
                                Free to use the funds for any legitimate purpose
                              </li>
                              <li>Easy documentation</li>
                              <li>
                                No need to provide collaterals against the loan
                              </li>
                              <li>Assisted journey by our expert staff</li>
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Features & Benefits (Flexible EMI)
                          <div className="btn_arrow_container">
                            <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                            <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="content">
                            <ul className="custom_ul">
                              <li>Flexible Repayment Terms. </li>
                              <li>Loan up to Rs 1 Lac</li>
                              <li>Transparent Process</li>
                              <li>Speedy Disbursal</li>
                              <li>Completely Digital Journey</li>
                              <li>Can Help Build Your Credit Score</li>
                              <li>
                                Free to use the funds for any legitimate purpose
                              </li>
                              <li>Easy documentation</li>
                              <li>
                                No need to provide collaterals against the loan
                              </li>
                              <li>Assisted journey by our expert staff</li>
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div> */}
                  <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                    <Card className="eligibility_card">
                      <Card.Body>
                        <h4>Features & Benefits (Bullet Payment)</h4>
                        <p class="description">
                          Features and benefits highlighted.
                        </p>
                        <hr />
                        <div class="content">
                          <ul className="custom_ul">
                            <li>Bulleted/Single one-time repayment </li>
                            <li>Loan from 5,000 to 50,000</li>
                            <li>Transparent Process</li>
                            <li>Speedy Disbursal</li>
                            <li>Completely Digital Journey</li>
                            <li>Can Help Build Your Credit Score</li>
                            <li>
                              Free to use the funds for any legitimate purpose
                            </li>
                            <li>Easy documentation</li>
                            <li>
                              No need to provide collaterals against the loan
                            </li>
                            <li>Assisted journey by our expert staff</li>
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                    <Card className="eligibility_card">
                      <Card.Body>
                        <h4>Eligibility criteria</h4>
                        <p class="description">
                          Meet criteria, qualify for loan
                        </p>
                        <hr />
                        <div class="content">
                          <ul className="custom_ul">
                            <li>
                              <span>Age:</span> Must be above 21 years of age
                            </li>
                            <li>
                              <span>Segment:</span> Salaried Customers only
                            </li>
                            <li>
                              <span>Income:</span> Minimum Salary Rs 15000/month{" "}
                            </li>
                            <li>
                              <span>Credit Score:</span> Min 600+
                            </li>
                            <li>
                              <span>Nationality:</span> Indian Resident
                            </li>
                            <li>
                              <span>Salary Transfer:</span> Salary Credit
                              Banking
                            </li>
                            <li>
                              <span>Job Stability:</span> Overall, Job Stability
                              of 1 year.{" "}
                            </li>
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <Card className="loan_calculation_card">
                  <Card.Body>
                    <h2 className="title mb-4">Loan Calculation</h2>
                    <form class="range-form">
                      <div class="form-group mb-5">
                        <label for="formControlRange">Loan Amount</label>
                        <Form.Range
                          min={5000}
                          max={50000}
                          value={loanamount}
                          name="loanAmount"
                          className="styled_slider"
                          onChange={(...args) =>
                            handleAmount(...args, "loanAmount")
                          }
                        />
                        <InputGroup className="range_demo_group" size="sm">
                          <Form.Control
                            aria-label="Loan Amount"
                            value={loanamount}
                            min={5000}
                            max={50000}
                            onChange={(...args) =>
                              handleAmount(...args, "loanAmount")
                            }
                          />
                          <InputGroup.Text>₹</InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div class="form-group mb-5">
                        <label for="formControlRange">Duration</label>
                        <Form.Range
                          min={1}
                          max={45}
                          value={loanduration}
                          name="duration"
                          className="styled_slider"
                          onChange={(...args) =>
                            handleDuration(...args, "duration")
                          }
                        />
                        <InputGroup className="range_demo_group" size="sm">
                          <Form.Control
                            aria-label="Loan Duration"
                            value={loanduration}
                            min={1}
                            max={45}
                            onChange={(...args) =>
                              handleDuration(...args, "duration")
                            }
                          />
                          <InputGroup.Text>Days</InputGroup.Text>
                        </InputGroup>
                      </div>
                    </form>
                    <div className="loan_view_card mb-4">
                      <div className="value_box">
                        <span className="value">{loanamount?.cur()}</span>
                        <span className="tag">Principal</span>
                      </div>
                      <div className="value_box">
                        <span className="value">{interest?.cur()}</span>
                        <span className="tag">Interest</span>
                      </div>
                      <div className="value_box last_box">
                        <span className="value">{processingfee?.cur()}</span>
                        <span className="tag">Processing Fee</span>
                      </div>
                      <div className="payment_box">
                        <span className="tag">
                          Total Payment (Principle + Interest)
                        </span>
                        <span className="value">{totalamount?.cur()}</span>
                      </div>
                    </div>
                    <p className="bottom_note">
                      * Interest per day 1% & Processing fee 10 %
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="nav_container">
          {editmode ? (
            <Link
              to={`/app/loancalculationdocs?id=${applicationId}`}
              className="btn btn-primary"
            >
              Edit
            </Link>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(...args) => handleApply(...args)}
            >
              Apply Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default constore(LoanCalculation);
