import React from "react";
import ReactApexChart from "react-apexcharts";

class ProjectStatusBlogDonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [4, 100, 50, 50, 50, 50, 50],
      options: {
        chart: {
          type: 'donut',
          width: 450,
        },
        colors: ['#FF7A00', '#15C85C', '#FFAB20', '#920201', '#FDA5A5', '#C992E3'],
        labels: ["Open Leads", "Interested Leads", "Not Interested Leads", "Not Reachable", "Call Not Answered", "Follow Up"],
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '90%',
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 33,
                },
                value: {
                  show: true,
                  fontSize: '38px',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  color: "#E11011",
                  offsetY: -28,
                },
                total: {
                  show: true,
                  fontSize: '22px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  label: 'Total Leads',

                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b, c) => {
                      return a + b;
                      // if (c >= 1) {
                      //   return a + b;
                      // } else {
                      //   return 0;
                      // }
                    }, 0)
                  }
                }
              }
            }
          }

        },

      },
    };
  }

  render() {
    return (
      <div id="projectChart" className="project-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.props?.data}
          type="donut"
          width={450}
        />
      </div>
    );
  }
}

export default ProjectStatusBlogDonutChart;