export const OpsMenuList = [
    {
        title: "Assigned Leads",
        iconStyle: imagepaths.SingleUserIcon,
        iconActive: imagepaths.SingleUserIconActive,
        to: "/app/leads",
        id: "leads",
    },
    {
        title: "My Applications",
        iconStyle: imagepaths.applicationCheckImg,
        iconActive: imagepaths.applicationCheckActiveImg,
        to: "/app/applications",
        id: "applications",
    },
];
