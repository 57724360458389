import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DocsCard from "../../MyProfile/DocsCard";
import { Checkbox, Card, Row, Col } from "antd";

const CollectionDocs = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const [docs, setDocs] = useState({
    panFileMetaData: {
      fileId: "test1233",
    },
  });

  // ----------------------------------------------------------------
  const CheckboxGroup = Checkbox.Group;

  const options = [
    { label: "Personal Info.", value: "personalInfo" },
    { label: "Income & BSA Info.", value: "incomeBsaInfoCheck" },
  ];

  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? options.map((option) => option.value) : []
    );
    setCheckAll(e.target.checked);
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = () => {};

  const downloadExcel = async () => {
    let payload = {};

    let startDateValue = document.querySelector("[name='startDate']")?.value;
    let endDateValue = document.querySelector("[name='endDate']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }
    // props.apis.apiExportAllRegisteredUsers(payload).then(async (res) => {
    //   if (res) {
    //     let data = await res;
    //     let blob = new Blob([data], {
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });
    //     let link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.setAttribute(
    //       "download",
    //       `registered_users_${new Date().toDate()}.xlsx`
    //     );
    //     link.click();
    //   }
    // });
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Checkbox
            onChange={onCheckAllChange}
            checked={checkAll}
            style={{ marginBottom: "16px" }}
            className="fs-5"
          >
            Select All
          </Checkbox>
          <div className="action_btn_group text-end">
            <button
              type="button"
              className="btn btn-primary-transparent file_download_btn text-primary"
              onClick={(...args) => downloadExcel(...args)}
            >
              <i className="fa fa-download me-2"></i>
              Download Excel
            </button>
          </div>
        </div>
        <CheckboxGroup
          value={checkedList}
          onChange={onChange}
          className="d-flex"
        >
          <div className="row w-100 g-4">
            {options.map((option) => (
              <div className="col-12">
                <Card>
                  <Checkbox value={option.value} className="mb-3 fs-5 fw-bold">
                    {option.label}
                  </Checkbox>
                  <DocsCard data={docs} size="sm" />
                </Card>
              </div>
            ))}
          </div>
        </CheckboxGroup>
      </div>
      <div className="nav_container mt-5">
        <button
          type="button"
          onClick={(...args) => props.changeTab(...args, "cibil")}
          className="btn btn-primary-lite me-3"
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary">
          Generate Form
        </button>
      </div>
    </>
  );
};

export default constore(CollectionDocs);
