import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AppFormData extends Component {
  render() {
    let { props } = this;
    let {
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      editdata,
      customcolspan = "6",
      modaltype = "",
      errorMessage,
      hidecancelbtn,
    } = props;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let getColClass = (c) => fields[c]?.customcolspan || "6";
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    // console.log({ fields, data, list });
    return (
      <AppForm {...formAttrs}>
        {list.map((row, i) => (
          <Row className="mx-0" key={i}>
            {row.map(([label, value, options], j) => (
              <Col lg={getColClass(label)} key={j}>
                <AppInput
                  name={label}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  disabled={disableallfields}
                  {...(disablefields.includes(label) &&
                  Object.keys(editdata).length > 0
                    ? {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                  errorMessage={errorMessage}
                ></AppInput>
              </Col>
            ))}
          </Row>
        ))}
        {modaltype === "centeredmodal" ? (
          <div className="row mt-4">
            <div
              className={Object.className({
                "col-12 mb-3 text-center d-flex align-item-center justify-content-center": true,
                hidden: disableallfields,
              })}
            >
              <Link
                to={"#"}
                onClick={onClose}
                className={Object.className({
                  "btn btn-danger light me-2": true,
                  "m-auto": disableallfields,
                  "d-none": hidecancelbtn,
                })}
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                {submitText}
              </button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-xl-6 mb-3"></div>
            <div
              className={Object.className({
                "col-xl-6 mb-3 text-end d-flex justify-content-end": true,
                hidden: disableallfields,
              })}
            >
              <Link
                to={"#"}
                onClick={onClose}
                className={Object.className({
                  "btn btn-danger light me-2": true,
                  "m-auto": disableallfields,
                  "d-none": hidecancelbtn,
                })}
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                {submitText}
              </button>
            </div>
          </div>
        )}
      </AppForm>
    );
  }
}

export default AppFormData;
