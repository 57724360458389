import React, { Component } from "react";
import { Tabs } from "antd";

export class AppTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // onTabChange = (key) => {
  //   console.log(key);
  // };

  render() {
    let { tabitems } = this.props;
    return (
      <>
        <Tabs
          defaultActiveKey={tabitems[0]?.key}
          items={tabitems}
          onChange={(...args) => this.onTabChange(...args)}
        />
      </>
    );
  }
}

export default AppTabs;
