import { connect } from "react-redux";
import "../helpers/index";
import methods from "../methods";
import services from "../services";
import apis from "../api";
import _enum from "../enum";
import mokeresults from "../mokeresults";
import Emitter from "../emitters";
import { language } from "../languages/index";
import { styled } from "styled-components";

const emitObj = new Emitter();
window._enum = _enum;
window.mokeresults = mokeresults;
window.emit = emitObj;

const setInitials = () => {
    window._enum = {
        ..._enum,
        ...window.getpathkey(_enum)
    };
    window.mokeresults = {
        ...mokeresults,
        ...window.getpathkey(mokeresults)
    }
};

const parseurl = function (urlstr) {
    try {
        let str = urlstr.includes("http") ? urlstr : (window.location.origin + urlstr);
        let url = new URL(str);
        let splits = url.hash.split("?");
        if (splits.length === 2) {
            url.hash = splits[1];
        }
        url.search += splits[1] || '';
        url.params = Object.fromEntries(url.searchParams.entries());
        return url;
    } catch (e) {
        console.log("err", e);
        return urlstr;
    }
}

export const mixins = {
    authToken() {
        return app?.props?.$store?.logged?.access_token
    },
    params() {
        return parseurl(window?.location.toString()).params;
    },
    constore(component) {
        let myBind = {
            name: component.name,
            dump: { componentName: component.name },
            $emit: emitObj
        };
        myBind = window[component.name] = (window[component.name] || myBind);
        let WrappedComponent = component;
        return connect(
            (state) => {
                myBind.$store = state.store;
                return (
                    {
                        $store: myBind.$store,
                        appstore: () => (myBind.$store),
                        $l: language(myBind.$store.language),
                        componentName: component.name,
                        $node: myBind,
                        $ns: myBind.dump,
                        $emit: emitObj,
                        $setNode: (obj) => {
                            obj = obj instanceof Function ? obj(myBind) : obj;
                            obj.props = Object.except(obj.props, ['$node', '$setNode']);
                            window[component.name] = Object.assign(myBind, obj);
                        },
                        watch: {}
                    }
                );
            },
            (dispatch) => {
                let ret = {};
                ret.dispatch = (payload) => {
                    try {
                        return dispatch({ type: 'store', payload });
                    } catch (error) {
                        dispatch({ type: 'store', payload });
                    }
                };
                ret.store = (name, value) => {
                    return typeof name == "object" ? ret.dispatch({ list: name }) : ret.dispatch({ name, value })
                };
                ret.location = window?.location;
                ret.setstore = (name, value) => (name instanceof Array) ? ret.dispatch(name) : ret.dispatch({ name, value });
                ret.urlparams = parseurl(ret.location.href);
                ret.initialconstruct = mixins.initialconstruct;
                ret.navigate = (window?.navigate || (() => { }));
                ret.apis = Object.entries(apis).reduce((col, [key, value]) => ({
                    ...col, [key]: value.bind({
                        ...apis,
                        props: Object.except(ret, ['apis'])
                    })
                }), {});
                ret.services = Object.entries(services).reduce((col, [key, value]) => ({ ...col, [key]: value.bind({ ...services, props: Object.except(ret, ['services']) }) }), {});
                ret._enum = _enum;
                ret.moke = mokeresults;
                ret.className = (...args) => Object.className(...args)
                return ret;
            }
        )(WrappedComponent);
    },
    initialconstruct(props = null, key = "") {
        let self = this || {};
        props = Object.assign({}, props || self.props);
        key = key || props.componentName;
        Object.entries(props.apis).reduce((collector, [key, value]) => {
            collector[key] = value.bind({
                ...this,
                ...props.apis
            });
            return collector;
        }, {});
        self.$store = props.$store;
        self.appstore = props.appstore;
        self.setstore = props.setstore;
        self.dispatch = props.dispatch;
        self.navigate = props.navigate;
        self.apis = props.apis;
        self.services = props.services;
        self._enum = props._enum;
        self.moke = props.moke;
        self.watch = mixins.watch.bind(self);
        window[key] = self;
    },
    watch() {

    },
    checkParent(ele, callback = (() => (false))) {
        let temp = ele;
        while (temp) {
            let flag = callback(temp, temp.parentElement);
            if (flag) {
                return temp.parentElement || flag;
            }
            temp = temp.parentElement;
        }
        return temp;
    },
    ...methods,
    ...services,
    apis: apis,
    setInitials,
    _enum: () => (window._enum),
    $emit: emitObj,
}
// window.onload = ()=>{
//     console.log("webpageloaded");
//     //setInitials();
// };


export const constore = mixins.constore;

export const createStyled = (stylcallback, componentcallback, name = Math.random().toString) => {
    const Component = stylcallback(styled);
    componentcallback.displayName = name;
    const RetComponent = componentcallback(Component);
    RetComponent.displayName = name;
    return RetComponent;
}

export default mixins;

window.constore = constore;