export default {
    Row:(props)=>(
        <div className={Object.className({['row '+(props.className||'')]:true})}>{props.children}</div>
    ),
    Col:(props)=>(
        <div className={Object.className({['col '+(props.className||'')]:true})}>{props.children}</div>
    ),
    Col12:(props)=>(
        <div className={Object.className({['col-12 '+(props.className||'')]:true})}>{props.children}</div>
    )
}