import React, { useEffect, useState } from "react";

const DocsUpload = (props) => {
  const hasEditData =
    props.editdata?.panFileId?.length !== 0 ||
    props.editdata?.panFileId !== null;
  const [docfields, setDocfields] = useState([
    {
      name: "panFileId",
      label: "Upload PAN Card",
      type: "file",
      fileLabel: "Upload PAN Card",
      foldername: "kycDocs",
      // showPreview: true,
      accept: "image/*",
    },
    // {
    //   name: "aadharFrontFileId",
    //   label: "Upload Aadhar Card (Front)",
    //   type: "file",
    //   fileLabel: "Upload Aadhar Card",
    //   foldername: "kycDocs",
    //   // showPreview: true,
    //   accept: "image/*",
    // },
    // {
    //   name: "aadharBackFileId",
    //   label: "Upload Aadhar Card (Back)",
    //   type: "file",
    //   fileLabel: "Upload Aadhar Card",
    //   foldername: "kycDocs",
    //   // showPreview: true,
    //   accept: "image/*",
    // },
    {
      name: "photoFileId",
      label: "Photo",
      type: "file",
      fileLabel: "Upload Photo",
      foldername: "kycDocs",
      // showPreview: true,
      accept: "image/*",
    },
  ]);

  useEffect(() => {
    console.log(props.editdata);
    if (hasEditData) {
      let alteredData = {
        panFileId: props?.editdata?.panFileMetaData?.fileId,
        // aadharFrontFileId: props?.editdata?.aadharFrontFileMetaData?.fileId,
        // aadharBackFileId: props?.editdata?.aadharBackFileMetaData?.fileId,
        photoFileId: props?.editdata?.photoFileMetaData?.fileId,
      };
      let updatedFields = updateFields(docfields, alteredData);
      setDocfields([...updatedFields]);
    }
  }, []);

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      if (field.type === "file") {
        console.log({ data, field });
        field.defaultValue = data[field.name];
        field.value = data[field.name];
        // field.disabled = true;
        return {
          ...field,
        };
      } else {
        return {
          ...field,
          defaultValue: data[field?.name],
          // disabled: true,
        };
      }
    });
    return result;
  };

  const onDocsSubmit = (e, data) => {
    console.log({ data });
    props.handleDocsSubmit(data);
  };

  return (
    <>
      <AppForm onSubmit={onDocsSubmit}>
        {docfields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 6}`}
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          );
        })}
        <div className="nav_container">
          <button
            type="button"
            className="btn btn-primary-lite me-3"
            onClick={(...args) => props.onClose()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {hasEditData ? "Update & Preview" : "Submit & Preview"}
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(DocsUpload);
