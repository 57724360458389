import React from 'react'

function AppEmpty(props) {
    let { data, children } = props;
  return (
    <>
        {!mixins.isEmpty(data) && children}
    </>
  )
}

export default AppEmpty