import React, { Component } from "react";
import ProfileSteps from "./ProfileSteps/ProfileSteps";

export class ProfileModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ProfileModal = this;
  }
  render() {
    let { props } = this;
    let {
      id,
      title,
      show,
      onClose,
      modalSize,
      logsData,
      logsColumns,
      handleSubmit,
      setGoSteps,
      goSteps,
      editdata,
    } = props;

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
        backdrop="static"
        className="profile-canvas"
      >
        <ProfileSteps
          handleSubmit={(...args) => handleSubmit(...args)}
          setGoSteps={setGoSteps}
          goSteps={goSteps}
          onClose={onClose}
          editdata={editdata}
        />
      </AppCanvas>
    );
  }
}

export default ProfileModal;
