import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import OpenRouteModel from "./OpenRouteModel";

function Register(props) {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState("");
  const [religion, setReligion] = useState("");
  let errorsObj = { customerName: "", pincode: "", email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isLoading, setLoading] = useState(false);

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    // if (customerName === "") {
    //   errorObj.customerName = "Customer Name is Required";
    //   error = true;
    // }
    // if (mobile === "") {
    //   errorObj.mobile = "Mobile is Required";
    //   error = true;
    // }
    // if (email === "") {
    //   errorObj.email = "Email is Required";
    //   error = true;
    // }
    // if (password === "") {
    //   errorObj.password = "Password is Required";
    //   error = true;
    // }
    // setErrors(errorObj);
    // if (error) return;

    props.store("auth", true);
    props.store("persona", _enum.ROUTE_PERSONAS.customer);
    props.store("logged", { emailAddress: email });
    props.store("user", {
      role: "CUSTOMER",
      isPasswordUpdated: true,
      customerName: customerName,
      mobileNumber: 9898987651,
      isPasswordUpdated: true,
      isAadharVerified: false,
      isPanVerified: false,
    });
    navigate("/app/customerhome");
  }

  const closeModal = () => {
    document.querySelector("#openRouteModel #bsmodalclose").click();
  };

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/login"} className="logo">
                    <img src={imagepaths.loginWhiteBg} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="login-form style-2">
                  <div className="card-body">
                    {/* <div className="logo-header">
                      <Link to={"/open/login"} className="logo">
														<img
															src={imagepaths.LogofullBank}
															alt="log1"
															className="width-230 light-logo"
														/>
													</Link>
													<Link to={"/open/login"} className="logo">
														<img
															src={imagepaths.LogoWhite}
															alt="log2"
															className="width-230 dark-logo"
														/>
													</Link>
                    </div> */}
                    <nav className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane active show fade">
                          {props.errorMessage && (
                            <div className="">{props.errorMessage}</div>
                          )}
                          {props.successMessage && (
                            <div className="">{props.successMessage}</div>
                          )}
                          <form className="dz-form py-2" onSubmit={onSignUp}>
                            <h3 className="form-title text-center mb-4">
                              Tell us a bit about yourself
                            </h3>
                            <div className="row g-3">
                              <div className="col-12 col-md-12 form-group mt-3">
                                <label htmlFor="dzName">
                                  Full Name (As per PAN Card)
                                </label>
                                <input
                                  value={customerName}
                                  onChange={(e) =>
                                    setCustomerName(e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="text"
                                />
                                {errors.customerName && (
                                  <div className="text-danger fs-12">
                                    {errors.customerName}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-12 form-group mt-3">
                                <label htmlFor="">Email ID</label>
                                <input
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="email"
                                />
                                {errors.email && (
                                  <div className="text-danger fs-12">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6 form-group mt-3">
                                <label htmlFor="dzName">PIN Code</label>
                                <input
                                  value={pincode}
                                  onChange={(e) => setPincode(e.target.value)}
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="text"
                                />
                                {errors.pincode && (
                                  <div className="text-danger fs-12">
                                    {errors.pincode}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6 form-group mt-3">
                                <label htmlFor="dzName">Gender</label>
                                <select
                                  value={gender}
                                  onChange={(e) => setGender(e.target.value)}
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="select"
                                >
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </select>
                                {errors.gender && (
                                  <div className="text-danger fs-12">
                                    {errors.gender}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6 form-group mt-3">
                                <label htmlFor="">DOB</label>
                                <input
                                  value={dob}
                                  onChange={(e) => setDob(e.target.value)}
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="date"
                                />
                                {errors.dob && (
                                  <div className="text-danger fs-12">
                                    {errors.dob}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6 form-group mt-3">
                                <label htmlFor="dzName">Marital Status</label>
                                <select
                                  value={maritalstatus}
                                  onChange={(e) =>
                                    setMaritalstatus(e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="select"
                                >
                                  <option value="single">Single</option>
                                  <option value="married">Married</option>
                                </select>
                                {errors.maritalstatus && (
                                  <div className="text-danger fs-12">
                                    {errors.maritalstatus}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6 form-group mt-3">
                                <label htmlFor="dzName">Religion</label>
                                <select
                                  value={religion}
                                  onChange={(e) => setReligion(e.target.value)}
                                  className="form-control"
                                  placeholder="Please Enter"
                                  type="select"
                                >
                                  <option value="hindu">Hindu</option>
                                  <option value="muslim">Muslim</option>
                                  <option value="christian">Christian</option>
                                </select>
                                {errors.religion && (
                                  <div className="text-danger fs-12">
                                    {errors.religion}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-12 mb-3 mt-3">
                                <span className="form-check float-start me-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input mt-0"
                                    id="check2"
                                    name="example1"
                                  />
                                  <label
                                    className="form-check-label d-unset"
                                    htmlFor="check2"
                                  >
                                    I have read and agreed to the
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#openRouteModel"
                                      className="text-primary ms-2"
                                    >
                                      Ayaan Finserve India Pvt. Ltd. T&C &
                                      Privacy Policy.
                                    </a>
                                  </label>
                                </span>
                              </div>
                              <div className="col-12 col-md-12 form-group clearfix text-left">
                                <button
                                  type="submit"
                                  className="btn btn-primary button-md btn-block"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Loading…" : "Sign Up"}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpenRouteModel
        id="openRouteModel"
        className="modal-lg openRouteModel"
        modalSize="default"
        title="Terms of Service and Privacy Policy"
        submitText="Understood"
        modaltype="centeredmodal"
        onClose={() => closeModal()}
        footer={true}
        okText="Understood"
      />
    </>
  );
}

export default constore(Register);
