import React, { Component } from "react";

export class ApplicationModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ApplicationModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, show, onClose, modalSize, logsData, logsColumns } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppFormData {...attrs} onClose={onClose}></AppFormData>
        {logsData?.length > 0 && (
          <>
            <h5 class="modal-title mb-3">Logs</h5>
            <AppTable
              data={logsData || []}
              columns={logsColumns}
              reorder={true}
              showPagination={false}
              editable={false}
              viewable={false}
              deletable={false}
            ></AppTable>
          </>
        )}
      </AppCanvas>
    );
  }
}

export default ApplicationModal;
