export default {

    apiGetCustomerDashboardMoke() {
        let data = [
            {
                title: _enum.PRODUCTS.personal_loan,
                image: imagepaths.personalLoanImg,
            },
            {
                title: _enum.PRODUCTS.home_loan,
                image: imagepaths.homeLoanImg,
            },
            {
                title: _enum.PRODUCTS.mortage_loan,
                image: imagepaths.mortageLoanImg,
            },
            {
                title: _enum.PRODUCTS.education_loan,
                image: imagepaths.educationLoanImg,
            },
            {
                title: _enum.PRODUCTS.vehicle_loan,
                image: imagepaths.vehicleLoanImg,
            },
            {
                title: _enum.PRODUCTS.lease_rental_discounting,
                image: imagepaths.propertyLoanImg,
            },
            {
                title: _enum.PRODUCTS.property_purchase_loan,
                image: imagepaths.propertyLoanImg,
            },
            {
                title: _enum.PRODUCTS.commercial_vehicles_loan,
                image: imagepaths.commercialLoanImg,
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiGetCustomersHistoryMoke() {
        let data = [
            // {
            //     name: "Suresh",
            //     mobileNumber: "9876543210",
            //     productType: "Personal Loan",
            //     date: "2023-11-28T13:44:44.656Z",
            //     status: "Submitted",
            // },
            // {
            //     name: "Manish",
            //     mobileNumber: "9876543211",
            //     productType: "Home Loan",
            //     date: "2023-11-21T13:44:44.656Z",
            //     status: "In Progress",
            // },
            // {
            //     name: "Yasha",
            //     mobileNumber: "9876543212",
            //     productType: "Vehicle Loan",
            //     date: "2023-11-24T13:44:44.656Z",
            //     status: "Closed",
            // },
            {
                assignedLead: {
                    leadName: "Mudit Rawat",
                    mobileNumber: "7985787641",
                    productType: "Education Loan",
                    assignedEmployee: {
                        "employeeName": "EL Test",
                        "department": "Sales",
                        "designation": "Manager",
                        "mobileNumber": "9999634141"
                    },
                    Applications: [
                        {
                            status: "disbursed",
                        }
                    ]
                }
            }
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetCustomerApplicationsMoke() {
        let data = [
            {
                loanId: 878788,
                productType: "short_term_loan",
                loanAmount: 5000,
                date: "25-02- 2024",
                status: "submitted",
            },
            {
                loanId: 878567,
                productType: "short_term_loan",
                loanAmount: 10000,
                date: "25-02- 2024",
                status: "in_progress",
            },
            {
                loanId: 878276,
                productType: "insta_loan",
                loanAmount: 100000,
                date: "25-02- 2024",
                status: "submitted",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiUploadMoke() {
        let data = { fileId: "672f8c0d3493c20a80d71f0d82228e31" }
        return this.mokeapi(() => (data), "", 100);
    },
    apiAddPayslipMoke(payload) {
        let data = "Payslip added";
        return this.mokeapi(() => (data), "", 100);
    },
    apiAddBanknameMoke(payload) {
        let data = "Bankname added";
        return this.mokeapi(() => (data), "", 100);
    }
}