import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PreDisburseInfo = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  let editmode = props.urlparams.params.readonly !== "true" ? true : false;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;

  const [loanfields, setLoanfields] = useState([
    {
      name: "borrowerName",
      label: "Customer Name",
      type: "text",
    },
    {
      name: "mobileNumber",
      label: "Mobile No.",
      type: "text",
    },
    {
      name: "emailAddress",
      label: "Email ID",
      type: "text",
    },
    {
      name: "loanAccountNumber",
      label: "Loan Account No",
      type: "text",
    },
    {
      name: "sanctionedLoanAmount",
      label: "Sanctioned Loan amount",
      type: "text",
    },
    {
      name: "sanctionedLoanTenure",
      label: "Tenure",
      type: "text",
    },
    {
      name: "sanctionedLoanIRR",
      label: "ROI",
      type: "text",
    },
    {
      name: "sanctionedLoanPF",
      label: "Processing Fees",
      type: "text",
    },
    {
      name: "netDisbursementAmt",
      label: "Net Disbursement Amt", // sanctionedLoanAmt - Processing Fee
      type: "number",
      readOnly: true,
    },
    {
      name: "loanPaymentMode",
      label: "Repayment Type",
      type: "select",
      options: _enum.REPAYMENT_TYPE_KEY,
    },
    {
      name: "sanctionedLoanStartDate",
      label: "Loan Start date",
      type: "date",
      // onChange: (date) => handleStartDateChange(date),
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      dateClassName: "fakedisabled",
    },
    {
      name: "sanctionedLoanEndDate",
      label: "Loan End date",
      type: "date",
    },
    {
      name: "sanctionedLoanTotalAmtPayable",
      label: "Total Amt payable",
      type: "text",
    },
    {
      name: "loanDetailsRemarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);
  const [bankfields, setBankfields] = useState([
    {
      name: "accountHolderName",
      label: "Account Holder Name",
      type: "text",
    },
    {
      name: "accountNumber",
      label: "Account No",
      type: "text",
    },
    {
      name: "accountHolderBankName",
      label: "Bank Name",
      type: "text",
    },
    {
      name: "accountHolderBankIFSC",
      label: "IFSC Code",
      type: "text",
    },
    {
      name: "bankDetailsRemarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);
  const [paymentfields, setPaymentfields] = useState([
    {
      name: "payment_type",
      label: "Payment Type",
      type: "select",
      options: _enum.PAYMENT_TYPES,
      defaultValue: "IMPS",
    },
    {
      name: "disbursementDate",
      label: "Payment Date",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
    },
    {
      name: "disbursementRemarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);
  const [editdata, setEditdata] = useState({});

  useEffect(() => {
    getPredisbursedApplication();
    console.log({ editmode });
    // if (isReadonly) {
    //   let lfields = loanfields.map((field, i) => {
    //     return {
    //       ...field,
    //       readOnly: true,
    //     };
    //   });
    //   console.log({ lfields });
    //   setLoanfields(lfields);
    // }
  }, []);

  const getPredisbursedApplication = () => {
    let payload = {
      loanAccountNumber: props.urlparams.params.lanid,
    };
    props.apis
      .apiGetUnderWriterPredisbursedApplicationByNumber(payload)
      .then((res) => {
        if (res) {
          setEditdata(res);
          let updatedFieldArray = updateDefaults(loanfields, res);
          setLoanfields(updatedFieldArray);

          let updatedBankFieldArray = updateDefaults(bankfields, res);
          setBankfields(updatedBankFieldArray);

          let updatedPaymentFieldArray = updateDefaults(paymentfields, res);
          setPaymentfields(updatedPaymentFieldArray);
        }
      });
  };

  const updateDefaults = (arr, applicationData) => {
    let defaultArr = arr.map((o) => {
      let defaultValue = o.defaultValue || applicationData[o.name] || "";

      if (o.name === "sanctionedLoanTotalAmtPayable") {
        let interestRate = applicationData.sanctionedLoanIRR;
        let loanAmount = applicationData.sanctionedLoanAmount;
        let loanduration = applicationData.sanctionedLoanTenure;
        // console.log({
        //   interestRate,
        //   loanAmount,
        //   loanduration,
        // });
        let interestAmount = loanAmount * 0.01 * loanduration;
        let totalAmount = (loanAmount + interestAmount).toFixed(2);
        // console.log({ totalAmount });
        return {
          ...o,
          defaultValue: totalAmount,
          readOnly: true,
        };
      }
      if (editmode) {
        if (
          [
            "sanctionedLoanStartDate",
            "disbursementRemarks",
            "bankDetailsRemarks",
            "disbursementDate",
            "payment_type",
          ].includes(o.name)
        ) {
          return {
            ...o,
            defaultValue,
          };
        } else {
          return {
            ...o,
            defaultValue,
            readOnly: true,
          };
        }
      } else {
        return {
          ...o,
          defaultValue,
          readOnly: true,
        };
      }
    });
    console.log({ defaultArr });
    return defaultArr;
  };

  const handlePendingSubmit = (e, data) => {
    console.log({ data });
    swal2
      .fire({
        title: "Are you sure want to disburse this loan application ?",
        icon: "info",
        iconColor: "#DE3232",
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiSendLoanAmountToCustomer({
              loanAccountNumber: props.urlparams.params.lanid,
            })
            .then((res) => {
              console.log({ res });
              if (res) {
                // let msgText, icon;
                // if (res?.status === "success") {
                //   msgText = "Application Disbursed Successfully";
                //   icon = "success";
                // } else if (res?.status === "failure") {
                //   msgText = "Application Failed";
                //   icon = "error";
                // } else if (res?.status === "pending") {
                //   msgText = "Application is in Pending State";
                //   icon = "info";
                // }

                swal2.fire({
                  title: "Disbursement is In-Progress",
                  text: "kindly check again in sometime",
                  icon: "success",
                  iconColor: "#DE3232",
                  timer: 4000,
                  confirmButtonText: "Close",
                });
                navigate("/app/loandetails");
              }
            })
            .catch((err) => {
              navigate("/app/loandetails");
            });
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <AppForm className="mx-0" onSubmit={handlePendingSubmit}>
          <div className="card p-4">
            <h2 className="form_title">Loan Deatils</h2>
            <div className="row">
              {loanfields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>
          <div className="card p-4">
            <h2 className="form_title">Bank A/C Details</h2>
            <div className="row">
              {bankfields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>
          <div className="card p-4">
            <h2 className="form_title">Payment Details</h2>
            <div className="row">
              {paymentfields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>

          <div className="card profile_card tvr_comment mx-0">
            <div className="card-header p-0 border-0">
              <div className="d-flex align-items-center mb-3">
                <h4 className="theme_text_sm ms-2 mb-0">TVR Comment</h4>
              </div>
            </div>
            <div className="card-body p-0 ms-2">
              <p className="comment_heading">Comment</p>
              <p className="comment">
                {editdata?.application?.customerTVRComment || "-"}
              </p>
            </div>
          </div>

          <div className="nav_container">
            <Link
              to={"/app/loandetails"}
              className={Object.className({
                "btn btn-primary-lite me-3": true,
                "d-none": !isAdmin || isReadonly,
              })}
            >
              Close
            </Link>
            <button
              type="submit"
              className={Object.className({
                "btn btn-primary": true,
                "d-none": !isAdmin || !editmode,
              })}
            >
              Disburse
            </button>
          </div>
          {isReadonly && (
            <div className="nav_container mt-5">
              <button
                type="button"
                onClick={(...args) =>
                  props.changeTab(...args, "postsanctioneddocs")
                }
                className="btn btn-primary-lite me-3"
              >
                Back
              </button>
              <Link
                to={`/app/loandetails?activetab=${tabStatus}`}
                className="btn btn-primary"
              >
                Close
              </Link>
            </div>
          )}
        </AppForm>
      </div>
    </>
  );
};
export default constore(PreDisburseInfo);
