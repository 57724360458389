import React, { Component } from "react";

export class InterestRateModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.InterestRateModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, show, onClose, modalSize } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppFormData {...attrs} onClose={onClose}></AppFormData>
      </AppCanvas>
    );
  }
}

export default InterestRateModal;
