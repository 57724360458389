import React from "react";

const FlexCard = ({
  className,
  data,
  title,
  verifiedIconsFor,
  hasFileId = false,
  hasPdfFile = false,
  fileIdKey,
  pdfIdKey,
  colspan_key,
  colspan_value,
  showResponseActions,
  response,
  responseTitle,
  handleResponseAccept,
  handleResponseReject,
  editable = false,
  onEdit = (v) => v,
}) => {
  if (verifiedIconsFor) {
    console.log({ verifiedIconsFor });
  }
  if (data && Object.keys(data).length > 0) {
    // console.log({ data });
  }
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  return (
    <div className="flex_card_outer">
      {editable && (
        <button
          type="button"
          className="btn edit_btn"
          onClick={(e) => onEdit(e)}
        >
          <i className="fa fa-pencil"></i>
        </button>
      )}
      <div className={`flex_card row ${className || ""}`}>
        {title && <h4 className="title">{title}</h4>}
        {data &&
          Object.entries(data)
            .filter((innerArr) => innerArr[0] !== "fileId")
            .map(([key, value], i) => (
              <div
                key={key}
                className={`card-item ${
                  i === 1 ? colspan_value || "col-2" : colspan_key || "col-2"
                }`}
              >
                <div className="card-key">{key?.spaceCase().toTitleCase()}</div>
                <div className="card-value">
                  {verifiedIconsFor && verifiedIconsFor.includes(key) ? (
                    <i className="fa fa-circle-check text-green ms-2"></i>
                  ) : null}
                  {hasPdfFile && pdfIdKey === key ? (
                    <>
                      <a
                        // href={`${data["loan_agreement"] || ""}`}
                        href={`${BASE_URL}${data["loan_agreement"] || ""}`}
                        target="_blank"
                        download
                        className="download_link fw-normal ms-2"
                      >
                        Download
                      </a>
                    </>
                  ) : hasFileId && fileIdKey === key ? (
                    <>
                      {value || "-"}
                      <a
                        href={`${BASE_URL}${data["fileId"] || ""}`}
                        className="download_link fw-normal ms-2"
                      >
                        Download
                      </a>
                    </>
                  ) : (
                    <>{value === 0 ? 0 : value || "-"}</>
                  )}
                </div>
              </div>
            ))}
        {response && (
          <div>
            {responseTitle && <h4 className="title">{responseTitle}</h4>}
            <div className="docs_card_container row g-0">
              {response?.map((item, i) => {
                return (
                  <div className="col-6 col-sm-4 col-md-6 col-lg-2">
                    <div className="docs_card" key={i}>
                      <img
                        src={imagepaths.docsCardRedIcon}
                        alt="docsCardRedIcon"
                      />
                      <h2 className="title">PAN Card Front</h2>
                      {item?.panFileId ? (
                        <a
                          href={`${BASE_URL}${item?.panFileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
              {showResponseActions && (
                <div className="nav_container mt-5">
                  <button
                    type="button"
                    className="btn btn-primary-lite me-3"
                    onClick={(...args) =>
                      handleResponseReject(...args, "reject")
                    }
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(...args) =>
                      handleResponseAccept(...args, "accept")
                    }
                  >
                    Accept
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlexCard;
