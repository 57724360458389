import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

function Login(props) {
  const navigate = useNavigate();
  const [mobilefield, setMobilefield] = useState("");
  let errorsObj = { email: "", password: "" };
  let mobilefieldErrorObj = { mobilefield: "" };
  const [otpErrors, setOtpErrors] = useState(mobilefieldErrorObj);
  const [isMobileLoading, setMobileLoading] = useState(false);

  function onOTPLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...mobilefieldErrorObj };

    if (mobilefield === "") {
      errorObj.mobilefield = "Field is Required";
      error = true;
    } else {
      setMobileLoading(true);
    }
    setOtpErrors(errorObj);
    if (error) {
      return;
    }
    let data = {
      mobileNumber: mobilefield,
    };

    props.apis
      .apiCustomerSignInRequest(data, { mobileNumber: mobilefield })
      .then((res) => {
        props.store("signedMobileNumber", mobilefield);
        navigate("/open/customer/signinverify");
        setMobileLoading(false);
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
        const errorObj = { ...errorsObj };
        errorObj.mobilefield = "login failed";
        setOtpErrors(errorObj);
        setMobileLoading(false);

        if (
          err?.response?.data?.statusCode === 404 &&
          err?.response?.data?.error === "Not Found"
        ) {
          console.log("redirect to signup page");
          signup(mobilefield);
        }
      });
  }

  function signup(mobilefield) {
    let data = {
      mobile: mobilefield,
    };

    props.apis
      .apiCustomerSignUpRequest(data)
      .then((res) => {
        console.log({ apiCustomerSignUpRequest: res });
        props.store("CustomerUID", res?.uid);
        props.store("CustomerSignUpMobileNumber", mobilefield);
        navigate("/open/customer/otp");
        setMobileLoading(false);
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
        const errorObj = { ...errorsObj };
        errorObj.mobilefield = "Mobile number login failed";
        setOtpErrors(errorObj);
        setMobileLoading(false);
      });
  }
  props.$setNode({
    props,
  });

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
        >
          <div className="parent-container row gx-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="logo_header pt-5 ps-5">
                <Link to={"/open/customer/login"} className="logo">
                  <img src={imagepaths.loginWhiteBg} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
              <div className="login-form style-2 py-4">
                <div className="card-body">
                  <div className="nav nav-tabs border-bottom-0">
                    <div className="tab-content w-100" id="nav-tabContent">
                      <div
                        className="tab-pane fade active show"
                        id="nav-personal"
                      >
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        <h3 className="form-title m-t0 text-center">
                          Customer Login
                        </h3>
                        <div className="dz-separator-outer m-b5">
                          <div className="dz-separator bg-primary style-liner"></div>
                        </div>
                        <p className="sub-title text-center">
                          Access to explore more
                        </p>
                        <form className=" dz-form pb-3" onSubmit={onOTPLogin}>
                          <div className="form-group mb-3">
                            <label>Email or Mobile no.</label>
                            <input
                              value={mobilefield}
                              type="text"
                              className="form-control"
                              onChange={(e) => setMobilefield(e.target.value)}
                              placeholder="Please enter"
                            />
                            {otpErrors.mobilefield && (
                              <div className="text-danger fs-12">
                                {otpErrors.mobilefield}
                              </div>
                            )}
                          </div>
                          <div
                            className={Object.className({
                              "form-group text-left": true,
                              "not-allowed": isMobileLoading,
                            })}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary button-md btn-block"
                              disabled={isMobileLoading}
                            >
                              {isMobileLoading ? "Loading…" : "Login"}
                            </button>
                          </div>
                        </form>
                        <div className="text-center bottom">
                          <span>Not a member? </span>
                          <NavLink
                            to="/open/customer/signup"
                            className="text-primary fw-bold"
                          >
                            Sign Up
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default constore(Login);
