const CibilCard = ({ data, cardvariant }) => {
  return (
    <div className={`cibil_card ${cardvariant || ""}`}>
      <div className="card_header row mx-auto">
        <div className="col-12 col-md-2 score_box">
          <div className="score">{data?.cibilScore}</div>
          <p className="m-0">Cibil Score</p>
          <div className="v-line"></div>
        </div>
        <div className="col-12 col-md-10 content">
          <div className="row mx-auto">
            <div className="col-12 col-md-4">
              <ul>
                <li>
                  <span className="list_title">No. of Accounts</span>
                  <span className="list_content">{data?.NoOfAccounts}</span>
                </li>
                <li>
                  <span className="list_title">Recent Account</span>
                  <span className="list_content">{data?.RecentAccount}</span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <ul>
                <li>
                  <span className="list_title">No. of Active Accounts</span>
                  <span className="list_content">
                    {data?.NoOfActiveAccounts}
                  </span>
                </li>
                <li>
                  <span className="list_title">Monthly Payment Amt.</span>
                  <span className="list_content">
                    {parseFloat(
                      data?.TotalMonthlyPaymentAmount
                    )?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <ul>
                <li>
                  <span className="list_title">No. of Writeoffs</span>
                  <span className="list_content">{data?.NoOfWriteOffs}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card_body p-3 px-md-5 py-md-4">
        <div className="row mx-auto">
          <div className="col-12 col-md-3">
            <ul>
              <li>
                <span className="list_title">No. of Past Due Accounts</span>
                <span className="list_content">
                  {data?.NoOfPastDueAccounts}
                </span>
              </li>
              <li>
                <span className="list_title">Total Past Dues</span>
                <span className="list_content">₹ {data?.TotalPastDue}</span>
              </li>
              <li>
                <span className="list_title">Total Balance Amt.</span>
                <span className="list_content">
                  {parseFloat(data?.TotalBalanceAmount)?.toLocaleString(
                    "en-IN",
                    {
                      style: "currency",
                      currency: "INR",
                    }
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <ul>
              <li>
                <span className="list_title">No. of Zero Balance Accounts</span>
                <span className="list_content">
                  {data?.NoOfZeroBalanceAccounts}
                </span>
              </li>
              <li>
                <span className="list_title">Total Highest Credit</span>
                <span className="list_content">₹ {data?.TotalHighCredit}</span>
              </li>
              <li>
                <span className="list_title">Total Sanction Amt.</span>
                <span className="list_content">
                  {parseFloat(data?.TotalSanctionAmount)?.toLocaleString(
                    "en-IN",
                    {
                      style: "currency",
                      currency: "INR",
                    }
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <ul>
              <li>
                <span className="list_title">Recent Accounts</span>
                <span className="list_content">{data?.RecentAccount}</span>
              </li>
              <li>
                <span className="list_title">Single Highest Sanction Amt.</span>
                <span className="list_content">
                  {parseFloat(
                    data?.SingleHighestSanctionAmount
                  )?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </span>
              </li>
              <li>
                <span className="list_title">Total Credit Limit</span>
                <span className="list_content">₹ {data?.TotalCreditLimit}</span>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <ul>
              <li>
                <span className="list_title">Oldest Accounts</span>
                <span className="list_content">{data?.OldestAccount}</span>
              </li>
              <li>
                <span className="list_title">Average Open Balance</span>
                <span className="list_content">
                  {parseFloat(data?.AverageOpenBalance)?.toLocaleString(
                    "en-IN",
                    {
                      style: "currency",
                      currency: "INR",
                    }
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CibilCard;
