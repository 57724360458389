import React, { useEffect, useRef, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { ToastContainer } from "react-toastify";
import DocsUpload from "./DocsUpload";
import IncomeDetails from "./IncomeDetails";
import KycDetails from "./KycDetails";
import ProfileDetails from "./ProfileDetails";

const ProfileSteps = (props) => {
  const verifyBtnRef = useRef(0);
  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  const {
    customerId,
    customerAadharCardInfo,
    customerPanCardInfo,
    CustomerAddressInfo,
    officialEmail,
    panFileMetaData,
    aadharFrontFileMetaData,
    aadharBackFileMetaData,
    emailVerification,
  } = props?.editdata;

  const hasEditData = officialEmail?.length > 0 ? true : false;

  const isIncomeDetailsVerified =
    props?.$store?.user?.officialEmail?.length > 0
      ? true
      : officialEmail?.length > 0
      ? true
      : false;

  const kycDetailsVerified =
    props?.$store?.user?.customerAadharCardInfo &&
    Object.keys(props?.$store?.user?.customerAadharCardInfo)?.length > 0 &&
    props?.$store?.user?.customerPanCardInfo &&
    Object.keys(props?.$store?.user?.customerPanCardInfo)?.length > 0 &&
    props?.$store?.user?.CustomerAddressInfo &&
    Object.keys(props?.$store?.user?.CustomerAddressInfo)?.length > 0
      ? true
      : customerAadharCardInfo &&
        Object.keys(customerAadharCardInfo).length > 0 &&
        customerPanCardInfo &&
        Object.keys(customerPanCardInfo).length > 0 &&
        CustomerAddressInfo &&
        Object.keys(CustomerAddressInfo).length > 0
      ? true
      : false;

  // const isDocsVerified =
  //   panFileMetaData &&
  //   Object.keys(panFileMetaData).length > 0 &&
  //   aadharFrontFileMetaData &&
  //   Object.keys(aadharFrontFileMetaData).length > 0 &&
  //   aadharBackFileMetaData &&
  //   Object.keys(aadharBackFileMetaData).length > 0
  //     ? true
  //     : false;
  const isDocsVerified =
    panFileMetaData && Object.keys(panFileMetaData).length > 0 ? true : false;

  // ----------------------------------------------------------------
  const handlePreview = () => {
    props.handleSubmit();
  };

  const handleDocsSubmit = (data) => {
    let payload = {
      ...Object.except(data, ["fileName"]),
    };

    props.apis.apiUploadCustomerDocs(payload).then((res) => {
      console.log({ apiUploadCustomerDocs: res });
      props.handleSubmit();
    });
  };

  // PROFILE DETAILS
  const onProfileSubmit = (e, data) => {
    let payload = {
      ...data,
      pincode: Number(data?.pincode),
      dob: new Date(data?.dob).toISOString(),
    };

    const resolve = () => {
      props.store("user", {
        ...props?.$store?.user,
        customerName: payload?.customerName,
        emailAddress: payload?.emailAddress,
        gender: payload?.gender,
        dob: payload?.dob,
        maritalStatus: payload?.maritalStatus,
        religion: payload?.religion,
        emailVerification: emailVerification,
        pincode: payload?.pincode,
      });
    };

    console.log({ payload });

    props.apis.apiUpdateCustomerDetails(payload).then((res) => {
      console.log({ res });
      resolve();
      toast.success("Profile Details Updated Successfully", toastConfig);
      skipToStep(1);
    });
  };

  // INCOME DETAILS
  const onIncomeSubmit = (e, data) => {
    console.log("Income Details: ", data);

    let payload = {
      ...Object.except(data, ["employmentType", "newcompanyname"]),
    };

    if (data?.employmentType !== "SALARIED") {
      payload.isSalaried = false;
      swal2.fire({
        title: "We are Sorry! ",
        text: "Currently we are catering to the salaried person only. Thank you for your interest we will get back to you",
        icon: "warning",
        iconColor: "#DE3232",
        confirmButtonColor: "#DE3232",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      payload.isSalaried = true;
    }

    if (data?.companyname === "OTHERS") {
      payload.companyname = data.newcompanyname;
    } else {
      payload.companyname = data.companyname;
    }
    console.log({ payload });

    const resolve = () => {
      props.store("user", {
        ...props?.$store?.user,
        officialEmail: payload?.officialEmail,
        monthlySalary: payload?.monthlySalary,
        salaryMode: payload?.salaryMode,
        companyname: payload?.companyname,
      });
    };

    if (hasEditData) {
      props.apis.apiUpdateCustomerIncomeDetails(payload).then((res) => {
        console.log({ res });
        resolve();
        toast.success("Income Details Updated Successfully", toastConfig);
        skipToStep(2);
      });
    } else {
      props.apis.apiSaveCustomerIncomeDetails(payload).then((res) => {
        console.log({ res });
        resolve();
        toast.success("Income Details Submitted Successfully", toastConfig);
        skipToStep(2);
      });
    }
  };

  // ----------------------------------------------------------------
  const skipToStep = (step) => {
    props.setGoSteps(step);
  };
  return (
    <>
      <ToastContainer />
      <div className="form-wizard profile_stepper_container">
        <Stepper
          className="nav-wizard profile_stepper"
          id="profileStepper"
          activeStep={props.goSteps}
        >
          <Step
            className="nav-link pe-none"
            label="Profile Details"
            onClick={() => props.setGoSteps(0)}
            stepClassName="profiledetails"
            completed={emailVerification}
          />
          <Step
            className="nav-link pe-none"
            label="Income Details"
            onClick={() => props.setGoSteps(1)}
            stepClassName="incomedetails"
            completed={isIncomeDetailsVerified}
          />
          <Step
            className="nav-link pe-none"
            label="KYC Details"
            onClick={() => props.setGoSteps(2)}
            stepClassName="aadhar"
            completed={kycDetailsVerified}
          />
          <Step
            className="nav-link pe-none"
            label="Docs Upload"
            onClick={() => props.setGoSteps(3)}
            stepClassName="docsupload"
            completed={isDocsVerified}
          />
        </Stepper>
        {props.goSteps === 0 && (
          <ProfileDetails
            skipToStep={(...args) => skipToStep(...args)}
            onProfileSubmit={(...args) => onProfileSubmit(...args)}
            onCustomerVerifyEmail={(...args) => customerVerifyEmail(...args)}
            onClose={props.onClose}
            editdata={props.editdata}
          />
        )}
        {props.goSteps === 1 && (
          <IncomeDetails
            skipToStep={(...args) => skipToStep(...args)}
            onIncomeSubmit={(...args) => onIncomeSubmit(...args)}
            onClose={props.onClose}
            editdata={props.editdata}
          />
        )}
        {props.goSteps === 2 && (
          <KycDetails
            editdata={props.editdata}
            skipToStep={(...args) => skipToStep(...args)}
            onClose={props.onClose}
          />
        )}
        {props.goSteps === 3 && (
          <DocsUpload
            skipToStep={(...args) => skipToStep(...args)}
            handleDocsSubmit={(...args) => handleDocsSubmit(...args)}
            handlePreview={(...args) => handlePreview(...args)}
            editdata={props.editdata}
            onClose={props.onClose}
          />
        )}
      </div>
    </>
  );
};

export default constore(ProfileSteps);
