export default {


    apiGetRegisteredUsersMoke() {
        let data = [
            {
                employeeId: 3453463,
                employeeName: "Ashish Kumar",
                mobileNumber: "+91 9879879879",
                emailAddress: "johndoe@gmail.com",
                registration_date: "03-08-2023",
                status: "pending"
            },
            {
                employeeId: 3453463,
                employeeName: "John Doe",
                mobileNumber: "+91 9879879879",
                emailAddress: "johndoe@gmail.com",
                registration_date: "03-08-2023",
                status: "success"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiAddMasterRole(data) {
        console.log({ apiCreateRole: data });
        return this.request(this.api().ADD_MASTER_ROLE, data).exec().log().get();
    },

    apiGetMasterRoles(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_ROLES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiDeleteMasterRole(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_ROLE)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMasterDesignations(data) {
        console.log({ apiAddMasterDesignations: data });
        return this.request(this.api().ADD_MASTER_DESIGNATION, data).exec().log().get();
    },

    apiGetMasterDesignations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_DESIGNATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiDeleteMasterDesignation(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_DESIGNATION)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMasterDepartments(data) {
        console.log({ apiAddMasterDepartments: data });
        return this.request(this.api().ADD_MASTER_DEPARTMENT, data).exec().log().get();
    },

    apiGetMasterDepartments(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_DEPARTMENTS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiDeleteMasterDepartment(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_DEPARTMENT)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMasterLocation(data) {
        console.log({ apiAddMasterLocation: data });
        return this.request(this.api().ADD_MASTER_LOCATION, data).exec().log().get();
    },

    apiGetMasterLocations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_LOCATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiGetLocationById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_MASTER_LOCATION_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateMasterLocation(data, urldata = {}) {
        console.log({ apiUpdateMasterLocation: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            locationName: data?.locationName,
            city: data?.city,
            state: data?.state,
            latitude: data?.latitude,
            longitude: data?.longitude,
            status: data?.status
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_MASTER_LOCATION, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteMasterLocation(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_LOCATION)
            .urltransform(urldata)
            .exec().log().get();
    },

    // EMPLOYEE

    apiAddEmployee(data) {
        return this.request(this.api().CREATE_EMPLOYEE, data)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddEmployee: response });
                return response.data
            })
            .get();
    },

    apiGetEmployee(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_EMPLOYEE, payload)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res.data,
                    total: res.count
                }
            })
            .get();
    },

    apiGetEmployeeById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_EMPLOYEE_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                console.log({ res });
                return res
                // return {
                //     employeeId: res?.profileId,
                //     employeeName: res?.name,
                //     emailAddress: res?.emailAddress,
                //     mobileNumber: res?.mobileNumber,
                //     role: res?.role,
                //     supervisorId: res?.supervisor?.profileId,
                //     joiningDate: res?.createdAt,
                //     locationId: res?.workLocation,
                //     status: res?.status,
                //     effectiveDate: res?.effectiveDate,
                // }
            })
            .get();
    },

    apiUpdateEmployee(data, urldata = {}) {
        console.log({ apiUpdateEmployee: data });
        urldata = { ...mixins.params(), ...urldata };

        // let payload = {
        //     employeeId: data?.employeeId,
        //     employeeName: data?.employeeName,
        //     emailAddress: data?.emailAddress,
        //     mobileNumber: data?.mobileNumber,
        //     designation: data?.designation,
        //     department: data?.department,
        //     role: data?.role,
        //     supervisorId: data?.supervisorId,
        //     joiningDate: data?.joiningDate,
        //     gender: data?.gender,
        //     dateOfBirth: data?.dateOfBirth,
        //     panCardId: data?.panCardId,
        //     locationId: data?.locationId,
        //     status: data?.status,
        //     effectiveDate: data?.effectiveDate,
        //     productType: data?.productType,
        // }
        // payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_EMPLOYEE, data).urltransform(urldata).exec().log().get();
    },

    apiDeleteEmployee(urldata = mixins.params()) {

        return this.request(this.api().DELETE_EMPLOYEE)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetMyInfo() {
        return this.request(this.api().GET_MY_INFO)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiGetSingleProfileInfo(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_SINGLE_PROFILE_INFO,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    //BANK NAMES

    apiAddBankname(data) {
        console.log({ apiAddBankname: data });
        return this.request(this.api().ADD_BANK_NAME, data).exec().log().get();
    },

    apiGetBanknames(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_BANK_NAMES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiGetBanknameById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_BANK_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateBankname(data, urldata = {}) {
        console.log({ apiUpdateEmployee: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            bankName: data?.bankName,
            fileId: data?.fileId,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_BANK_NAME, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteBankname(urldata = mixins.params()) {

        return this.request(this.api().DELETE_BANK_NAME)
            .urltransform(urldata)
            .exec().log().get();
    },

    // INTEREST RATES

    apiAddInterestRate(data) {
        console.log({ apiAddInterestRate: data });
        return this.request(this.api().ADD_INTEREST_RATES, data).exec().log().get();
    },

    apiGetInterestRates(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_INTEREST_RATES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },


    apiGetInterestRateById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_INTEREST_RATE_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateInterestRate(data, urldata = {}) {
        console.log({ apiUpdateEmployee: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            bankName: data?.bankName,
            productType: data?.productType,
            interestRate: data?.interestRate,
            interestType: data?.interestType,
            processingFee: data?.processingFee,
            loanAmount: data?.loanAmount,
            tenure: data?.tenure,
            fileId: data?.fileId,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_INTEREST_RATE, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteInterestRate(urldata = mixins.params()) {

        return this.request(this.api().DELETE_INTEREST_RATE)
            .urltransform(urldata)
            .exec().log().get();
    },

    //PRODUCT TYPES

    apiAddProductType(data) {
        console.log({ apiAddProductType: data });
        return this.request(this.api().ADD_PRODUCT_TYPE, data).exec().log().get();
    },

    apiGetProductTypes(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PRODUCT_TYPES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiDeleteProductType(urldata = mixins.params()) {

        return this.request(this.api().DELETE_PRODUCT_TYPE)
            .urltransform(urldata)
            .exec().log().get();
    },
    // PINCODE

    apiGetIndividualByPincode(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_INDIVIDUAL_BY_PINCODE,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },
    // MASTER COMPANY

    apiAddMasterCompany(data) {
        console.log({ apiAddProductType: data });
        return this.request(this.api().ADD_MASTER_COMPANY, data).exec().log().get();
    },
    apiGetMasterCompanies(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_ALL_MASTER_COMPANIES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },
}