import React from "react";

const RoleCard = (props) => {
  let { name, data = [], title, crudOperation = true } = props;
  //   console.log(name, data);

  return (
    <div className="role_card">
      <div className="card_header">
        <h3 className="title">{title?.toTitleCase() || name?.toTitleCase()}</h3>
      </div>
      <hr />
      <div className="card_body">
        {data &&
          data.map((item, i) => {
            return (
              <div className="list_box" key={i}>
                <p className="title">{item[name]}</p>
                <div
                  className={Object.className({
                    action_box: true,
                    "d-none": !crudOperation,
                  })}
                >
                  <button
                    className="delete me-2"
                    onClick={(e) => props.onDelete(e, item)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                  <button className="edit">
                    <i className="fa fa-pencil"></i>
                  </button>
                </div>
              </div>
            );
          })}
        {data.length == 0 && (
          <div className="text-center">No {name} found...</div>
        )}
      </div>
      <div
        className={Object.className({
          card_footer: true,
          "d-none": props.hideFooter,
        })}
      >
        <AppForm onSubmit={props.onSubmit}>
          <div className="col-12 mb-3">
            <input
              id={`role-${name}-input`}
              type="text"
              className="form-control w-100"
              placeholder={`Please enter ${name}`}
              name={name}
            />
          </div>
          <div className="col-12 mb-3">
            <button type="submit" className="btn btn-primary w-100">
              Submit
            </button>
          </div>
        </AppForm>
      </div>
    </div>
  );
};

export default RoleCard;
