import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

const PostSanctionedDocsEdit = (props) => {
  const [checkValue, setCheckValue] = useState("sameAddressCheck");
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [sameFields, setSameFields] = useState([
    {
      name: "residenceStatus",
      label: "Residence Status",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "state",
      label: "State",
      type: "select",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    // {
    //   name: "docType",
    //   label: "Document Type",
    //   type: "select",
    //   options: _enum.DOC_TYPES_NEW,
    // },
    // {
    //   name: "fileId",
    //   label: "Document Upload",
    //   fileLabel: "Upload document",
    //   foldername: "postSanctionedDocs",
    //   type: "file",
    //   placeholder: "Please enter",
    // },
    {
      name: "address1",
      label: "Address",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
  ]);
  const [newFields, setNewFields] = useState([
    {
      name: "address1",
      label: "Address 1",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
      required: true,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "postSanctionedDocs",
      type: "file",
      placeholder: "Please enter",
    },
  ]);

  const [referenceFields1, setReferenceFields1] = useState([
    {
      label: "Enter Name",
      name: "reference1name",
      type: "text",
    },
    {
      label: "Mobile No.",
      name: "reference1mobile",
      type: "number",
    },
    {
      label: "Relation",
      name: "reference1relation",
      type: "select",
      options: _enum.RELATIONS_1,
    },
  ]);
  const [referenceFields2, setReferenceFields2] = useState([
    {
      label: "Enter Name",
      name: "reference2name",
      type: "text",
    },
    {
      label: "Mobile No.",
      name: "reference2mobile",
      type: "number",
    },
    {
      label: "Relation",
      name: "reference2relation",
      type: "select",
      options: _enum.RELATIONS_2,
    },
  ]);

  const [bankFields, setBankFields] = useState([
    { label: "Account Holder Name", name: "accountHolderName", type: "text" },
    { label: "Account No.", name: "accountNumber", type: "number" },
    {
      label: "Bank Name",
      name: "accountHolderBankName",
      type: "text",
    },
    {
      label: "IFSC Code",
      name: "accountHolderBankIFSC",
      type: "text",
      onChange: (...args) => handleIfscCodeChange(...args),
    },
  ]);

  const [additionalDocs, setAdditionalDocs] = useState([
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      onChange: (...args) => handleAdditionalDocsChange(...args),
      options: _enum.DOC_TYPES_NEW_WITH_OTHERS,
      required: true,
    },
    {
      name: "documentName",
      label: "Document Name",
      type: "text",
      columnClass: "d-none",
      required: true,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "postSanctionedDocs",
      type: "file",
      placeholder: "Please enter",
      required: true,
    },
  ]);

  const handleIfscCodeChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const ifscReg = _enum?.REGEX?.ifsc;
    const fieldValue = e.target.value;
    let submitBtn = document.querySelector("#bankAccSubmitBtn");

    if (ifscReg.test(fieldValue)) {
      setErrorMessage("");
      submitBtn?.classList.remove("pe-none");
    } else {
      setErrorMessage({
        name: "accountHolderBankIFSC",
        message: "Please enter a valid IFSC Code",
      });
      submitBtn?.classList.add("pe-none");
    }
  };

  const handleAdditionalDocsChange = (args) => {
    var documentNameInput = document.querySelector("#column-documentName");

    const hasOthers = args?.some((obj) => obj.key === "OTHERS");

    if (hasOthers) {
      documentNameInput.classList.remove("d-none");
    } else {
      documentNameInput.classList.add("d-none");
    }
    setAdditionalDocs([...additionalDocs]);
  };

  const handleCommunicationAddress = (e) => {
    e.persist();
    let value = e.target.value;
    console.log({ value });

    setCheckValue(value);
  };

  const resolveResponse = (msg) => {
    swal2.fire({
      title: msg,
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
    props.closeEditView();
  };

  const onCommunicationSubmit = (e, data) => {
    let payload = {
      ...Object.except(data, ["communicationAddress"]),
      isAadharAddress: checkValue === "sameAddressCheck" ? true : false,
    };
    let addressId = props.editdata?.permanent_address?.addressId;
    props.apis
      .apiUpdateUnderWriterPermanentAddressInfo(payload, {
        addressId: addressId,
      })
      .then(() => {
        resolveResponse("Permanent Address Updated Successfully");
      });
  };

  const referenceSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    let applicationId = parseInt(props.urlparams.params.id);
    props.apis
      .apiUpdateUnderWriterReferenceDetails(payload, {
        applicationId: applicationId,
      })
      .then(() => {
        resolveResponse("Reference Details Updated Successfully");
      });
  };

  const bankAccountSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    let applicationId = parseInt(props.urlparams.params.id);
    props.apis
      .apiUpdateUnderWriterAccountDetails(payload, {
        applicationId: applicationId,
      })
      .then(() => {
        resolveResponse("Bank Account Details Updated Successfully");
      });
  };

  const additionalDocsSubmit = (e, data) => {
    let payloadObj = {};

    if (data?.docType === "OTHERS") {
      payloadObj = {
        ...Object.except(data, ["documentName"]),
        fileName: data?.documentName,
      };
    } else {
      payloadObj = {
        ...Object.except(data, ["documentName"]),
      };
    }
    let payload = [payloadObj];
    // console.log({ data, payload });
    let applicationId = parseInt(props.urlparams.params.id);
    props.apis
      .apiUpdateUnderWriterAdditionalDocs(payload, {
        applicationId: applicationId,
      })
      .then(() => {
        resolveResponse("Additional Documents Updated Successfully");
      });
  };

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      console.log({ data, field });
      if (data !== undefined && field !== undefined) {
        if (data[field?.name] === undefined) {
        }
        if (field.type === "file") {
          // console.log({ data, field });
          field.defaultValue = data?.fileId;
          field.value = data?.fileId;
          // field.disabled = true;
          return {
            ...field,
          };
        } else if (field.name === "docType") {
          // console.log(data, field, data[field?.name]);
          field.defaultValue = data[field?.name];
          field.value = data[field?.name];
          return {
            ...field,
          };
        } else if (field.name === "documentName") {
          return {
            ...field,
            defaultValue: data["fileName"],
          };
        } else {
          return {
            ...field,
            defaultValue: data[field?.name],
          };
        }
      }
    });
    return result;
  };

  useEffect(() => {
    if (props.editdata) {
      console.log(props.editdata);
      //------------------------- UPDATE SAME ADDRESS -------------------------
      let updatedAddress = updateFields(
        sameFields,
        props?.editdata?.permanent_address
      );
      setSameFields([...updatedAddress]);

      //------------------------- UPDATE NEW ADDRESS -------------------------
      let updatedNewAddress = updateFields(
        newFields,
        props?.editdata?.permanent_address
      );
      setNewFields([...updatedNewAddress]);

      //------------------------- UPDATE REFERENCE DETAILS -------------------------
      let updatedReferenceDetails1 = updateFields(
        referenceFields1,
        props?.editdata?.reference_details_1
      );
      setReferenceFields1([...updatedReferenceDetails1]);

      let updatedReferenceDetails2 = updateFields(
        referenceFields2,
        props?.editdata?.reference_details_2
      );
      setReferenceFields2([...updatedReferenceDetails2]);

      //------------------------- UPDATE BANK DETAILS -------------------------
      let updatedBankDetails = updateFields(
        bankFields,
        props?.editdata?.bank_account_details
      );
      setBankFields([...updatedBankDetails]);

      //------------------------- UPDATE ADDITIONAL DOCS -------------------------
      let updatedAdditionalDocs = updateFields(
        additionalDocs,
        props?.editdata?.additionalDocsSubmitted
      );
      setAdditionalDocs([...updatedAdditionalDocs]);
    }
  }, [props.editdata]);

  return (
    <div className="psdocs_acc_container">
      {/* <button
        type="button"
        className="btn btn-sm btn-primary ms-3"
        onClick={(...args) => props.closeEditView(...args)}
      >
        Back
      </button> */}

      <Accordion
        defaultActiveKey={props?.activeAccordion}
        className="psdocs_acc mt-4"
      >
        <Accordion.Item eventKey="permanent_address">
          <Accordion.Header>
            Permanent Address
            <div className="btn_arrow_container">
              <i class="fa-solid fa-chevron-up btn_arrow up"></i>
              <i class="fa-solid fa-chevron-down btn_arrow down"></i>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div class="content">
              <AppForm onSubmit={onCommunicationSubmit}>
                <div className="checkbox_container mb-4">
                  <div class="form-check me-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="sameAddressCheck"
                      name="communicationAddress"
                      id="sameAddressCheck"
                      onChange={(...args) =>
                        handleCommunicationAddress(...args)
                      }
                      checked={checkValue === "sameAddressCheck"}
                    />
                    <label class="form-check-label" for="sameAddressCheck">
                      Same as communication address
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="newAddressCheck"
                      name="communicationAddress"
                      id="newAddressCheck"
                      onChange={(...args) =>
                        handleCommunicationAddress(...args)
                      }
                      checked={checkValue === "newAddressCheck"}
                    />
                    <label class="form-check-label" for="newAddressCheck">
                      New Address
                    </label>
                  </div>
                </div>
                {checkValue === "sameAddressCheck" &&
                  sameFields?.map((field, i) => {
                    return (
                      <div
                        className={`col-12 col-md-${field?.customcolspan || 4}`}
                        key={i}
                      >
                        <AppInput {...field}></AppInput>
                      </div>
                    );
                  })}
                {checkValue === "newAddressCheck" &&
                  newFields?.map((field, i) => {
                    return (
                      <div
                        className={`col-12 col-md-${field?.customcolspan || 4}`}
                        key={i}
                      >
                        <AppInput {...field}></AppInput>
                      </div>
                    );
                  })}
                <div className="nav_container mt-4">
                  <button
                    type="button"
                    className="btn btn-primary-lite me-3"
                    onClick={(...args) => props.closeEditView(...args)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-3">
                    Update
                  </button>
                </div>
              </AppForm>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* REFERENCE DETAILS FORM */}
        <Accordion.Item eventKey="reference_details">
          <Accordion.Header>
            Reference Details
            <div className="btn_arrow_container">
              <i class="fa-solid fa-chevron-up btn_arrow up"></i>
              <i class="fa-solid fa-chevron-down btn_arrow down"></i>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div class="content">
              <AppForm onSubmit={referenceSubmit}>
                <h3 className="grey_title">Reference 1</h3>
                {referenceFields1?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4}`}
                      key={i}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                })}
                <h3 className="grey_title mt-3">Reference 2</h3>
                {referenceFields2?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4}`}
                      key={i}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                })}
                <div className="nav_container mt-4">
                  <button
                    type="button"
                    className="btn btn-primary-lite me-3"
                    onClick={(...args) => props.closeEditView(...args)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-3">
                    Update
                  </button>
                </div>
              </AppForm>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* BANK ACCOUNT DETAILS */}
        <Accordion.Item eventKey="bank_account_details">
          <Accordion.Header>
            Bank Account Details
            <div className="btn_arrow_container">
              <i class="fa-solid fa-chevron-up btn_arrow up"></i>
              <i class="fa-solid fa-chevron-down btn_arrow down"></i>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div class="content">
              <AppForm onSubmit={bankAccountSubmit}>
                {bankFields?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4}`}
                      key={i}
                    >
                      <AppInput
                        errorMessage={errorMessage}
                        {...field}
                      ></AppInput>
                    </div>
                  );
                })}
                <div className="nav_container mt-4">
                  <button
                    type="button"
                    className="btn btn-primary-lite me-3"
                    onClick={(...args) => props.closeEditView(...args)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary ms-3"
                    id="bankAccSubmitBtn"
                  >
                    Update
                  </button>
                </div>
              </AppForm>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* ADDITIONAL DOCS */}
        <Accordion.Item eventKey="additional_docs">
          <Accordion.Header>
            Additional Document
            <div className="btn_arrow_container">
              <i class="fa-solid fa-chevron-up btn_arrow up"></i>
              <i class="fa-solid fa-chevron-down btn_arrow down"></i>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div class="content">
              <AppForm onSubmit={additionalDocsSubmit}>
                {additionalDocs?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4} ${
                        field?.columnClass || ""
                      }`}
                      id={`column-${field?.name}`}
                      key={i}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                })}
                <div className="nav_container mt-4">
                  <button
                    type="button"
                    className="btn btn-primary-lite me-3"
                    onClick={(...args) => props.closeEditView(...args)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-3">
                    Update
                  </button>
                </div>
              </AppForm>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default constore(PostSanctionedDocsEdit);
