import React, { Component } from "react";
import menu from "../menu";
import Nav from "../../layouts/nav";
import Footer from "../../layouts/Footer";
// import ScrollToTop from "../layouts/ScrollToTop";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.menu = menu;
  }
  componentDidMount() {
    this.setState({ isClient: true });
    this.props.store("mounted", true);
    this.props.services
      .checkAuthorize(this.props, "admin")
      .then(() => {
        this.props.services.checkExpiry();
      })
      .catch((err) => {});
  }
  render() {
    let props = this.props;
    let sideMenu = props.$store.sideMenu;
    return (
      <div
        id="main-wrapper"
        className={`show ${sideMenu ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          {props.children}
        </div>
        <Footer />

        {/* TOP LEVEL MODAL */}
        {/* <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default constore(Layout);
