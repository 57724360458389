import React, { useEffect, useState } from "react";

const InfoCard = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  const [customerinfo, setCustomerinfo] = useState({});
  useEffect(() => {
    setCustomerinfo({
      name: props?.$store?.user?.customerName,
      mobile: props?.$store?.user?.mobileNumber,
      email: props?.$store?.user?.emailAddress,
    });
  }, []);

  const { data } = props;
  let dob = data?.dob;
  const date_of_birth = new Date(dob).dateToDDMMYYYY(new Date(dob));
  const age = new Date(dob).getAge();
  var address, docproof, docprooflink;
  if (data?.CustomerAddressInfo && data?.CustomerAddressInfo.length > 0) {
    address =
      (data?.CustomerAddressInfo[0]?.address1 !== null
        ? data?.CustomerAddressInfo[0]?.address1?.toTitleCase()
        : "") +
      (data?.CustomerAddressInfo[0]?.address2 !== null
        ? `, ${data?.CustomerAddressInfo[0]?.address2?.toTitleCase()}`
        : "") +
      (data?.CustomerAddressInfo[0]?.address3 !== null
        ? `, ${data?.CustomerAddressInfo[0]?.address3?.toTitleCase()}.`
        : "");

    docproof = `${
      _enum.DOC_TYPES_MAP[data?.CustomerAddressInfo[0]?.docType] || "-"
    }`;
    docprooflink = data?.CustomerAddressInfo[0]?.fileId;
  } else {
    address = "";
  }

  var pincode;
  if (data?.CustomerAddressInfo && data?.CustomerAddressInfo.length > 0) {
    pincode = `${data?.CustomerAddressInfo[0]?.pincode}`;
  } else {
    pincode = "";
  }

  if (props?.data) {
    let name = props?.data?.customerName;
    let firstLetter = name.charAt(0); // Get the first letter of the first word
    let secondLetterIndex = name.indexOf(" ") + 1; // Get the index of the first letter of the word after the space
    let secondLetter = name.charAt(secondLetterIndex); // Get the second letter of the word after the space
    let result = firstLetter + secondLetter; // Concatenate the first and second letters
    var initialValue = result ? result : "";
  }

  console.log({ data });

  return (
    <div className="info_card">
      <div className="content">
        <div className="name_card">
          <div className="avatar_circle">{initialValue || ""}</div>
          <h2 className="name">{data?.customerName || "-"}</h2>
        </div>
        <div className="content_list_container row">
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">User ID: </span>
              <span>{data?.customerId || "-"}</span>
            </li>
            <li>
              <span className="icon">Gender: </span>
              <span>{data?.gender?.toTitleCase() || "-"}</span>
            </li>
            <li>
              <span className="icon">Date of Birth:</span>
              <span>{date_of_birth || "-"}</span>
            </li>
            <li>
              <span className="icon">Age: </span>
              <span>{age || "-"}</span>
            </li>
          </ul>
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">Mobile No:</span>
              <span>{data?.mobileNumber || "-"}</span>
            </li>
            <li>
              <span className="icon">Email ID:</span>
              <span>{data?.emailAddress || "-"}</span>
            </li>
            <li>
              <span className="icon">PIN Code: </span>
              <span>{pincode || "-"}</span>
            </li>
          </ul>
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">Communication Address:</span>
              <span className="whitespace-preline">{address || "-"}</span>
            </li>
            <li>
              <span className="icon">Document Proof:</span>
              <div className="d-flex flex-wrap">
                <span className="whitespace-preline">{docproof || "-"}</span>
                <span className="ms-2">
                  {docprooflink && (
                    <a
                      href={`${BASE_URL}${docprooflink}`}
                      className="download_link white"
                    >
                      Download
                    </a>
                  )}
                </span>
              </div>
            </li>
          </ul>
        </div>
        {data?.status !== "COMPLETED" && (
          <button
            className="edit_btn"
            onClick={(...args) => props.onEdit(...args, "profiledetails")}
          >
            <i className="fa fa-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default constore(InfoCard);
