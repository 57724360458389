import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import RepaymentReportSearch from "./RepaymentReportSearch/RepaymentReportSearch";
import { Dropdown } from "react-bootstrap";

const RepaymentReport = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "borrowerName",
      title: "Name",
      sorter: (a, b) =>
        a.loanAccountDetails?.borrowerName.length -
        b.loanAccountDetails?.borrowerName.length,
      render: (text, record) => {
        return (
          <h6 className="text-primary">
            {record?.loanAccountDetails?.borrowerName}
          </h6>
        );
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "paymentDate",
      title: "Payment Date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "paymentAmt",
      title: "Payment Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "paymentMode",
      title: "Payment Mode",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.paymentStatus?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.paymentStatus?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "paymentReferenceNum",
      title: "Payment Ref. No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "totalAmt",
      title: "Total Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    let payload = {
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    };
    getAllLoanRepayments(payload, false);
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);

    let alteredData = {};

    if (data?.start_date?.length > 0) {
      alteredData.start_date = data?.start_date;
    }
    if (data?.end_date?.length > 0) {
      alteredData.end_date = data?.end_date;
    }

    if (Object.keys(data).length === 0) {
      setSearch({
        ...alteredData,
        ...search,
      });
      getAllLoanRepayments(alteredData, reset);
    } else {
      setSearch({
        ...alteredData,
        ...search,
      });
      getAllLoanRepayments(alteredData, reset);
    }
  };

  const downloadFile = async () => {
    let payload = {};

    let startDateValue = document.querySelector("[name='start_date']")?.value;
    let endDateValue = document.querySelector("[name='end_date']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }

    props.apis.apiExportRepaymentReport(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `repayment_report_${new Date().toDate()}.xlsx`
        );
        link.click();
      }
    });
  };

  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedLanNumbers, setSelectedLanNumbers] = useState([]);
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys, selectedRows);
    let selectedLoanAccountNumbers = selectedRows?.map(
      (v) => v.loanAccountNumber
    );
    setSelectedLanNumbers(selectedLoanAccountNumbers);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => {
    //   let { role } = props?.$store?.user;
    //   if (role === _enum.PERSONAS.sales && record?.status !== "INTERESTED") {
    //     return { disabled: true };
    //   }
    // },
  };

  const getAllLoanRepayments = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetAllLoanRepayments(payload).then(({ data, total }) => {
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  useEffect(() => {
    getAllLoanRepayments();
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Reports"
        back={"Repayment"}
        backLink={`/app/repaymentreports`}
        pageTitle={"Repayment"}
        parentTitle={"Home"}
        pageIcon={imagepaths.reportsIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Repayment Report</h4>
                  <RepaymentReportSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                <div className="action_btn_group text-end mb-4">
                  <button
                    type="button"
                    className="btn btn-primary ms-1 me-5"
                    onClick={(...args) => downloadFile(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                  checkbox={true}
                  rowSelection={rowSelection}
                  isCustomRowKey={true}
                  customRowKey={"repaymentId"}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(RepaymentReport);
