import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import VendorsSearch from "./VendorsSearch/VendorsSearch";

const ServiceVendorRecords = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "vendor",
      title: "Vendor",
      render: (text, record) => {
        return (
          <div className="products">
            <div>
              <h6 className="text-primary">{text}</h6>
            </div>
          </div>
        );
      },
    },
    { dataIndex: "service", title: "Service" },
    { dataIndex: "hits", title: "Hits" },
  ];

  const downloadExcel = async () => {
    let startDateInput = document?.querySelector(
      '[name="appinput-startDate"] input[name="startDate"]'
    );
    let endDateInput = document?.querySelector(
      '[name="appinput-endDate"] input[name="endDate"]'
    );

    if (
      startDateInput?.value?.length === 0 &&
      endDateInput?.value?.length === 0
    ) {
      swal2.fire({
        title: "Please select a start date and end date",
        timer: 3000,
        icon: "warning",
        iconColor: "#FF4D4D",
      });
    } else {
      let urldata = {
        startDate: new Date(startDateInput?.value).toISOString(),
        endDate: new Date(endDateInput?.value).toISOString(),
      };
      console.log({ urldata });

      // props.apis.apiGetEquifaxExcelFile(urldata).then(async (res) => {
      //   if (res) {
      //     let data = await res;
      //     let blob = new Blob([data], {
      //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     });
      //     let link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.setAttribute(
      //       "download",
      //       `equifax_excel_report_${new Date().toDate()}.xlsx`
      //     );
      //     link.click();
      //   }
      // });
    }
  };

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  const getMis = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    // props.apis.apiGetCibilUsersHistory(payload).then(({ data, total }) => {
    //   setIsFetching(false);
    //   setTableData(data);
    //   setRowcount(total);
    // });
    setIsFetching(false);
    setTableData([]);
    setRowcount(0);
  };

  useEffect(() => {
    let payload = {
      ...search,
    };
    getMis(payload);
  }, [search]);

  return (
    <>
      <MainPagetitle
        mainTitle="Know Your Customer"
        back="Service Vendor Records"
        backLink="/app/kyc"
        pageTitle={"Know Your Customer"}
        parentTitle={"Home"}
        pageIcon={imagepaths.PersonSearchIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <div className="w-100 d-flex flex-column">
                    <h4 className="heading mb-3">Service Vendor Records</h4>
                    <VendorsSearch onSearch={(...arg) => onSearch(...arg)} />
                  </div>
                  <div className="d-flex align-self-end">
                    <button
                      type="button"
                      className="btn btn-primary vendor_btn"
                      onClick={(...args) => downloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(ServiceVendorRecords);
