import React, { useEffect, useRef, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Modal } from "react-bootstrap";
import FlexCard from "../LoanApplications/Helpers/FlexCard";

const MyApplicationView = (props) => {
  const [show, setShow] = useState(false);
  const [applicationData, setApplicationData] = useState([]);
  const [editdata, setEditdata] = useState({});

  const [checkValue, setCheckValue] = useState("currentOutstanding");

  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const [payinPayload, setPayinPayload] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (payinPayload && formRef.current) {
      formRef.current.submit();
    }
  }, [payinPayload]);

  const transactionColumns = [
    {
      dataIndex: "createdAt",
      title: "Txn. Date",
      render: (text, record) => {
        if (record?.createdAt) {
          let date = new Date(record?.createdAt).dateToDDMMYYYY(
            new Date(record?.createdAt)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "paymentAmt",
      title: "Txn. Amt.",
      render: (text, record) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "transactionType",
      title: "Txn. Type",
    },
    {
      dataIndex: "orderNo",
      title: "Reference No.",
    },
    {
      dataIndex: "status",
      title: "Txn. Status",
    },
  ];

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const getApplication = () => {
    let payload = {
      applicationId: props.urlparams.params.id,
    };
    props.apis.apiGetCustomerApplicationById(payload).then((res) => {
      setEditdata(res);
      setApplicationData({
        loan_details: {
          loan_Id: res?.loanAccountNumber,
          loan_amount: res?.sanctionedLoanAmount?.cur(),
          start_date: new Date(res?.sanctionedLoanStartDate).dateToDDMMYYYY(
            new Date(res?.sanctionedLoanStartDate)
          ),
          end_date: new Date(res?.sanctionedLoanEndDate).dateToDDMMYYYY(
            new Date(res?.sanctionedLoanEndDate)
          ),
          loan_agreement: res?.loanAgreementFileId,
          tenure: res?.sanctionedLoanTenure,
          ROI: res?.sanctionedLoanIRR,
          processing_fee: res?.sanctionedLoanPF?.cur(),
          interest: res?.interest?.cur(),
          penal_interest: res?.interest?.cur(),
          total_payable: res?.totalAmount?.cur(),
          loan_status: res?.loanAccountStatus,
        },
      });
      setTransactionTableData(res?.loanAccountPayinTnx);
      setIsFetching(false);
    });
  };

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handlePayment = (e) => {
    e.persist();
    let value = e.target.value;
    setCheckValue(value);
  };

  const handleSubmit = (e, data) => {
    let payload = {
      loanAccountNumber: editdata.loanAccountNumber || "",
      redirecturl: `${endpoints[environment].baseFE_URL}app/myapplications`,
    };

    if (checkValue === "currentOutstanding") {
      payload.paymentAmt = editdata.otherAmount || "";
    } else {
      payload.paymentAmt = data?.amount;
    }

    const resolve = () => {
      handleClose();
      getApplication();
    };

    props.apis
      .apiPayinByCustomer(payload)
      .then((res) => {
        setPayinPayload(res);
      })
      .catch((err) => {
        console.log(err);
        resolve();
      });
  };

  useEffect(() => {
    getApplication();
  }, []);
  return (
    <>
      <MainPagetitle
        mainTitle={`My Application | (Application No.)${props.urlparams.params.id}`}
        back={"View"}
        backLink="/app/myapplications"
        pageTitle={"My Application"}
        parentTitle={"Home"}
        pageIcon={imagepaths.greyUserCheckIconActive}
      />

      <div className="container-fluid">
        <div className="card p-4">
          <FlexCard
            title="Loan Details"
            data={applicationData && applicationData?.loan_details}
            colspan_key="col-3"
            colspan_value="col-3"
            hasPdfFile={true}
            pdfIdKey="loan_agreement"
          />
          <div className="d-flex justify-content-start w-100">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) => handleShow(...args)}
            >
              Pay Now
            </button>
          </div>
        </div>

        <div className="card p-4">
          <div className="form_group_card px-0">
            <div className="d-flex align-items-start justify-content-between">
              <h2 className="mb-3 theme_text_sm">Transaction History</h2>
            </div>
            <div>
              <AppTable
                data={transactionTableData}
                columns={transactionColumns}
                onChange={(...arg) => onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                editable={false}
                viewable={false}
                deletable={false}
                loading={isFetching}
              ></AppTable>
            </div>
          </div>
        </div>
      </div>
      <form
        ref={formRef}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        action="https://pg.icepe.in/paymentrequest"
        method="post"
        className="flex flex-col gap-4"
      >
        {payinPayload &&
          Object.keys(payinPayload).map((key) => (
            <input
              key={key}
              type="hidden"
              name={key}
              value={payinPayload[key]}
            />
          ))}
      </form>
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal payment_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleSubmit}>
            <div className="payment_modal_content_box">
              <p className="content_title">How much you want to pay?</p>
              <div className="checkbox_container mb-4 flex-column">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="currentOutstanding"
                    name="communicationAddress"
                    id="currentOutstanding"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "currentOutstanding"}
                  />
                  <label class="form-check-label" for="currentOutstanding">
                    Current Outstanding
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="otherAmount"
                    name="communicationAddress"
                    id="otherAmount"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "otherAmount"}
                  />
                  <label class="form-check-label" for="otherAmount">
                    Other Amount
                  </label>
                </div>
              </div>
            </div>
            {checkValue === "otherAmount" && (
              <AppInput name="amount" label="Amount" type="number"></AppInput>
            )}
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Pay Now
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(MyApplicationView);
