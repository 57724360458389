import React, { Component } from "react";
import menu from "./menu";
import "./page-layout.scss";

import Nav from "../layouts/nav";
import Footer from "../layouts/Footer";
// import ScrollToTop from "../layouts/ScrollToTop";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.menu = menu;
  }
  render() {
    let props = this.props;
    let sideMenu = false;
    return (
      <div>
         {props.children}
      </div>
    );
  }
}

export default constore(Layout);