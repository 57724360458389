import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";

function SigninVerify(props) {
  const [storedMobileNumber, setStoredMobileNumber] = useState("");
  const [otpcode, setOtpcode] = useState();
  const [verifyDisabled, setVerifyDisabled] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const navigate = useNavigate();
  const signedMobileNumber = props?.$store?.signedMobileNumber;

  useEffect(() => {
    setStoredMobileNumber(signedMobileNumber);
    setDisableResend(true);

    return () => {
      props.store("signedMobileNumber", null);
      setStoredMobileNumber(null);
    };
  }, []);

  const handleChange = (otp) => {
    // console.log("otp changed: ", otp.length);
    setOtpcode(otp);
    if (otp.length === 6) {
      setOtpcode(otp);
      setVerifyDisabled(false);
    } else {
      setOtpcode(otp);
      setVerifyDisabled(true);
    }
  };

  // -------------------- OTP COUNTDOWN TIMER --------------------
  const timercount = 60;
  const initialTime = localStorage.getItem("countdown") || timercount;
  const [time, setTime] = useState(parseInt(initialTime, 10));
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let countdownInterval;

    if (isRunning) {
      countdownInterval = setInterval(() => {
        if (time > 0) {
          setTime((prevTime) => prevTime - 1);
        } else {
          clearInterval(countdownInterval);
          let timerBox = document.getElementById("otpTimer");
          timerBox.innerText = "OTP expired";
          setIsRunning(false);
          setDisableResend(false);
          localStorage.removeItem("countdown");
        }
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isRunning, time]);

  useEffect(() => {
    localStorage.setItem("countdown", time.toString());
  }, [time]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("countdown");
    };
  }, []);

  const handleRestart = () => {
    setTime(timercount);
    setIsRunning(true);
    setDisableResend(true);
  };

  const handlePausePlay = () => {
    setIsRunning(!isRunning);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log({ storedMobileNumber });

    let data = {
      mobileNumber: storedMobileNumber,
    };
    props.apis
      .apiCustomerSignInRequest(data, { mobileNumber: signedMobileNumber })
      .then((res) => {
        console.log({ apiCustomerSignInRequest: res });
        props.store("signedMobileNumber", signedMobileNumber);
        handleRestart();
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
      });
  };

  const handleOTPverify = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data = {
      mobileNumber: signedMobileNumber,
      otpCode: otpcode,
    };
    props.apis.apiCustomerSignInVerify(data).then((res) => {
      console.log({ apiCustomerSignInVerify: res });
      props.store("logged", res.logged);
      props.store("loggedIn", new Date().getTime());
      props.store("auth", res.auth);
      props.store("user", res.user);
      props.store("persona", _enum.ROUTE_PERSONAS.customer);

      if (res?.user?.status === "COMPLETED") {
        navigate("/app/customerhome");
      } else {
        navigate("/app/myprofile");
      }
    });
  };

  props.$setNode({
    props,
  });
  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/customer/login"} className="logo">
                    <img src={imagepaths.loginWhiteBg} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="login-form style-2 py-4">
                  <div className="card-body">
                    <nav className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane active show fade">
                          {props.errorMessage && (
                            <div className="">{props.errorMessage}</div>
                          )}
                          {props.successMessage && (
                            <div className="">{props.successMessage}</div>
                          )}
                          <h3 className="form-title m-t0 text-center">
                            Enter OTP
                          </h3>
                          <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                          </div>
                          <p className="sub-title text-center">
                            Access to explore more
                          </p>
                          <form className="dz-form py-2">
                            <span className="d-block text-center mx-auto mt-3 mb-0 otp_top_text">
                              OTP to verify
                            </span>
                            <OTPInput
                              value={otpcode}
                              onChange={(...args) => handleChange(...args)}
                              numInputs={6}
                              separator={<span>-</span>}
                              renderInput={(props) => <input {...props} />}
                              containerStyle="otp_container"
                            />

                            <div className="otp_timer_box d-flex flex-column justify-content-center mb-5">
                              {/* OTP COUNTDOWN TIMER */}
                              <p className="countdown_timer_box text-center">
                                <span className="me-1">Resend OTP in:</span>
                                <span className="mb-0 otp_timer" id="otpTimer">
                                  {formatTime(time)}
                                </span>
                              </p>
                            </div>

                            <div className="form-group clearfix text-left mb-4">
                              <button
                                type="submit"
                                className="btn btn-primary button-md btn-block"
                                onClick={(e) => handleOTPverify(e)}
                                disabled={verifyDisabled}
                              >
                                Verify
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="text-center otp_bottom">
                          <span className="me-2">Not received OTP?</span>
                          <button
                            type="button"
                            className={Object.className({
                              "plain-text-btn": true,
                              // "text-muted": disableResend,
                            })}
                            disabled={disableResend}
                            onClick={(...args) => handleResendOtp(...args)}
                          >
                            Resend
                          </button>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default constore(SigninVerify);
