import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainPagetitle from "../../../../layouts/MainPagetitle";

const LoanCalculationDocs = (props) => {
  const fromPath = props?.urlparams?.params?.path;
  const [checkValue, setCheckValue] = useState("MONTHLY");
  const editmode = props.urlparams.params.id ? true : false;
  const applicationId = Number(props?.urlparams?.params?.id);
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  // ----------------------------------------------------------------
  const [show, setShow] = useState(false);
  const [activePayslip, setActivePayslip] = useState({});
  const [payslipfiles, setPayslipfiles] = useState({
    payslip1: {},
    payslip2: {},
    payslip3: {},
  });
  const [bankstatementfiles, setBankstatementfiles] = useState({
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
    // bankStatement4: {},
    // bankStatement5: {},
    // bankStatement6: {},
  });
  const [passcheck, setPasscheck] = useState(false);
  const [consolPassCheck, setConsolPassCheck] = useState(false);
  const [editdata, setEditdata] = useState(null);
  // ----------------------------------------------------------------
  const [showbankmodal, setShowbankmodal] = useState(false);
  const [activeBankStatement, setActiveBankStatement] = useState({});
  const [bankPassCheck, setBankPassCheck] = useState(false);

  // -----------------------------------------------------------------
  // COLUMNS AND FIELDS

  const payslipModalBtns = [
    {
      name: "pay_slip1",
      label: "Pay slip - 1",
      type: "modalFile",
      index: 1,
      modalTitle: "Month 1 - Salary Slip",
      placeholder: "Month 1 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "pay_slip2",
      label: "Pay slip - 2",
      type: "modalFile",
      index: 2,
      modalTitle: "Month 2 - Salary Slip",
      placeholder: "Month 2 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "pay_slip3",
      label: "Pay slip - 3",
      type: "modalFile",
      index: 3,
      modalTitle: "Month 3 - Salary Slip",
      placeholder: "Month 3 - Upload pdf",
      customcolspan: 4,
    },
  ];

  const bankModalBtns = [
    {
      name: "bank_statement_1",
      label: "Bank Statement - 1",
      type: "modalFile",
      index: 1,
      modalTitle: "Month 1 - Bank Statement",
      placeholder: "Month 1 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "bank_statement_2",
      label: "Bank Statement - 2",
      type: "modalFile",
      index: 2,
      modalTitle: "Month 2 - Bank Statement",
      placeholder: "Month 2 - Upload pdf",
      customcolspan: 4,
    },
    {
      name: "bank_statement_3",
      label: "Bank Statement - 3",
      type: "modalFile",
      index: 3,
      modalTitle: "Month 3 - Bank Statement",
      placeholder: "Month 3 - Upload pdf",
      customcolspan: 4,
    },
    // {
    //   name: "bank_statement_4",
    //   label: "Bank Statement - 4",
    //   type: "modalFile",
    //   index: 4,
    //   modalTitle: "Month 4 - Bank Statement",
    //   placeholder: "Month 4 - Upload pdf",
    //   customcolspan: 4,
    // },
    // {
    //   name: "bank_statement_5",
    //   label: "Bank Statement - 5",
    //   type: "modalFile",
    //   index: 5,
    //   modalTitle: "Month 5 - Bank Statement",
    //   placeholder: "Month 5 - Upload pdf",
    //   customcolspan: 4,
    // },
    // {
    //   name: "bank_statement_6",
    //   label: "Bank Statement - 6",
    //   type: "modalFile",
    //   index: 6,
    //   modalTitle: "Month 6 - Bank Statement",
    //   placeholder: "Month 6 - Upload pdf",
    //   customcolspan: 4,
    // },
  ];

  const payslipFields = [
    {
      name: "payslipDate",
      label: "Select Month",
      type: "month",
    },
    {
      name: "fileId",
      label: "Upload",
      type: "defaultfile",
      fileLabel: "Upload pdf",
      accept: "application/pdf",
      foldername: "payslipDocs",
    },
  ];

  const bankFields = [
    {
      name: "bankname",
      label: "Bank Name",
      type: "select",
      options: _enum.STATIC_BANK_NAMES,
    },
    {
      name: "startDate",
      label: "Start date",
      type: "date",
    },
    {
      name: "endDate",
      label: "End date",
      type: "date",
    },
    {
      name: "fileId",
      label: "Upload",
      type: "defaultfile",
      fileLabel: "Upload pdf",
      accept: "application/pdf",
      foldername: "bankStatementDocs",
    },
  ];

  const consolidatedFields = [
    {
      name: "bankname",
      label: "Bank Name",
      type: "select",
      options: _enum.STATIC_BANK_NAMES,
      placeholder: "Please enter",
    },
    {
      name: "startDate",
      label: "Select From Date",
      type: "date",
      required: true,
    },
    {
      name: "endDate",
      label: "Select To Date",
      type: "date",
      required: true,
    },
    {
      name: "fileId",
      label: "Upload",
      type: "defaultfile",
      fileLabel: "Upload pdf",
      foldername: "bankStatementDocs",
      accept: "application/pdf",
      required: true,
    },
    // {
    //   name: "filePassword",
    //   label: "password",
    //   type: "text",
    //   placeholder: "Enter Password",
    // },
    // {
    //   name: "passwordprotected",
    //   label: "File password protected",
    //   type: "checkbox",
    //   customcolspan: 12,
    //   outerclass: "d-flex",
    //   className: "inline_checkbox",
    // },
  ];

  // ---------------------  PAYSLIP ---------------------
  const getEndDateOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    return new Date(year, month, lastDay).toISOString() || "";
  };

  const parseCustomDate = (dateStr) => {
    const [monthStr, yearStr] = dateStr.split("-");
    const month = new Date(
      Date.parse(monthStr + " 1, " + new Date().getFullYear())
    ).getMonth();
    const year = parseInt(yearStr, 10);

    return new Date(year, month, 1);
  };

  const handlePayslipSubmit = (e, data) => {
    let payslipDate = parseCustomDate(data?.payslipDate);

    let payload = {
      ...data,
      applicationId: applicationId,
      index: activePayslip?.index,
      payslipDate: payslipDate.toISOString(),
      filename: data?.fileId?.name?.filename() || "",
    };
    console.log({ data, payload });
    setPayslipfiles((prevState) => ({
      ...prevState,
      [`payslip${activePayslip?.index}`]: payload,
    }));
    handleClose();
  };

  const handlePasswordCheck = (e) => {
    let value = e.target.checked;
    setPasscheck(value);
  };

  const handleConsolidatedPasswordCheck = (e) => {
    let value = e.target.checked;
    setConsolPassCheck(value);
  };

  const handleShow = (e, record) => {
    console.log({ record });
    setActivePayslip(record);
    setShow(true);
  };

  const handleClose = (e) => {
    setActivePayslip({});
    setPasscheck(false);
    setShow(false);
    // remove uploaded file from form
    let fileInput = document.querySelector(
      `[name="appinput-fileId"] .filevalue_container`
    );
    console.log(editmode, fileInput);
    if (editmode === false) {
      console.log("clear upload data");
      if (fileInput && fileInput.value.length > 0) {
        props.apis
          .apiFileRemover({ fileId: fileInput.value })
          .then((res) => {
            console.log({ apiFileRemover: res });
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    }
  };

  const handleFileDownload = (e, record) => {
    console.log(record);
    const url = `${BASE_URL}${record?.fileId}`;
    const link = document.createElement("a");

    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // -------------------  BANK-STATEMENT -------------------

  const toggleBankstatement = (e) => {
    e.persist();
    let value = e.target.value;
    console.log({ value });

    setCheckValue(value);
  };

  const handleBankStatementSubmit = (e, data) => {
    let payload = {
      ...Object.except(data, ["fileName", "startDate", "endDate"]),
      applicationId: applicationId,
      index: activeBankStatement?.index,
      filename: data?.fileId?.name?.filename() || "",
      startDate: new Date(data?.startDate).toISOString(),
      endDate: new Date(data?.endDate).toISOString(),
    };
    console.log({ data, payload });
    setBankstatementfiles((prevState) => ({
      ...prevState,
      [`bankStatement${activeBankStatement?.index}`]: payload,
    }));
    handleBSClose();
  };

  const handleBSPasswordCheck = (e) => {
    let value = e.target.checked;
    setBankPassCheck(value);
  };

  const handleBankShow = (e, record) => {
    console.log({ record });
    setActiveBankStatement(record);
    setShowbankmodal(true);
  };

  const handleBSClose = (e) => {
    setActiveBankStatement({});
    setBankPassCheck(false);
    setShowbankmodal(false);
    // remove uploaded file from form
    // let fileInput = document.querySelector(
    //   `[name="appinput-fileId"] .filevalue_container`
    // );
    // console.log(editmode, fileInput);
    // if (editmode === false) {
    //   console.log("clear upload data");
    //   if (fileInput && fileInput.value.length > 0) {
    //     props.apis
    //       .apiFileRemover({ fileId: fileInput.value })
    //       .then((res) => {
    //         console.log({ apiFileRemover: res });
    //       })
    //       .catch((err) => {
    //         console.log({ err });
    //       });
    //   }
    // }
  };

  const handleApplicationSubmit = (e, data) => {
    console.log({ data });

    let payslipData = [
      payslipfiles.payslip1,
      payslipfiles.payslip2,
      payslipfiles.payslip3,
    ];
    let monthlystatements = [
      bankstatementfiles.bankStatement1,
      bankstatementfiles.bankStatement2,
      bankstatementfiles.bankStatement3,
      // bankstatementfiles.bankStatement4,
      // bankstatementfiles.bankStatement5,
      // bankstatementfiles.bankStatement6,
    ];

    let bankstatements;
    if (checkValue === "MONTHLY") {
      bankstatements = [...monthlystatements];
    } else {
      let consolidatedData = [
        {
          ...Object.except(data, ["passwordprotected"]),
          index: 1,
          applicationId: Number(props.urlparams.params.id),
          filename: data?.fileId?.name?.filename() || "",
          startDate: new Date(data?.startDate).toISOString(),
          endDate: new Date(data?.endDate).toISOString(),
        },
      ];
      bankstatements = [...consolidatedData];
    }

    let allKeysHaveValue = [...payslipData, ...bankstatements].every(
      (payslip) => {
        return (
          Object.keys(payslip).length > 0 &&
          Object.values(payslip).every(
            (value) => value !== undefined && value !== ""
          )
        );
      }
    );

    console.log({ allKeysHaveValue, payslipData, bankstatements });

    if (!allKeysHaveValue) {
      console.log("Some keys do not have value in all objects");
      swal2.fire({
        title: "Please fill all required fields",
        icon: "error",
        iconColor: "#DE3232",
        timer: 3000,
        showCancelButton: false,
      });
    } else {
      console.log("All keys have value in all objects");
      // Call the function
      fetchValues(payslipData, "payslip")
        .then((payslipResponse) => {
          console.log({ payslipResponse });
        })
        .then(() => {
          fetchValues(bankstatements, "bankstatement").then(
            (bankstatementData) => {
              console.log({ bankstatementData });
              swal2
                .fire({
                  title: "Loan Applied Successfully",
                  icon: "success",
                  iconColor: "#DE3232",
                  confirmButtonColor: "#DE3232",
                  confirmButtonText: "Close",
                })
                .then(() => {
                  props.navigate("/app/myapplications");
                });
            }
          );
        });
    }
  };

  // convert files to etag and
  async function fetchValues(objects, type) {
    const newObjects = [];

    for (const obj of objects) {
      let reqdata = {
        file: obj.fileId,
      };
      let filePayload = {};
      if (type === "payslip") {
        filePayload.foldername = "payslipDocs";
      }
      if (type === "bankstatement") {
        filePayload.foldername = "bankStatementDocs";
        let params = {
          bank_name: obj.bankname,
          application_id: obj.applicationId,
          statement_file_password: obj.filePassword || "",
        };
        props.apis
          .apiAddCustomerBSA(
            {
              file: obj.fileId,
              password: obj.filePassword || "",
            },
            { applicationId: obj.applicationId }
          )
          .then((res) => {})
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
      }

      const response = await props.apis
        .apiFileUploader(reqdata, filePayload)
        .catch((err) => {
          if (err) {
            console.log({ err });
          }
        });
      const data = await response;

      // Assuming the API response is an object with a single key
      const key = Object.keys(data)[0];
      const value = data[key];

      // Add a new property to the object with the fetched value
      const newObj = { ...obj, fileId: value };
      console.log({ newObj });

      var payload = {
        ...newObj,
      };
      if (type === "payslip") {
        const postresponse = await props.apis
          .apiAddCustomerPayslip(payload)
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
        console.log(postresponse);
      }
      if (type === "bankstatement") {
        const postresponse = await props.apis
          .apiAddCustomerBankStatament(payload)
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
        console.log(postresponse);
      }

      newObjects.push(newObj);
    }
    // console.log(`${type}:`, newObjects);
    return newObjects;
  }

  const getPageIcon = () => {
    let icon;
    if (fromPath === "customerhome") {
      icon = imagepaths.DashboardActive;
    }
    if (fromPath === "services") {
      icon = imagepaths.serviceHomeIconActive;
    }
    return icon;
  };

  const getMainTitle = () => {
    let mainTitle = "";
    if (fromPath === "customerhome") {
      mainTitle = "Dashboard";
    }
    if (fromPath === "services") {
      mainTitle = "Service";
    }
    return mainTitle;
  };

  const getBreadCums = () => {
    let pagetitle = "";
    if (fromPath === "customerhome") {
      pagetitle = "Loans Rs. 50000";
    }
    if (fromPath === "services") {
      pagetitle = "Loans Rs. 50000";
    }
    return pagetitle;
  };

  const getBackLink = () => {
    let url;
    if (fromPath === "customerhome") {
      url = "/app/customerhome";
    }
    if (fromPath === "services") {
      url = "/app/services";
    }
    return url;
  };

  props.$setNode({
    props,
    payslipfiles,
  });
  return (
    <>
      <MainPagetitle
        parentTitle={"Home"}
        mainTitle={getMainTitle()}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={getPageIcon()}
      />
      <div className="container-fluid">
        <Card className="form_group_card row">
          <div className="row mb-4">
            <h2 className="form_title mb-3">Last 3 months pay slip</h2>
            {payslipModalBtns?.map((field, i) => {
              return (
                <>
                  <div
                    className={`col-12 col-md-${field?.customcolspan || 6}`}
                    key={i}
                  >
                    <div className="modal_file_container">
                      <label>
                        {field?.label}
                        <span className="text-danger">*</span>
                      </label>
                      {Object.keys(payslipfiles[`payslip${i + 1}`]).length >
                      0 ? (
                        <>
                          <Button
                            variant="dark"
                            onClick={(...args) => handleShow(...args, field)}
                            className="w-100 text-left"
                          >
                            {payslipfiles[`payslip${i + 1}`]["filename"]}
                          </Button>
                          {/* <button
                            type="button"
                            onClick={(...args) =>
                              handleFileDownload(
                                ...args,
                                editdata?.applicationPayslip[i]
                              )
                            }
                            className="file_name_btn"
                          >
                            {payslipfiles[`payslip${i + 1}`]["filename"]}
                            <i class="fa-solid fa-download"></i>
                          </button> */}
                        </>
                      ) : (
                        <Button
                          variant="dark"
                          onClick={(...args) => handleShow(...args, field)}
                          className="w-100 text-left"
                        >
                          {field.placeholder}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <h2 className="form_title mb-3">Bank statement</h2>
          <div className="checkbox_container mb-4">
            <div class="form-check me-3">
              <input
                class="form-check-input"
                type="radio"
                value="MONTHLY"
                name="bankstatement"
                id="MONTHLY"
                onChange={(...args) => toggleBankstatement(...args)}
                checked={checkValue === "MONTHLY"}
              />
              <label class="form-check-label" for="MONTHLY">
                Monthly
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="CONSOLIDATED"
                name="bankstatement"
                id="CONSOLIDATED"
                onChange={(...args) => toggleBankstatement(...args)}
                checked={checkValue === "CONSOLIDATED"}
              />
              <label class="form-check-label" for="CONSOLIDATED">
                Consolidated 3 months
              </label>
            </div>
          </div>

          <AppForm
            onSubmit={handleApplicationSubmit}
            className="bank_modal_btns_container"
          >
            {checkValue === "MONTHLY" &&
              bankModalBtns?.map((field, i) => {
                return (
                  <div
                    className={`col-12 col-md-${field?.customcolspan || 6}`}
                    key={i}
                  >
                    <div className="modal_file_container">
                      <label>
                        {field?.label}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      {Object.keys(bankstatementfiles[`bankStatement${i + 1}`])
                        .length > 0 ? (
                        <>
                          <Button
                            variant="dark"
                            onClick={(...args) =>
                              handleBankShow(...args, field)
                            }
                            className="w-100 text-left"
                          >
                            <div className="d-flex align-item-center justify-content-between">
                              {
                                bankstatementfiles[`bankStatement${i + 1}`][
                                  "filename"
                                ]
                              }
                              <i class="fa-solid fa-upload"></i>
                            </div>
                          </Button>
                          {/* <button
                            type="button"
                            onClick={(...args) =>
                              handleFileDownload(
                                ...args,
                                editdata?.applicationPayslip[i]
                              )
                            }
                            className="file_name_btn"
                          >
                            {bankstatementfiles[`bankStatement${i + 1}`]["filename"]}
                            <i class="fa-solid fa-download"></i>
                          </button> */}
                        </>
                      ) : (
                        <Button
                          variant="dark"
                          onClick={(...args) => handleBankShow(...args, field)}
                          className="w-100 text-left"
                        >
                          <div className="d-flex align-item-center justify-content-between">
                            {field.placeholder}
                            <i class="fa-solid fa-upload"></i>
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            {checkValue === "CONSOLIDATED" &&
              consolidatedFields?.map((field, i) => {
                return (
                  <>
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4}`}
                      key={i}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  </>
                );
              })}
            {checkValue === "CONSOLIDATED" && (
              <>
                {consolPassCheck ? (
                  <div className="col-12 col-md-4">
                    <AppInput
                      type="text"
                      label="Password"
                      name="filePassword"
                    ></AppInput>
                  </div>
                ) : null}
                <div className="col-12 col-md-12">
                  <AppInput
                    type="checkbox"
                    label="File password protected"
                    name="passwordprotected"
                    outerclass="d-flex"
                    className="inline_checkbox"
                    onChange={(...args) =>
                      handleConsolidatedPasswordCheck(...args)
                    }
                  ></AppInput>
                </div>
              </>
            )}
            <div className="nav_container">
              <Link
                to={`/app/myapplications`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </Card>
        <div className="alert_note">
          <span className="mandatory text-danger">*</span>
          <p>
            <span>Note:</span> Only online generated bank statement will be
            valid
          </p>
        </div>
      </div>

      {/* PAYSLIP MODAL */}
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{activePayslip?.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handlePayslipSubmit}>
            {payslipFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Form.Check
              type="checkbox"
              id={`default-1`}
              label="File password protected"
              className="ms-3"
              onChange={(...args) => handlePasswordCheck(...args)}
            />
            {passcheck ? (
              <AppInput
                type="text"
                label="Password"
                name="filePassword"
              ></AppInput>
            ) : null}
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>

      {/* BANK-STATEMENT MODAL */}
      <Modal
        show={showbankmodal}
        onHide={(...args) => handleBSClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{activeBankStatement?.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleBankStatementSubmit}>
            {bankFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Form.Check
              type="checkbox"
              id={`default-1`}
              label="File password protected"
              className="ms-3"
              onChange={(...args) => handleBSPasswordCheck(...args)}
            />
            {bankPassCheck ? (
              <AppInput
                type="text"
                label="Password"
                name="filePassword"
              ></AppInput>
            ) : null}
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={(...args) => handleBSClose(...args)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(LoanCalculationDocs);
