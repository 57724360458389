export default {
    debounce(callback, delay = 500) {
        clearTimeout(globalThis.debouncest);
        globalThis.debouncest = setTimeout(() => {
            callback();
        }, delay);
    },
    checkAuthorize(props, role) {
        let redirected = false;
        if (props?.$store?.user?.role !== role) {
            redirected = true;
            console.log({ role });
            // setTimeout(() => {
            //     navigate("/open/login");
            // });
            // swal2.fire({
            //     title: "Oops! Session Timeout",
            //     text: "Try re-login again",
            //     imageUrl: imagepaths.timeoutImg,
            //     imageAlt: "timeoutImg",
            //     customClass: {
            //         image: "timeoutImg"
            //     }
            // });
        }
        // if(props?.$store?.user === undefined || props?.$store?.user === null){}
        console.log({ redirected, props });
        return new Promise((resolve, reject) => redirected ? reject() : resolve());
    },
    checkExpiry() {
        const refreshExpiresIn = app.$store?.auth?.refresh_expires_in || 0;
        const accessExpiresIn = app.$store?.auth?.access_expires_in || 0;
        const lastLoggedIn = Math.abs(new Date(app.$store.loggedIn).diff());
        console.log({ lastLoggedIn });
        const loggedOut = accessExpiresIn == 0 || refreshExpiresIn == 0;
        const loggedIn = !loggedOut && lastLoggedIn < accessExpiresIn;
        const authExpired = lastLoggedIn < refreshExpiresIn && !loggedIn;
        const refreshExpired = lastLoggedIn >= refreshExpiresIn && !authExpired;
        const isTempPwd = loggedIn && app.$store?.user?.isTempPwd;
        const persona = app.$store?.persona;
        const profileCompletionStatus = !!(loggedIn && app.$store?.user?.profileCompletionStatus);
        console.log({ lastLoggedIn, loggedIn, loggedOut, authExpired, refreshExpired, accessExpiresIn });
        if (refreshExpired) {
            // app.apis.apiLogout().then(() => {
            //     navigate("/open/login");
            // }).catch(err => {
            //     navigate("/open/login");
            // });
            const resolve = () => {
                console.log('logout', "DESTROY_SESSION");
                app?.props?.store("auth", false);
                app?.props?.store("user", false);
                app?.props?.store("persona", "");
                localStorage.clear();
                if (app?.props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
                    app?.props.navigate("/open/customer/login");
                } else {
                    app?.props.navigate("/open/login");
                }
            }
            resolve();
        }
    }
}