import React, { useEffect } from "react";

import { Card } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import ProductCard from "../elements/ProductCard";

const Services = (props) => {
  const products = [
    {
      title: "Upto Rs. 50,000",
      desc: "Short-term loans are small-scale financial..",
      image: imagepaths.peoplechattingImg,
      buttonType: "active",
      loanType: "short_term_loan",
    },
    {
      title: "Upto Rs. 1 Lac",
      desc: "Quick 1 Lac short-term loans offer immediate funds..",
      image: imagepaths.smileyCoupleImg,
      buttonType: "inactive",
      loanType: "long_term_loan",
    },
    {
      title: "Upto Rs. 2 Lac",
      desc: "Short-term loans up to ₹2 lac provide swift access..",
      image: imagepaths.shakehandsImg,
      buttonType: "inactive",
      loanType: "long_term_loan",
    },
  ];

  useEffect(() => {}, []);

  props.$setNode({
    props,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Services"
        pageTitle={"Services"}
        parentTitle={"Home"}
        pageIcon={imagepaths.serviceHomeIconActive}
      />
      <div className="container-fluid">
        <Card name="custom-tab" className="dz-card min-h-[600px]">
          <Card.Header className="border-none">
            <div>
              <Card.Title className="heading_title">Top Products</Card.Title>
            </div>
          </Card.Header>
          <div className="row g-3 px-3 mb-5">
            {products &&
              products.map((item, i) => {
                return (
                  <ProductCard
                    key={i}
                    title={item.title}
                    desc={item.desc}
                    image={item.image}
                    buttonType={item.buttonType}
                    link={`/app/loancalculation?path=services`}
                    // handleProduct={props.handleProduct}
                  />
                );
              })}
          </div>
        </Card>
      </div>
    </>
  );
};

export default constore(Services);
