export default {

    // MASTER - LOCATION
    ADD_MASTER_LOCATION: {
        url: "/api/v1/location",
        method: "post",
    },
    GET_MASTER_LOCATIONS: {
        url: "/api/v1/location",
        method: "get",
    },
    UPDATE_MASTER_LOCATION: {
        url: "/api/v1/location/{locationId}",
        method: "put",
    },
    GET_MASTER_LOCATION_BY_ID: {
        url: "/api/v1/location/{locationId}",
        method: "get",
    },
    DELETE_MASTER_LOCATION: {
        url: "/api/v1/location/{locationId}",
        method: "delete",
    },

    // MASTER - ROLES
    ADD_MASTER_ROLE: {
        url: "/api/v1/roles/role",
        method: "post",
    },
    ADD_MASTER_DESIGNATION: {
        url: "/api/v1/roles/designation",
        method: "post",
    },
    ADD_MASTER_DEPARTMENT: {
        url: "/api/v1/roles/department",
        method: "post",
    },

    GET_MASTER_ROLES: {
        url: "/api/v1/master-role",
        method: "get",
    },
    GET_MASTER_DESIGNATIONS: {
        url: "/api/v1/roles/designations",
        method: "get",
    },
    GET_MASTER_DEPARTMENTS: {
        url: "/api/v1/roles/departments",
        method: "get",
    },

    DELETE_MASTER_ROLE: {
        url: "/api/v1/roles/role/{role}",
        method: "delete",
    },
    DELETE_MASTER_DESIGNATION: {
        url: "/api/v1/roles/designation/{designation}",
        method: "delete",
    },
    DELETE_MASTER_DEPARTMENT: {
        url: "/api/v1/roles/department/{department}",
        method: "delete",
    },

    // BANK-NAMES
    ADD_BANK_NAME: {
        url: "/api/v1/bankNames",
        method: "post",
    },
    GET_BANK_NAMES: {
        url: "/api/v1/bankNames",
        method: "get",
    },
    GET_BANK_BY_ID: {
        url: "/api/v1/bankNames/{bankId}",
        method: "get",
    },
    UPDATE_BANK_NAME: {
        url: "/api/v1/bankNames/{bankId}",
        method: "put",
    },
    DELETE_BANK_NAME: {
        url: "/api/v1/bankNames/{bankId}",
        method: "delete",
    },

    // INTEREST RATE
    ADD_INTEREST_RATES: {
        url: "/api/v1/interestRates",
        method: "post",
    },
    GET_INTEREST_RATES: {
        url: "/api/v1/interestRates",
        method: "get",
    },
    GET_INTEREST_RATE_BY_ID: {
        url: "/api/v1/interestRates/{interestRateId}",
        method: "get",
    },
    UPDATE_INTEREST_RATE: {
        url: "/api/v1/interestRates/{interestRateId}",
        method: "put",
    },
    DELETE_INTEREST_RATE: {
        url: "/api/v1/interestRates/{interestRateId}",
        method: "delete",
    },

    // PRODUCT TYPES
    ADD_PRODUCT_TYPE: {
        url: "/api/v1/product-type",
        method: "post",
    },
    GET_PRODUCT_TYPES: {
        url: "/api/v1/product-type",
        method: "get",
    },
    DELETE_PRODUCT_TYPE: {
        url: "/api/v1/product-type/{productType}",
        method: "delete",
    },

    // PINCODE
    GET_INDIVIDUAL_BY_PINCODE: {
        url: "/api/v1/master-pincode/{pincode}",
        method: "get",
    },

    // MASTER COMPANY
    ADD_MASTER_COMPANY: {
        url: "/api/v1/master-company",
        method: "post",
    },
    GET_ALL_MASTER_COMPANIES: {
        url: "/api/v1/master-company",
        method: "get",
    },
}