File.prototype.dataurl = function () {
    var file = this;
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            }
            reader.readAsDataURL(file);
        } catch (error) {
            reject(false)
        }
    })
}