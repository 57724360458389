import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import { Dropdown } from "react-bootstrap";

const PortfolioInfo = (props) => {
  var cardname = props.urlparams.params.cardname;
  var timeline = props.urlparams.params.timeline;
  var tablename = props.urlparams.params.tablename;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);

  const columns = [
    {
      dataIndex: "borrowerName",
      title: "Customer Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return <h6 className="text-primary">{text}</h6>;
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "netDisbursementAmt",
      title: "Disbursement Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanPF",
      title: "Processing Fees",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanTotalAmtPayable",
      title: "Total Payable",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "totalRepaymentAmtTillDate",
      title: "Repay Amt",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "totalOutstandingAmtTillDate",
      title: "Outstanding Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Principle amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanTenure",
      title: "Tenure",
      render: (text, record) => {
        return <span>{`${text} Days` || "-"}</span>;
      },
    },
    {
      dataIndex: "loanInterestAmtTillDate",
      title: "Loan Int. Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
  ];
  const collectionsColumns = [
    {
      dataIndex: "borrowerName",
      title: "Customer Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return (
          <h6 className="text-primary">
            {record?.loanAccountDetails?.borrowerName || "-"}
          </h6>
        );
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return (
          <span>
            {record?.loanAccountDetails?.sanctionedLoanAmount?.cur() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "outstandingAmt",
      title: "Outstanding Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "repaymentId",
      title: "Repayment ID",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "paymentAmt",
      title: "Payment Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "paymentDate",
      title: "Payment Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "dueDate",
      title: "Due Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
  ];
  const customerColumn = [
    {
      dataIndex: "customerName",
      title: "Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return (
          <div className="products">
            <div>
              <h6 className="text-primary">{record.customerName}</h6>
              <span>{record.customerId}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "emailAddress",
      title: "Email ID",
    },
    {
      dataIndex: "createdAt",
      title: "Registration Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let mappedStatus = _enum.STATUS_COLOR_MAP[record.status] || "";
        return (
          <Dropdown
            className={Object.className({
              "status-dropdown": true,
              "no-arrow pe-none": true,
            })}
          >
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const leadsColumn = [
    {
      dataIndex: "leadName",
      title: "Name",
      render: (text, record) => {
        return (
          <>
            <span className="text-primary">{text}</span>
            {record?.isVerified && (
              <i className="fa fa-circle-check text-red ms-2"></i>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "location",
      title: "Location",
    },
    // {
    //   dataIndex: "productType",
    //   title: "Product Type",
    //   render: (text, record) => {
    //     return <>{text || "-"}</>;
    //   },
    // },
    {
      dataIndex: "assignedBy",
      title: "Assigned By",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.employeeName;
        return emp;
      },
    },
    {
      dataIndex: "assignedBy",
      title: "Tele Mobile No.",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.mobileNumber;
        return emp;
      },
    },
    {
      dataIndex: "source",
      title: "Source",
      render: (text, record) => {
        return <>{text?.toTitleCase() || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Created Date",
      render: (text, record) => {
        if (record?.createdAt) {
          let date = new Date(record?.createdAt).dateToDDMMYYYY(
            new Date(record?.createdAt)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: "Lead Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "followUpAt",
      title: "Follow Up At",
      render: (text, record) => {
        if (record?.followUpAt) {
          let date = new Date(record?.followUpAt).dateToDDMMYYYY(
            new Date(record?.followUpAt)
          );
          let time = new Date(record?.followUpAt).toLocaleTimeString();
          return (
            <>
              {date} {time}
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "assignedEmployee",
      title: "Assigned To",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.name;
        return emp || "-";
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ];
  const pendingapplicationsColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    // {
    //   dataIndex: "mobileNumber",
    //   title: "Mobile No.",
    //   render: (text, record) => {
    //     return record?.customer?.mobileNumber;
    //   },
    // },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const rejectedapplicationsColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "rejectedReason",
      title: "Remarks",
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const inprogressColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Applied Tenure",
      render: (text) => {
        return <>{text} Days</>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const sanctionedColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmt",
      title: "Sanctioned Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "sanctionedIRR",
      title: "ROI",
      render: (text) => {
        return <>{`${text} %`}</>;
      },
    },
    {
      dataIndex: "applicationLoanAccount",
      title: "Sanctioned Loan Tenure",
      render: (text, record) => {
        let tenure = record?.applicationLoanAccount?.sanctionedLoanTenure;
        return <>{tenure ? `${tenure} Days` : "-"}</>;
      },
    },
    {
      dataIndex: "applicationLoanAccount",
      title: "KFS",
      render: (text, record) => {
        return record.status === "ESIGNED" ? (
          <div className="docs_card">
            <a
              href={text?.signzyLoanAgreementLink}
              target="_blank"
              className="text-primary text-underline"
            >
              Download
            </a>
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  const onPageChange = (pageData) => {
    let payload = {};
    let pagechangedata = {
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    };
    if (
      [
        "totalUsers",
        "pending",
        "completed",
        "applied",
        "totalleads",
        "digital",
        "offline",
        "enquiry",
      ].includes(cardname)
    ) {
      payload = {
        ...pagechangedata,
        startDate: props.urlparams.params.start_date,
        endDate: props.urlparams.params.end_date,
      };
    } else if (
      props.urlparams.params.start_date &&
      props.urlparams.params.end_date
    ) {
      payload = {
        ...pagechangedata,
        start_date: props.urlparams.params.start_date,
        end_date: props.urlparams.params.end_date,
      };
    } else {
      payload = {
        ...pagechangedata,
      };
    }
    console.log({ onPageChange: pageData, payload });
    getData(payload);
  };

  const downloadFile = async () => {
    let payload = {};
    let data = {};

    let startDateValue = props.urlparams.params.start_date;
    let endDateValue = props.urlparams.params.end_date;

    if (startDateValue !== "null") data.start_date = startDateValue;
    if (endDateValue !== "null") data.end_date = endDateValue;

    payload = {
      timeline: timeline || "TODAY",
    };

    const resolve = async (res, filename) => {
      let data = await res;
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${filename}.xlsx`);
      link.click();
    };

    // EXPORT REVENUE INFO
    if (cardname === "totalCollection") {
      props.apis
        .apiExportRevenueTotalCollections(data, payload)
        .then(async (res) => {
          if (res) resolve(res, "total_collections");
        });
    }
    if (cardname === "processingFees") {
      props.apis.apiExportRevenueProcessing(data, payload).then(async (res) => {
        if (res) resolve(res, "processing_fees");
      });
    }
    if (cardname === "principle") {
      props.apis.apiExportRevenuePrinciple(data, payload).then(async (res) => {
        if (res) resolve(res, "principle");
      });
    }
    if (cardname === "interest") {
      props.apis.apiExportRevenueInterest(data, payload).then(async (res) => {
        if (res) resolve(res, "interest");
      });
    }

    // EXPORT ACTIVITIES
    if (cardname === "overdueYesterday") {
      props.apis.apiExportActivitiesOverdueYesterday().then(async (res) => {
        if (res) resolve(res, "overdue_yesterday");
      });
    }
    if (cardname === "collectionsYesterday") {
      props.apis.apiExportActivitiesCollectionsYesterday().then(async (res) => {
        if (res) resolve(res, "collections_yesterday");
      });
    }
    if (cardname === "overdueToday") {
      props.apis.apiExportActivitiesOverdueToday().then(async (res) => {
        if (res) resolve(res, "overdue_today");
      });
    }
    if (cardname === "collectionsToday") {
      props.apis.apiExportActivitiesCollectionsToday().then(async (res) => {
        if (res) resolve(res, "collections_today");
      });
    }

    // EXPORT OVERDUE PORTFOLIO
    if (Object.keys(_enum.OVERDUE_PORTFOLIO_RANGE_MAP).includes(cardname)) {
      props.apis
        .apiExportOverduePortfolio({
          overdueRange: _enum.OVERDUE_PORTFOLIO_RANGE_MAP[cardname],
        })
        .then(async (res) => {
          if (res) resolve(res, `overdue_portfolio_${cardname}`);
        });
    }

    // User Registration
    if (
      [..._enum.CUSTOMER_FILTER_STATUS_KEY_MAP, "totalUsers"].includes(cardname)
    ) {
      props.apis
        .apiExportTotalRegisteredUsers(
          {
            ...data,
            ...(cardname !== "totalUsers"
              ? { filterStatus: _enum.CUSTOMER_FILTER_STATUS_MAP[cardname] }
              : {}),
          },
          payload
        )
        .then(async (res) => {
          if (res) resolve(res, cardname);
        });
    }

    // Lead Info
    if ([..._enum.SOURCES_KEY_MAP, "totalleads"].includes(cardname)) {
      props.apis
        .apiExportDashboardLeadsInfo(
          {
            ...data,
            ...(cardname !== "totalleads"
              ? { filterStatus: _enum.SOURCES_MAP[cardname] }
              : {}),
          },
          payload
        )
        .then(async (res) => {
          if (res) resolve(res, cardname);
        });
    }

    // Application Status
    if ([..._enum.DASHBOARD_APPLICATION_STATUS_KEY_MAP].includes(tablename)) {
      props.apis
        .apiExportApplicationsByStatus({
          ...(tablename === "totalApplication"
            ? {}
            : {
                filterStatus: _enum.DASHBOARD_APPLICATION_STATUS_MAP[tablename],
              }),
        })
        .then(async (res) => {
          if (res) resolve(res, tablename);
        });
    }
  };

  // REVENUE INFO LIST
  const getRevenueTotalCollectionsList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis
      .apiGetRevenueTotalCollectionsList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };
  const getRevenueProcessingFeesList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis
      .apiGetRevenueProcessingFeesList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };
  const getRevenuePrincipleList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis
      .apiGetRevenuePrincipleList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };
  const getRevenueInterestList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis
      .apiGetRevenueInterestList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  // ACTIVITIES LIST
  const getActivitiesOverdueYesterdayList = (query) => {
    let payload = { ...search, ...query };
    props.apis
      .apiGetActivitiesOverdueYesterdayList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getActivitiesCollectionsYesterdayList = (query) => {
    let payload = { ...search, ...query };
    props.apis
      .apiGetActivitiesCollectionsYesterdayList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getActivitiesOverdueTodayList = (query) => {
    let payload = { ...search, ...query };
    props.apis
      .apiGetActivitiesOverdueTodayList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getActivitiesCollectionsTodayList = (query) => {
    let payload = { ...search, ...query };
    props.apis
      .apiGetActivitiesCollectionsTodayList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  // OVERDUE PORTFOLIO LIST
  const GetDashboardAdminOverduePortfolioList = () => {
    let payload = {
      overdueRange: _enum.OVERDUE_PORTFOLIO_RANGE_MAP[cardname],
    };
    props.apis
      .apiGetDashboardAdminOverduePortfolioList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  // REGISTERED CUSTOMERS LIST
  const getAllRegisteredUsersDashboardList = (search) => {
    let payload = {
      ...search,
      timeline: timeline,
      filterStatus: _enum.CUSTOMER_FILTER_STATUS_MAP[cardname],
    };
    props.apis
      .apiGetAllRegisteredUsersDashboardList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  // REGISTERED CUSTOMERS LIST
  const getDashboardAdminLeadsInfoList = (query) => {
    let payload = {
      ...query,
      timeline: timeline,
      filterStatus: _enum.DASHBOARD_LEADS_FILTER_STATUS_MAP[cardname],
    };
    props.apis
      .apiGetDashboardAdminLeadsInfoList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  // APPLICATION STATUS LIST
  const getTotalApplicationStatusList = (query) => {
    let payload = {
      ...query,
      filterStatus: _enum.DASHBOARD_APPLICATION_STATUS_MAP[tablename],
    };
    props.apis
      .apiGetTotalApplicationStatusList(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getData = (searchPayload) => {
    if (tablename === "customer") {
      setTableColumns(customerColumn);
    } else if (tablename === "collections") {
      setTableColumns(collectionsColumns);
    } else if (tablename === "leads") {
      setTableColumns(leadsColumn);
    } else if (
      tablename === "pendingapplications" ||
      tablename === "totalApplication" ||
      tablename === "underwriting" ||
      tablename === "agreement" ||
      tablename === "disbursed"
    ) {
      setTableColumns(pendingapplicationsColumns);
    } else if (tablename === "sanctioned") {
      setTableColumns(sanctionedColumns);
    } else if (tablename === "rejected") {
      setTableColumns(rejectedapplicationsColumns);
    } else {
      setTableColumns(columns);
    }

    if (cardname === "totalCollection") {
      getRevenueTotalCollectionsList(searchPayload);
    }
    if (cardname === "processingFees") {
      getRevenueProcessingFeesList(searchPayload);
    }
    if (cardname === "principle") getRevenuePrincipleList(searchPayload);
    if (cardname === "interest") getRevenueInterestList(searchPayload);

    if (cardname === "overdueYesterday") {
      getActivitiesOverdueYesterdayList(searchPayload);
    }
    if (cardname === "collectionsYesterday") {
      getActivitiesCollectionsYesterdayList(searchPayload);
    }
    if (cardname === "overdueToday") {
      getActivitiesOverdueTodayList(searchPayload);
    }
    if (cardname === "collectionsToday") {
      getActivitiesCollectionsTodayList(searchPayload);
    }

    if (Object.keys(_enum.OVERDUE_PORTFOLIO_RANGE_MAP).includes(cardname)) {
      GetDashboardAdminOverduePortfolioList();
    }
    if (tablename === "customer") {
      getAllRegisteredUsersDashboardList(searchPayload);
    }
    if (tablename === "leads") {
      getDashboardAdminLeadsInfoList(searchPayload);
    }
    if (
      Object.keys(_enum.DASHBOARD_APPLICATION_STATUS_MAP).includes(tablename)
    ) {
      getTotalApplicationStatusList(searchPayload);
    }

    // scroll page to top
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let payload = {};
    if (
      [
        "totalUsers",
        "pending",
        "completed",
        "applied",
        "totalleads",
        "digital",
        "offline",
        "enquiry",
      ].includes(cardname)
    ) {
      payload = {
        ...search,
        startDate: props.urlparams.params.start_date,
        endDate: props.urlparams.params.end_date,
      };
    } else if (
      props.urlparams.params.start_date &&
      props.urlparams.params.end_date
    ) {
      payload = {
        ...search,
        start_date: props.urlparams.params.start_date,
        end_date: props.urlparams.params.end_date,
      };
    } else {
      payload = {
        ...search,
      };
    }
    getData(payload);
  }, []);

  const getBreadCums = () => {
    let pagetitle = "";
    if (tablename === "customer") {
      pagetitle = "User Registration Data";
    } else if (tablename === "leads") {
      pagetitle = "Leads Info. Data";
    } else if (
      Object.keys(_enum.DASHBOARD_APPLICATION_STATUS_MAP).includes(tablename)
    ) {
      pagetitle = "Application Status Data";
    } else {
      pagetitle = "Portfolio Info. Data";
    }
    return pagetitle;
  };

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Dashboard"
        back={getBreadCums()}
        backLink={`/app/home`}
        pageTitle={"Dashboard"}
        parentTitle={"Home"}
        pageIcon={imagepaths.DashboardActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container"></div>
                <div className="action_btn_group text-end mb-4">
                  <button
                    type="button"
                    className="btn btn-primary ms-1 me-5"
                    onClick={(...args) => downloadFile(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={tableColumns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(PortfolioInfo);
