window.getpathkey = function (obj, bkey = window.location.pathname) {
    bkey = bkey.replace(new RegExp("^/"), '');
    if (bkey && obj) {
        let objkeys = Object.keyify(obj);
        let keys = bkey.posiblepath("/", "/").reverse();
        let retkey = keys.reduce((ckey, key) => {
            let rkey = ckey;
            let haskey = objkeys.find(k => k.startsWith(`${key}.`));
            if (haskey && !rkey) {
                rkey = Object.getNested(obj, key);
            }
            return rkey;
        }, "");
        return retkey ? retkey : obj;

    } else {
        return obj;
    }
}


window.rmerge = function (source, comparator) {
    let result = { ...source };
    for (const key in comparator) {
        if (comparator.hasOwnProperty(key)) {
            const value = comparator[key];
            if (Array.isArray(value) && result.hasOwnProperty(key) && Array.isArray(result[key])) {
                result[key] = rmerge(result[key], value);
            } else if (value !== null && value !== undefined && value !== '') {
                result[key] = value;
            }
        }
    }
    return result;
}

window.scrollToTop = function () {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
}