
export const CustomerMenuList = [
    {
        title: "Dashboard",
        iconStyle: imagepaths.Dashboard,
        iconActive: imagepaths.DashboardActive,
        to: "/app/customerhome",
        id: "customerhome",
        hasInnerPath: true,
        innerpaths: ["customerhome", "loancalculation"],
    },
    {
        title: "My Profile",
        iconStyle: imagepaths.LeadsIcon,
        iconActive: imagepaths.LeadsIconActive,
        to: "/app/myprofile",
        id: "myprofile",
    },
    {
        title: "Services",
        iconStyle: imagepaths.serviceHomeIcon,
        iconActive: imagepaths.serviceHomeIconActive,
        to: "/app/services",
        id: "services",
        hasInnerPath: true,
        innerpaths: ["services", "loancalculation"],
    },
    {
        title: "My Application",
        iconStyle: imagepaths.greyUserCheckIcon,
        iconActive: imagepaths.greyUserCheckIconActive,
        to: "/app/myapplications",
        id: ["myapplications", "myapplicationqueries", "customerpostsanctiondocs"]
    },
];
