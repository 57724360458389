import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Cibil from "../LoanApplications/Cibil/Cibil";
import FinalReview from "../LoanApplications/FinalReview/FinalReview";
import IncomeDetails from "../LoanApplications/IncomeDetails/IncomeDetails";
import PersonalInfo from "../LoanApplications/PersonalInfo/PersonalInfo";
import Queries from "../LoanApplications/Queries/Queries";

const DupeCheckDetailsView = (props) => {
  const activeStatus = props?.urlparams?.params?.status;
  const [customerName, setCustomerName] = useState("");

  const [activeKey, setActiveKey] = useState("personalinfo");
  const tabData = [
    { key: "personalinfo", name: "Personal Info." },
    { key: "incomedetails", name: "Income & BSA Info." },
    { key: "cibil", name: "CIBIL" },
    { key: "queries", name: "Queries" },
    { key: "finalreview", name: "Final Review" },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const handleGetName = (data) => {
    setCustomerName(data);
  };

  const changeTab = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveKey(key);
    scrollToTop();
  };

  const handlePostSanction = (e, data) => {
    console.log({ data });
    swal2.fire({
      title: "Post Sanction Approved Successfully",
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
  };

  return (
    <div>
      <MainPagetitle
        mainTitle="Dedup Check"
        back={customerName || ""}
        backLink={`/app/dupecheck`}
        pageTitle={"Dedup Check"}
        parentTitle={"Home"}
        pageIcon={imagepaths.DupeCheckIconActive}
      />
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1 p-4">
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Nav as="ul" className="nav-tabs border-0">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  eventKey={data.key}
                  className="text-dark bg-transparent"
                >
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {activeKey === "personalinfo" && (
              <Tab.Pane eventKey="personalinfo">
                <PersonalInfo
                  changeTab={(...args) => changeTab(...args)}
                  onGetName={handleGetName}
                />
              </Tab.Pane>
            )}
            {activeKey === "incomedetails" && (
              <Tab.Pane eventKey="incomedetails">
                <IncomeDetails changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "cibil" && (
              <Tab.Pane eventKey="cibil">
                <Cibil changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "queries" && (
              <Tab.Pane eventKey="queries">
                <Queries changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "finalreview" && (
              <Tab.Pane eventKey="finalreview">
                <FinalReview changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "postsanctioneddocs" && (
              <Tab.Pane eventKey="postsanctioneddocs">
                <PostSanctionedDocs
                  changeTab={(...args) => changeTab(...args)}
                  handlePostSanction={(...args) => handlePostSanction(...args)}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default constore(DupeCheckDetailsView);
