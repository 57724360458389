/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
Number.prototype.toFill = function(place){
    let arr =  [];
    let temp = this.toString().split('').reverse();
    if(temp.length<place) {
        arr.length = place;
        arr = arr.fill('0').map((v,i)=>temp[i]||v).reverse();
        return arr.join('');
    } else {
        return this;
    }
}

Number.prototype.file = function(prepath=window.location.origin) {
    return `${prepath}/api/storage/files/${this}`.access();
}

Number.prototype.uniqueId = function() {
    return Math.random().toString().slice(2).slice(0,this).parse();
}

Number.prototype.rand = function(max) {
    let min = Math.ceil(this);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}