import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ProductTypeModal, { BankNamesModal } from "./ProductTypeModal";

const ProductType = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Product Type",
      name: "productType",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "productType",
      title: "Product Name",
      render: (text, record) => {
        return <span className="text-primary">{text || "-"}</span>;
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getProductTypes = () => {
    let payload = {
      ...search,
    };
    // props.apis.apiGetProductTypes(payload).then((res) => {
    //   console.log({ apiGetProductTypes: res });
    //   setTableData(res);
    //   setIsFetching(false);
    //   // setRowcount(count);
    // });
    setTableData([]);
    setIsFetching(false);
  };

  const deleteProductType = (record) => {
    console.log({ deleteProductType: record });
    swal2
      .fire({
        title: "Are you sure you want to delete this product?",
        icon: "warning",
        iconColor: "#FF4D4D",
        showCancelButton: true,
        confirmButtonColor: "#FF4D4D",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // props.apis
          //   .apiDeleteProductType({ productType: record.productType })
          //   .then((res) => {
          //     console.log({ apiDeleteProductType: res });
          //     swal2.fire({
          //       title: "Product Deleted",
          //       text: "Product deleted successfully !!",
          //       icon: "success",
          //       iconColor: "#FF4D4D",
          //       timer: 4000,
          //     });
          //     getProductTypes();
          //   });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let formdata = data;
    let payload = {
      ...formdata,
    };

    console.log({ payload });

    // props.apis.apiAddProductType(payload).then((res) => {
    //   console.log({ apiAddProductType: res });
    //   swal2.fire({
    //     title: "Product Type added successfully.",
    //     icon: "success",
    //     iconColor: "#FF4D4D",
    //     timer: 4000,
    //   });
    //   closeModal();
    //   getProductTypes();
    // });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    closeModal();
    setPopupfields({});
    setPopupdata({});
  };

  useEffect(() => {
    console.log({ ProductTypes: props });
    getProductTypes();
  }, []);

  return (
    <>
      {/* <div className="page_header mb-3 text-end">
        <Link
          to={"#"}
          className="btn btn-primary btn-sm ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          + Add New
        </Link>
      </div> */}
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editable={false}
        viewable={false}
        deletable={false}
        // deleteAction={(e) => deleteProductType(e)}
        loading={isFetching}
        tableClassName="producttype_table"
      ></AppTable>
      <ProductTypeModal
        id="producttypeCanvas"
        title="Add Product Type"
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        fields={popupfields}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText="Submit"
      />
    </>
  );
};

export default constore(ProductType);
