import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobilefield, setMobilefield] = useState("");
  let errorsObj = { email: "", password: "" };
  let mobilefieldErrorObj = { mobilefield: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [otpErrors, setOtpErrors] = useState(mobilefieldErrorObj);
  const [password, setPassword] = useState("");
  const [loginToggle, setLoginToggle] = useState(true);
  const [otpToggle, setOtpToggle] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isMobileLoading, setMobileLoading] = useState(false);

  const isAdmin = () => {
    return props?.$store?.user?.role === "admin";
  };
  // useEffect(() => {
  //   function simulateNetworkRequest() {
  //     return new Promise((resolve) => setTimeout(resolve, 2000));
  //   }

  //   if (isLoading) {
  //     simulateNetworkRequest().then(() => {
  //       setLoading(false);
  //     });
  //   }
  // }, [isLoading]);

  function onLogin(e) {
    e.preventDefault();
    setLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    let data = {
      emailAddress: email,
      password: password,
    };

    // props.store("auth", true);
    // props.store("persona", _enum.ROUTE_PERSONAS.admin);
    // props.store("logged", { emailAddress: "admin@test.com" });
    // props.store("user", { role: "ADMIN", isPasswordUpdated: true });
    // navigate("/app/home");
    props.apis
      .apiLogin(data)
      .then((res) => {
        props.store("logged", res.logged);
        props.store("loggedIn", new Date().getTime());
        props.store("auth", res.auth);
        props.store("user", res.user);
        props.store("persona", res?.user?.role);

        let loginpath;
        if (
          res?.user?.role === _enum.PERSONAS.admin ||
          res?.user?.role === _enum.PERSONAS.collections
        ) {
          loginpath = "/app/home";
        } else if (
          res?.user?.role === _enum.PERSONAS.sales ||
          res?.user?.role === _enum.PERSONAS.ops
        ) {
          loginpath = "/app/leads";
        } else if (res?.user?.role === _enum.PERSONAS.underwriter) {
          loginpath = "/app/pendingapplications";
        } else {
          if (res?.user?.status === "COMPLETED") {
            loginpath = "/app/customerhome";
          } else {
            loginpath = "/app/myprofile";
          }
        }
        navigate(loginpath);
        setLoading(false);
      })
      .catch(() => {
        const errorObj = { ...errorsObj };
        errorObj.email = "login failed";
        setErrors(errorObj);
        setLoading(false);
      });
  }

  function onOTPLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...mobilefieldErrorObj };
    var mobilePattern = /^\d{10}$/;

    if (mobilefield === "") {
      errorObj.mobilefield = "Mobile Number is Required";
      error = true;
    } else if (!mobilePattern.test(mobilefield)) {
      errorObj.mobilefield = "Invalid Mobile Number";
      error = true;
    } else {
      setMobileLoading(true);
    }
    setOtpErrors(errorObj);
    if (error) {
      return;
    }
    let data = {
      mobile: mobilefield,
    };

    props.apis
      .apiOTPLogin(data)
      .then((res) => {
        console.log({ onOTPSubmit: res });
        props.store("OTPresponses", res);
        props.store("mobileNumber", mobilefield);

        navigate("/open/otp");
        setMobileLoading(false);
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
        const errorObj = { ...errorsObj };
        errorObj.mobilefield = "Mobile number login failed";
        setOtpErrors(errorObj);
        setMobileLoading(false);
      });
  }

  const handleLoginToggle = (val) => {
    if (val === "mobilefield") {
      setOtpToggle(true);
      setPasswordToggle(false);
      setLoginToggle(true);
    }
    if (val === "password") {
      setOtpToggle(false);
      setPasswordToggle(true);
      setLoginToggle(false);
    }
  };

  const toggleSwitchPassword = () => {
    const passwordField = document.querySelector("#password");
    const togglePassword = document.querySelector("#togglePassword");

    togglePassword.addEventListener("click", function () {
      if (passwordField.type === "password") {
        passwordField.type = "text";
        togglePassword.classList.remove("fa-eye");
        togglePassword.classList.add("fa-eye-slash");
      } else {
        passwordField.type = "password";
        togglePassword.classList.remove("fa-eye-slash");
        togglePassword.classList.add("fa-eye");
      }
    });
  };

  props.$setNode({
    props,
  });

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
        >
          <div className="parent-container row gx-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="logo_header pt-5 ps-5">
                <Link to={"/open/login"} className="logo">
                  <img src={imagepaths.loginWhiteBg} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
              <div className="login-form style-2 py-4">
                <div className="card-body">
                  <div className="nav nav-tabs border-bottom-0">
                    <div className="tab-content w-100" id="nav-tabContent">
                      <div
                        className="tab-pane fade active show"
                        id="nav-personal"
                      >
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        <h3 className="form-title m-t0 text-center">Login</h3>
                        <div className="dz-separator-outer m-b5">
                          <div className="dz-separator bg-primary style-liner"></div>
                        </div>
                        <p className="sub-title text-center">
                          Access to explore more
                        </p>

                        {/* <div className="login_toggle_container my-4">
                          <button
                            type="button"
                            onClick={() => handleLoginToggle("mobilefield")}
                            className={otpToggle ? "btn active" : "btn"}
                          >
                            OTP
                          </button>
                          <button
                            type="button"
                            onClick={() => handleLoginToggle("password")}
                            className={passwordToggle ? "btn active" : "btn"}
                          >
                            Password
                          </button>
                        </div> */}
                        {/* <div>
                          {!loginToggle && (
                            <form
                              className=" dz-form pb-3"
                              // onSubmit={onLogin}
                              onSubmit={!isLoading ? onLogin : null}
                            >
                              <div className="form-group mb-3">
                                <label>Email or Mobile no.</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                  <div className="text-danger fs-12">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="password-container form-group mb-3">
                                <label>Password</label>
                                <input
                                  type="password"
                                  id="password"
                                  className="form-control"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <i
                                  class="far fa-eye"
                                  id="togglePassword"
                                  onMouseEnter={() => toggleSwitchPassword()}
                                ></i>
                                {errors.password && (
                                  <div className="text-danger fs-12">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mb-5 d-flex align-items-start justify-content-between">
                                <span className="form-check d-inline-block ms-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="check1"
                                    name="example1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="check1"
                                  >
                                    Remember me
                                  </label>
                                </span>
                                <span>
                                  <NavLink
                                    to="/open/forgetpassword"
                                    className="ms-auto text-primary fw-bold"
                                  >
                                    Forgot Password ?
                                  </NavLink>
                                </span>
                              </div>
                              <div
                                className={Object.className({
                                  "form-group text-left": true,
                                  "not-allowed": isLoading,
                                })}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary button-md btn-block"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Loading…" : "login"}
                                </button>
                              </div>
                            </form>
                          )}
                        </div>
                        <div>
                          {loginToggle && (
                            <form
                              className=" dz-form pb-3"
                              onSubmit={onOTPLogin}
                            >
                              <div className="form-group mb-3">
                                <label>Email or Mobile no.</label>
                                <input
                                  value={mobilefield}
                                  type="number"
                                  className="form-control"
                                  onChange={(e) =>
                                    setMobilefield(e.target.value)
                                  }
                                />
                                {otpErrors.mobilefield && (
                                  <div className="text-danger fs-12">
                                    {otpErrors.mobilefield}
                                  </div>
                                )}
                              </div>
                              <div
                                className={Object.className({
                                  "form-group text-left": true,
                                  "not-allowed": isMobileLoading,
                                })}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary button-md btn-block"
                                  disabled={isMobileLoading}
                                >
                                  {isMobileLoading ? "Loading…" : "Send OTP"}
                                </button>
                              </div>
                            </form>
                          )}
                        </div> */}
                        <form
                          className=" dz-form pb-3"
                          // onSubmit={onLogin}
                          onSubmit={!isLoading ? onLogin : null}
                        >
                          <div className="form-group mb-3">
                            <label>Email or Mobile no.</label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="password-container form-group mb-3">
                            <label>Password</label>
                            <input
                              type="password"
                              id="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                              class="far fa-eye"
                              id="togglePassword"
                              onMouseEnter={() => toggleSwitchPassword()}
                            ></i>
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-5 d-flex align-items-start justify-content-between">
                            <span className="form-check d-inline-block ms-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="check1"
                                name="example1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="check1"
                              >
                                Remember me
                              </label>
                            </span>
                            <span>
                              <NavLink
                                to="/open/forgetpassword"
                                className="ms-auto text-primary fw-bold"
                              >
                                Forgot Password ?
                              </NavLink>
                            </span>
                          </div>
                          <div
                            className={Object.className({
                              "form-group text-left": true,
                              "not-allowed": isLoading,
                            })}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary button-md btn-block"
                              disabled={isLoading}
                            >
                              {isLoading ? "Loading…" : "login"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default constore(Login);
