import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import LogoutPage from "./Logout";

import { IMAGES, SVGICON } from "../../constant/theme";
import Logoutbtn from "./Logoutbtn";

const NotificationBlog = ({ classChange }) => {
  return (
    <>
      <li>
        <div className="timeline-panel">
          <div className="media me-2">
            <img alt="images" width={50} src={IMAGES.Avatar} />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Dr sultads Send you Photo</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>KG</div>
          <div className="media-body">
            <h6 className="mb-1">Resport created successfully</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>
            <i className="fa fa-home" />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Reminder : Treatment Time!</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
    </>
  );
};

const Header = (props) => {
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  props.$setNode({
    props,
  });

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="input-group search-area">
                <span className="input-group-text rounded-0">
                  <Link to={"#"}>
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8.78605"
                        cy="8.78605"
                        r="8.23951"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5168 14.9447L17.7471 18.1667"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </span>
                <input
                  type="text"
                  className="form-control rounded-0"
                  placeholder="Search"
                />
              </div>
            </div>
            <ul className="navbar-nav header-right">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown"
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer"
                  variant=""
                  as="a"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="end"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <div className="widget-media dz-scroll p-3 height380">
                    <ul className="timeline">
                      <NotificationBlog classChange="media-info" />
                      <NotificationBlog classChange="media-success" />
                      <NotificationBlog classChange="media-danger" />
                      <NotificationBlog classChange="media-info" />
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </div>
                  <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              <li className="nav-item align-items-center header-border">
                <Logoutbtn />
              </li>
              <li className="nav-item ps-3">
                <Dropdown
                  className="header-profile2"
                  style={{ cursor: "unset" }}
                >
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media">
                        <img src={IMAGES.Avatar} alt="" />
                      </div>
                      <div className="header-info">
                        {props?.$store?.persona ===
                        _enum.ROUTE_PERSONAS.customer ? (
                          <>
                            <h6>
                              {props?.$store?.user?.customerName || "Customer"}
                            </h6>
                            <p>
                              {props?.$store?.persona?.toTitleCase() || "-"}
                            </p>
                          </>
                        ) : (
                          <>
                            <h6>
                              {props?.$store?.user?.name ||
                                props?.$store?.logged?.mobile ||
                                "Employee"}
                            </h6>
                            <p>{props?.$store?.user?.role || "-"}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu align="end">
                    <div className="card border-0 mb-0">
                      <div className="card-header py-2">
                        <div className="products">
                          <img
                            src={IMAGES.Avatar}
                            className="avatar avatar-md"
                            alt=""
                          />
                          <div>
                            {props?.$store?.persona ===
                            _enum.ROUTE_PERSONAS.customer ? (
                              <>
                                <h6>
                                  {props?.$store?.user?.customerName || "-"}
                                </h6>
                                <span>Customer</span>
                              </>
                            ) : (
                              <>
                                <h6>
                                  {props?.$store?.user?.supervisor
                                    ?.employeeName ||
                                    props?.$store?.logged?.mobile ||
                                    "-"}
                                </h6>
                                <span>
                                  {props?.$store?.user?.supervisor
                                    ?.designation || "-"}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-body px-0 py-2">
                        <Link to={"#"} className="dropdown-item ai-icon ">
                          {SVGICON.User} <span className="ms-2">Profile </span>
                        </Link>
                        <Link to={"#"} className="dropdown-item ai-icon ">
                          {SVGICON.Project}{" "}
                          <span className="ms-2">My Project</span>
                          <span className="badge badge-sm badge-secondary light rounded-circle text-white ms-2">
                            4
                          </span>
                        </Link>
                        <Link to={"#"} className="dropdown-item ai-icon ">
                          {SVGICON.Message}{" "}
                          <span className="ms-2">Message </span>
                        </Link>
                        <Link to={"#"} className="dropdown-item ai-icon ">
                          {SVGICON.Notification}{" "}
                          <span className="ms-2">Notification </span>
                        </Link>
                      </div>
                      <div className="card-footer px-0 py-2">
                        <Link to={"#"} className="dropdown-item ai-icon ">
                          {SVGICON.Headersetting}{" "}
                          <span className="ms-2">Settings </span>
                        </Link>
                        <LogoutPage />
                      </div>
                    </div>
                  </Dropdown.Menu> */}
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default constore(Header);
