import React, { Component, forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const AppReactDatePicker = (props) => {
  let {
    type,
    name,
    disablePastDates = false,
    disableFutureDates = false,
  } = props;
  const [startDate, setStartDate] = useState(new Date());

  const handleSetStartDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className="AppReactDatePicker">
      <DatePicker
        showIcon
        icon="fa-regular fa-calendar"
        selected={startDate}
        onChange={(date) => handleSetStartDateChange(date)}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onFocus={(e) => {
          e.preventDefault();
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        name={name}
        dateFormat="yyyy/MM/dd"
        className="form-control"
        {...(disablePastDates ? { minDate: new Date().toDate() } : {})}
        {...(disableFutureDates ? { maxDate: new Date().toDate() } : {})}
      />
    </div>
  );
};

export default AppReactDatePicker;
