export default (props) => (
  <AppInput
    labelClass={"focus-label"}
    controlClass="floating"
    {...props}
    labelpos="none"
    className={"form-focus " + (props?.className || "")}
    placeholder={props?.placeholder || ""}
  ></AppInput>
);
