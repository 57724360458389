const files = require.context('./', true, /\.jsx$/i);
const file_keys = files.keys()
.filter(key=>key!=="./index.js")
.filter(key=>{
    let name = key.filename();
    let indexFile = `${name}/index.jsx`;
    let nameFile = `${name}/${name}.jsx`;
    return (key.endsWith(indexFile)||key.endsWith(nameFile));
});
const pages = {
    ...file_keys.map(key=>{
        let name = key.filename();
        let index = key.lastIndexOf(name);
        let startpath = key.substring(1,index-1);
        return ({
            path:startpath,
            Element:files(key).default,
            startpath
        })
    })
}

console.log({pages});
export default pages;
