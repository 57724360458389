export default {

    apiGetAllDupeCheckData() {
        let data = [
            {
                applicationId: 123456,
                applicationLoanAccount: {
                    borrowerName: 'Suresh Vadla',
                    sanctionedLoanAmount: 25000,
                    sanctionedLoanIRR: 10,
                    sanctionedLoanTenure: 30
                },
                status: 'REJECTED',
            },
            {
                applicationId: 123456,
                applicationLoanAccount: {
                    borrowerName: 'Suresh Vadla',
                    sanctionedLoanAmount: 5000,
                    sanctionedLoanIRR: 10,
                    sanctionedLoanTenure: 40
                },
                status: 'CLOSED',
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
}