export default {
  GENDER: [
    { key: "MALE", label: "Male" },
    { key: "FEMALE", label: "Female" },
    { key: "OTHERS", label: "Others" },
  ],
  GENDER_SHORT: [
    { key: "M", label: "Male" },
    { key: "F", label: "Female" },
  ],
  PERSONAS: {
    admin: "ADMIN",
    sales: "SALES",
    sales_lead: "SALES_LEAD",
    ops: "OPERATIONS",
    underwriter: "UNDERWRITER",
    senior_underwriter: "SENIOR_UNDERWRITER",
    collections: "COLLECTIONS",
    customer: "CUSTOMER",
  },
  ROUTE_PERSONAS: {
    admin: "ADMIN",
    sales: "SALES",
    sales_lead: "SALES_LEAD",
    ops: "OPERATIONS",
    underwriter: "UNDERWRITER",
    senior_underwriter: "SENIOR_UNDERWRITER",
    collections: "COLLECTIONS",
    customer: "CUSTOMER",
  },
  STATUS: [
    { key: "active", label: "Active" },
    { key: "inactive", label: "In Active" },
  ],
  ACCOUNTS_STATUS: [
    { key: "ACTIVE", label: "Active" },
    { key: "OVERDUE", label: "Overdue" },
  ],
  STATUS_COLOR_MAP: {
    interested: "success",
    submitted: "success",
    applied: "success",
    open: "success",
    disbursed: "success",
    PROFILE_CREATED: "success",
    COMPLETED: "success",
    success: "success",
    sanctioned: "success",
    post_sanctioned: "success",
    customer_declined: "success",
    under_writing: "success",
    query: "success",
    approved: "success",
    loan_agreement: "success",
    agreement: "success",
    esigned: "success",
    active: "success",
    closed: "success",
    //
    not_reachable: "warning",
    in_progress: "warning",
    logged_in: "warning",
    pending: "warning",
    pre_disbursement: "warning",
    follow_up: "warning",
    call_not_answered: "warning",
    //
    not_interested: "error",
    call_back: "error",
    rejected: "error",
    failed: "error",
    overdue: "error",
    //
    INCOME_DETAILS_UPDATED: "processing",
    KYC_DETAILS_UPDATED: "processing",
  },
  INTEREST_TYPES: [
    { key: "Fixed", label: "Fixed" },
    { key: "Floating", label: "Floating" },
  ],
  ADMIN_LEADS_STATUS: [
    { key: "OPEN", label: "Open" },
    { key: "INTERESTED", label: "Interested" },
    { key: "NOT_INTERESTED", label: "Not Interested" },
    { key: "NOT_REACHABLE", label: "Not Reachable" },
    { key: "CALL_NOT_ANSWERED", label: "Call Not Answered" },
    // { key: 'APPLIED', label: 'Applied'},
    // { key: 'NOT_ELIGIBLE', label: 'Not Eligible'},
    // { key: 'PENDING', label: 'Pending'},
  ],
  ADMIN_LEADS_STATUS_WITH_FOLLOW_UP: [
    { key: "OPEN", label: "Open" },
    { key: "INTERESTED", label: "Interested" },
    { key: "NOT_INTERESTED", label: "Not Interested" },
    { key: "NOT_REACHABLE", label: "Not Reachable" },
    { key: "CALL_NOT_ANSWERED", label: "Call Not Answered" },
    { key: "FOLLOW_UP", label: "Follow Up" },
  ],
  OPS_LEADS_STATUS: [
    { key: "open", label: "Open" },
    { key: "not_interested", label: "Not Interested" },
    { key: "not_eligible", label: "Not Eligible" },
    { key: "pending", label: "Pending" },
  ],
  APPLICATION_STATUS: [
    { key: "disbursed", label: "Disbursed" },
    { key: "rejected", label: "Rejected" },
    { key: "logged_in", label: "Logged In" },
  ],
  SOURCES: [
    { key: "SELF", label: "Self" },
    { key: "ENQUIRY", label: "Enquiry" },
    { key: "EMPLOYEE", label: "Employee" },
    { key: "BULK", label: "Bulk" },
    { key: "CUSTOMER", label: "Customer" },
    { key: "SOCIAL", label: "Social" },
    { key: "DSA", label: "DSA" },
  ],
  SOURCES_MAP: {
    offline: "OFFLINE",
    enquiry: "ENQUIRY",
    digital: "DIGITAL",
  },
  SOURCES_KEY_MAP: [
    "offline",
    "enquiry",
    "digital",
  ],
  REGEX: {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    number: /^[0-9]+$/,
    onlystring: /^[A-Za-z\s]*$/,
    pan: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    aadhar: /^\d{12}$/,
    degrees: /^[0-9-+.]*$/,
    float: /^[0-9.]*$/,
    ifsc: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
  },
  // LEAD_STATUS_OPTIONS
  PRODUCTS: {
    personal_loan: "PERSONAL LOAN",
    home_loan: "HOME LOAN",
    mortage_loan: "MORTGAGE LOAN",
    education_loan: "EDUCATION LOAN",
    vehicle_loan: "VEHICLE LOAN",
    lease_rental_discounting: "LEASE RENTAL DISCOUNTING",
    property_purchase_loan: "PROPERTY PURCHASE LOAN",
    commercial_vehicles_loan: "COMMERCIAL VEHICLES LOAN",
    //
    term_loan: "TERM LOAN",
    working_capital: "WORKING CAPITAL LOAN",
    medical_equipment_loan: "MEDICAL EQUIPMENT LOAN",
    od_and_cc_limits: "OVERDRAFT LOAN",
    equipment_machinery_loans: "MACHINERY LOAN",
    non_fun_base_limits: "NON FUND BASED FINANCE",
    unsecured_business_loan: "UNSECURED BUSINESS LOAN",
    gst_business_loan: "GST BUSINESS LOAN",
    //
    project_funding: "PROJECT FUNDING",
    real_estate_loan: "REAL ESTATE LOAN",
    acquisition_finance: "ACQUISITION FINANCE",
    promoter_funding: "PROMOTER FUNDING",
    export_finance: "EXPORT FINANCE",
    bill_discounting: "BILL DISCOUNTING",
    dealer_financing: "DEALER FINANCE",
  },
  //
  MOKE_COMPANY_NAMES: [
    { key: "companyA", label: "Company A" },
    { key: "companyB", label: "Company B" },
  ],
  CUSTOMER_PRODUCT_TYPES: {
    short_term_loan: "Dhanvriddhi Short Term Loan",
    insta_loan: "Dhanvriddhi Insta Loan",
  },
  RECHARGE_AMOUNTS: [
    { key: 1000, label: "₹ 1,000" },
    { key: 500, label: "₹ 500" },
    { key: 100, label: "₹ 100" },
    { key: 'others', label: "Others" },
  ],
  MARITAL_STATUS: [
    { key: "SINGLE", label: "Single" },
    { key: "MARRIED", label: "Married" },
  ],
  RELIGIONS: [
    { key: "HINDU", label: "Hindu" },
    { key: "MUSLIM", label: "Muslim" },
    { key: "CHRISTIAN", label: "Christian" },
    { key: "OTHERS", label: "Others" },
  ],
  RELATIONS_1: [
    { key: "MOTHER", label: "Mother" },
    { key: "FATHER", label: "Father" },
    { key: "DAUGHTER", label: "Daughter" },
    { key: "SON", label: "Son" },
    { key: "BROTHER", label: "Brother" },
    { key: "OTHER", label: "Others" },
  ],


  RELATIONS_2: [
    { key: "FRIEND", label: "Friend" },
    { key: "COLLEAGUE", label: "Colleague" },
    { key: "OFFICE_REF", label: "Office Reference" },
    { key: "OTHER", label: "Others" },
  ],
  MODE_OF_SALARY: [
    { key: "ONLINE", label: "Online" },
    { key: "OFFLINE", label: "Offline" },
  ],
  CUSTOMER_PROFILE_STATUS: {
    PROFILE_CREATED: "PROFILE_CREATED",
    KYC_DETAILS_UPDATED: "KYC_DETAILS_UPDATED",
    INCOME_DETAILS_UPDATED: "INCOME_DETAILS_UPDATED",
    APPLIED: "APPLIED",
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
  },
  CUSTOMER_PROFILE_STATUS_KEYS: [
    { key: "PROFILE_CREATED", label: "Profile Created" },
    { key: "KYC_DETAILS_UPDATED", label: "KYC Details Updated" },
    { key: "INCOME_DETAILS_UPDATED", label: "Income Details Updated" },
    { key: "COMPLETED", label: "Completed" },
  ],
  RESIDENCE_TYPES: [
    { key: "OWNED", label: "Owned" },
    { key: "RENTED", label: "Rented" },
    { key: "PARENTAL_OWNED", label: "Parental Owned" },
  ],
  ADDRESS_TYPES: [
    { key: "COMMUNICATION", label: "Communication" },
    { key: "PERMANENT", label: "Permanent" },
  ],
  DOC_TYPES: [
    { value: "VOTER_ID", label: "Voter ID" },
    // { value: "AADHAR_CARD", label: "Aadhar Card" },
    { value: "GAS_BILL", label: "Gas Bill" },
    { value: "ELECTRICITY_BILL", label: "Electricity Bill" },
    { value: "RESIDENTIAL_PROOF", label: "Residential Proof" },
  ],
  DOC_TYPES_MAP: {
    VOTER_ID: "Voter ID",
    AADHAR_CARD: "Aadhar Card",
    GAS_BILL: "Gas Bill",
    ELECTRICITY_BILL: "Electricity Bill",
    RESIDENTIAL_PROOF: "Residential Proof",
    OTHERS: "Others",
  },

  UNDER_WRITER_DECISIONS: [
    { key: "DISCREPANCY", label: "Discrepancy" },
    { key: "APPROVED", label: "Approved" },
    { key: "DECLINE", label: "Decline" },
  ],

  UNDER_WRITER_DECISION_DISCREPANCY: [
    { key: "DISCREPANCY", label: "Discrepancy" },
  ],

  UNDER_WRITER_DECISIONS_FOR_QUERIES: [
    { key: "APPROVED", label: "Approved" },
    { key: "DECLINE", label: "Decline" },
  ],
  UNDER_WRITER_DECISION_MAP: {
    approved: "APPROVED",
    declined: "DECLINE",
  },
  YES_OR_NO: [
    { key: "yes", label: "Yes" },
    { key: "no", label: "No" },
  ],
  MOKE_LOAN_TYPES: [
    { key: "Personal Loan", label: "Personal Loan" },
    { key: "Auto Loan", label: "Auto Loan" },
    { key: "Home Loan", label: "Home Loan" },
    { key: "Two-wheelar Loan", label: "Two-wheelar Loan" },
    { key: "Others", label: "Others" },
  ],
  CATEGORY_MAP: {
    CIBIL: "Cibil",
    INCOME_DTLS: "Income & BSA Info.",
    PERSONAL_INFO: "Personal Info.",
  },
  YES_OR_NO_BOOLEAN: [
    { key: true, label: "Yes" },
    { key: false, label: "No" },
  ],
  PRESENCE_YEAR_KEY: [
    { key: "1", label: "1 Year" },
    { key: "2", label: "2 Years" },
    { key: "3", label: "3 Years" },
    { key: "4", label: "4 Years" },
    { key: "5", label: ">5 Years" },
  ],
  PRESENCE_YEAR_VALUE: [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
    { value: "3", label: "3 Years" },
    { value: "4", label: "4 Years" },
    { value: "5", label: ">5 Years" },
  ],
  REPAYMENT_TYPE_KEY: [
    { key: "BULLET", label: "Bullet" },
    { key: "EMI", label: "EMI" },
  ],
  REPAYMENT_TYPE_VALUE: [
    { value: "BULLET", label: "Bullet" },
    { value: "EMI", label: "EMI" },
  ],
  OVERALL_DETAIL_KEY: {
    today: "TODAY",
    current_month: "CURR_MONTH",
    last_month: "LAST_MONTH",
    all_time: "ALL_TIME",
    custom: "CUSTOM",
  },
  OVERALL_DETAIL: [
    { key: "TODAY", label: "Today" },
    { key: "CURR_MONTH", label: "Current Month" },
    { key: "LAST_MONTH", label: "Last Month" },
    { key: "ALL_TIME", label: "All" },
    { key: "CUSTOM", label: "Custom" },
  ],

  DOC_TYPES_NEW: [
    { key: "VOTER_ID", label: "Voter ID" },
    // { key: "AADHAR_CARD", label: "Aadhar Card" },
    { key: "GAS_BILL", label: "Gas Bill" },
    { key: "ELECTRICITY_BILL", label: "Electricity Bill" },
    { key: "RESIDENTIAL_PROOF", label: "Residential Proof" },
  ],

  DOC_TYPES_NEW_WITH_OTHERS: [
    { key: "VOTER_ID", label: "Voter ID" },
    { key: "OFFICE_ID", label: "Office ID" },
    // { key: "AADHAR_CARD", label: "Aadhar Card" },
    { key: "GAS_BILL", label: "Gas Bill" },
    { key: "ELECTRICITY_BILL", label: "Electricity Bill" },
    { key: "RESIDENTIAL_PROOF", label: "Residential Proof" },
    { key: "OTHERS", label: "Others" },
  ],
  NO_OF_MONTHS: [{ key: 1, label: "1 Month" }],

  COMPANY_CATEGORY: [
    { key: "Super CAT A", label: "Super CAT A" },
    { key: "Super CAT B", label: "Super CAT B" },
    { key: "Super CAT C", label: "Super CAT C" },
    { key: "Super CAT D", label: "Super CAT D" },
    { key: "GOVERNMENT", label: "Government" },
    { key: "ARMY", label: "Army" },
  ],
  MOKE_NEW_BANK_NAMES: [
    "Aditya Birla Bank",
    "Axis Bank",
    "Bajaj Finserv",
    "Bandhan Bank ",
    "Bank of Baroda",
    "Bank Of India",
    "Canara Bank",
    "Cholamandalam Bank",
    "Clix Capital",
    "Credit Saison",
    "DBS Bank",
    "Fibe",
    "Fi Money",
    "Finnable Bank",
    "Fullerton Bank ",
    "HDFC Bank",
    "Hero FinCorp",
    "ICICI Bank",
    "IDBI Bank",
    "IDFC Bank",
    "InCred Bank",
    "IndusInd Bank ",
    "IOB Bank",
    "Kotak Bank",
    "L&T Finance",
    "Muthoot Bank",
    "Paysense Bank",
    "Piramal Bank ",
    "Poonawalla Finserv",
    "Prefr",
    "Punjab National Bank",
    "RBL Bank",
    "SBM Bank",
    "ShriRam Finance ",
    "State Bank of India",
    "Tata capital",
    "Union Bank",
    "Upwards",
    "Yes Bank"
  ],
  BSA_BANKS: [
    {
      key: "bank_of_india",
      label: "Bank Of India"
    },
    {
      key: "bank_of_baroda",
      label: "Bank Of Baroda"
    },
    {
      key: "bank_of_maharashtra",
      label: "Bank Of Maharashtra"
    },
    {
      key: "canara_bank",
      label: "Canara Bank"
    },
    {
      key: "dbs_bank",
      label: "Dbs Bank"
    },
    {
      key: "idbi_bank",
      label: "Idbi Bank"
    },
    {
      key: "idfc_bank",
      label: "Idfc Bank"
    },
    {
      key: "indian_bank",
      label: "Indian Bank"
    },
    {
      key: "indian_overseas_bank",
      label: "Indian Overseas Bank"
    },
    {
      key: "iob",
      label: "Iob"
    },
    {
      key: "kotak",
      label: "Kotak"
    },
    {
      key: "pnb",
      label: "Pnb"
    },
    {
      key: "uco_bank",
      label: "Uco Bank"
    },
    {
      key: "union_bank_of_india",
      label: "Union Bank Of India"
    },
    {
      key: "ujjivan_bank",
      label: "Ujjivan Bank"
    },
    {
      key: "tjsb_bank",
      label: "Tjsb Bank"
    },
    {
      key: "rmgb_bank",
      label: "Rmgb Bank"
    },
    {
      key: "maharashtra_gramin_bank",
      label: "Maharashtra Gramin Bank"
    },
    {
      key: "jnk_bank",
      label: "Jnk Bank"
    },
    {
      key: "icici_bank",
      label: "Icici Bank"
    },
    {
      key: "central_bank",
      label: "Central Bank"
    },
    {
      key: "yes_bank",
      label: "Yes Bank"
    },
    {
      key: "sbi_bank",
      label: "Sbi Bank"
    },
    {
      key: "axis_bank",
      label: "Axis Bank"
    },
    {
      key: "fsf_bank",
      label: "Fsf Bank"
    },
    {
      key: "mgbm_bank",
      label: "Mgbm Bank"
    },
    {
      key: "hdfc_bank",
      label: "Hdfc Bank"
    },
    {
      key: "stanc_bank",
      label: "Stanc Bank"
    },
    {
      key: "kalyan_bank",
      label: "Kalyan Bank"
    },
    {
      key: "fedral_bank",
      label: "Fedral Bank"
    },
    {
      key: "south_india_bank",
      label: "South India Bank"
    },
    {
      key: "dns_bank",
      label: "Dns Bank"
    },
    {
      key: "tm_bank",
      label: "Tm Bank"
    },
    {
      key: "andra_bank",
      label: "Andra Bank"
    },
    {
      key: "city_union_bank",
      label: "City Union Bank"
    }
  ],

  STATIC_BANK_NAMES: [
    {
      key: "abhyudaya_cooperative_bank_limited",
      label: "ABHYUDAYA COOPERATIVE BANK LIMITED"
    },
    {
      key: "ahmedabad_mercantile_cooperative_bank",
      label: "AHMEDABAD MERCANTILE COOPERATIVE BANK"
    },
    {
      key: "ahmednagar_merchants_co_op_bank_ltd",
      label: "AHMEDNAGAR MERCHANTS CO-OP BANK LTD"
    },
    {
      key: "airtel_payments_bank_limited",
      label: "AIRTEL PAYMENTS BANK LIMITED"
    },
    {
      key: "akola_janata_commercial_cooperative_bank",
      label: "AKOLA JANATA COMMERCIAL COOPERATIVE BANK"
    },
    {
      key: "almora_urban_cooperative_bank_limited",
      label: "ALMORA URBAN COOPERATIVE BANK LIMITED"
    },
    {
      key: "ambarnath_jaihind_coop_bank_ltd_ambarnath",
      label: "AMBARNATH JAIHIND COOP BANK LTD AMBARNATH"
    },
    {
      key: "andhra_pradesh_grameena_vikas_bank",
      label: "ANDHRA PRADESH GRAMEENA VIKAS BANK"
    },
    {
      key: "andhra_pragathi_grameena_bank",
      label: "ANDHRA PRAGATHI GRAMEENA BANK"
    },
    {
      key: "apna_sahakari_bank_limited",
      label: "APNA SAHAKARI BANK LIMITED"
    },
    {
      key: "arvind_sahakari_bank_ltd",
      label: "ARVIND SAHAKARI BANK LTD"
    },
    {
      key: "au_small_finance_bank_limited",
      label: "AU SMALL FINANCE BANK LIMITED"
    },
    {
      key: "australia_and_new_zealand_banking_group_limited",
      label: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED"
    },
    {
      key: "axis_bank",
      label: "AXIS BANK"
    },
    {
      key: "bnp_paribas",
      label: "BNP PARIBAS"
    },
    {
      key: "bandhan_bank_limited",
      label: "BANDHAN BANK LIMITED"
    },
    {
      key: "bank_of_america",
      label: "BANK OF AMERICA"
    },
    {
      key: "bank_of_baharain_and_kuwait_bsc",
      label: "BANK OF BAHARAIN AND KUWAIT BSC"
    },
    {
      key: "bank_of_baroda",
      label: "BANK OF BARODA"
    },
    {
      key: "bank_of_ceylon",
      label: "BANK OF CEYLON"
    },
    {
      key: "bank_of_china_limited_india_branch",
      label: "Bank of China Limited India Branch"
    },
    {
      key: "bank_of_india",
      label: "BANK OF INDIA"
    },
    {
      key: "bank_of_maharashtra",
      label: "BANK OF MAHARASHTRA"
    },
    {
      key: "barclays_bank",
      label: "BARCLAYS BANK"
    },
    {
      key: "bassein_catholic_cooperative_bank_limited",
      label: "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED"
    },
    {
      key: "bhagini_nivedita_sahakari_bank_ltd_pune",
      label: "BHAGINI NIVEDITA SAHAKARI BANK LTD PUNE"
    },
    {
      key: "bharat_cooperative_bank_mumbai_limited",
      label: "BHARAT COOPERATIVE BANK MUMBAI LIMITED"
    },
    {
      key: "bombay_mercantile_cooperative_bank_ltd",
      label: "BOMBAY MERCANTILE COOPERATIVE BANK LTD"
    },
    {
      key: "canara_bank",
      label: "CANARA BANK"
    },
    {
      key: "capital_small_finance_bank_limited",
      label: "CAPITAL SMALL FINANCE BANK LIMITED"
    },
    {
      key: "central_bank_of_india",
      label: "CENTRAL BANK OF INDIA"
    },
    {
      key: "chhattisgarh_rajya_gramin_bank",
      label: "CHHATTISGARH RAJYA GRAMIN BANK"
    },
    {
      key: "citi_bank",
      label: "CITI BANK"
    },
    {
      key: "citizen_credit_cooperative_bank_limited",
      label: "CITIZEN CREDIT COOPERATIVE BANK LIMITED"
    },
    {
      key: "city_union_bank_limited",
      label: "CITY UNION BANK LIMITED"
    },
    {
      key: "coastal_local_area_bank_ltd",
      label: "COASTAL LOCAL AREA BANK LTD"
    },
    {
      key: "credit_agricole_corporate_and_investment_bank_calyon_bank",
      label: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK"
    },
    {
      key: "credit_suisee_ag",
      label: "CREDIT SUISEE AG"
    },
    {
      key: "csb_bank_limited",
      label: "CSB BANK LIMITED"
    },
    {
      key: "ctbc_bank_co_ltd",
      label: "CTBC BANK CO LTD"
    },
    {
      key: "darussalam_co_operative_urban_bank_ltd",
      label: "Darussalam Co operative Urban Bank Ltd"
    },
    {
      key: "dcb_bank_limited",
      label: "DCB BANK LIMITED"
    },
    {
      key: "deogiri_nagari_sahakari_bank_ltd_aurangabad",
      label: "DEOGIRI NAGARI SAHAKARI BANK LTD AURANGABAD"
    },
    {
      key: "deposit_insurance_and_credit_guarantee_corporation",
      label: "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION"
    },
    {
      key: "deustche_bank",
      label: "DEUSTCHE BANK"
    },
    {
      key: "development_bank_of_singapore",
      label: "DEVELOPMENT BANK OF SINGAPORE"
    },
    {
      key: "dhanalakshmi_bank",
      label: "DHANALAKSHMI BANK"
    },
    {
      key: "dmk_jaoli_bank",
      label: "DMK JAOLI BANK"
    },
    {
      key: "doha_bank",
      label: "DOHA BANK"
    },
    {
      key: "dombivli_nagari_sahakari_bank_limited",
      label: "DOMBIVLI NAGARI SAHAKARI BANK LIMITED"
    },
    {
      key: "durgapur_steel_peoples_co_operative_bank_ltd",
      label: "DURGAPUR STEEL PEOPLES CO-OPERATIVE BANK LTD"
    },
    {
      key: "emirates_nbd_bank_pjsc",
      label: "EMIRATES NBD BANK PJSC"
    },
    {
      key: "equitas_small_finance_bank_limited",
      label: "EQUITAS SMALL FINANCE BANK LIMITED"
    },
    {
      key: "esaf_small_finance_bank_limited",
      label: "ESAF SMALL FINANCE BANK LIMITED"
    },
    {
      key: "export_import_bank_of_india",
      label: "EXPORT IMPORT BANK OF INDIA"
    },
    {
      key: "federal_bank",
      label: "FEDERAL BANK"
    },
    {
      key: "fincare_small_finance_bank_ltd",
      label: "FINCARE SMALL FINANCE BANK LTD"
    },
    {
      key: "fino_payments_bank",
      label: "FINO PAYMENTS BANK"
    },
    {
      key: "first_abu_dhabi_bank_pjsc",
      label: "FIRST ABU DHABI BANK PJSC"
    },
    {
      key: "firstrand_bank_limited",
      label: "FIRSTRAND BANK LIMITED"
    },
    {
      key: "gp_parsik_bank",
      label: "GP PARSIK BANK"
    },
    {
      key: "gs_mahanagar_co_operative_bank_limited_mumbai",
      label: "GS Mahanagar Co-operative Bank Limited Mumbai"
    },
    {
      key: "guardian_souharda_sahakari_bank_niyamita",
      label: "Guardian Souharda Sahakari Bank Niyamita"
    },
    {
      key: "haryana_state_cooperative_bank",
      label: "HARYANA STATE COOPERATIVE BANK"
    },
    {
      key: "hdfc_bank",
      label: "HDFC BANK"
    },
    {
      key: "himachal_pradesh_state_cooperative_bank_ltd",
      label: "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD"
    },
    {
      key: "hsbc_bank",
      label: "HSBC BANK"
    },
    {
      key: "hutatma_sahakari_bank_ltd",
      label: "Hutatma Sahakari Bank Ltd"
    },
    {
      key: "icici_bank_limited",
      label: "ICICI BANK LIMITED"
    },
    {
      key: "idbi_bank",
      label: "IDBI BANK"
    },
    {
      key: "idfc_first_bank_ltd",
      label: "IDFC First Bank Ltd"
    },
    {
      key: "india_post_payment_bank",
      label: "INDIA POST PAYMENT BANK"
    },
    {
      key: "indian_bank",
      label: "INDIAN BANK"
    },
    {
      key: "indian_overseas_bank",
      label: "INDIAN OVERSEAS BANK"
    },
    {
      key: "indusind_bank",
      label: "INDUSIND BANK"
    },
    {
      key: "industrial_and_commercial_bank_of_china_limited",
      label: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED"
    },
    {
      key: "industrial_bank_of_korea",
      label: "INDUSTRIAL BANK OF KOREA"
    },
    {
      key: "irinjalakuda_town_co_operative_bank_ltd",
      label: "Irinjalakuda Town Co-Operative Bank Ltd"
    },
    {
      key: "jalgaon_janata_sahakari_bank_limited",
      label: "JALGAON JANATA SAHAKARI BANK LIMITED"
    },
    {
      key: "jammu_and_kashmir_bank_limited",
      label: "JAMMU AND KASHMIR BANK LIMITED"
    },
    {
      key: "jana_small_finance_bank_ltd",
      label: "JANA SMALL FINANCE BANK LTD"
    },
    {
      key: "janakalyan_sahakari_bank_limited",
      label: "JANAKALYAN SAHAKARI BANK LIMITED"
    },
    {
      key: "janaseva_sahakari_bank_borivli_limited",
      label: "JANASEVA SAHAKARI BANK BORIVLI LIMITED"
    },
    {
      key: "janaseva_sahakari_bank_limited",
      label: "JANASEVA SAHAKARI BANK LIMITED"
    },
    {
      key: "janata_sahakari_bank_limited",
      label: "JANATA SAHAKARI BANK LIMITED"
    },
    {
      key: "janatha_seva_cooperative_bank_ltd",
      label: "JANATHA SEVA COOPERATIVE BANK LTD"
    },
    {
      key: "jio_payments_bank_limited",
      label: "JIO PAYMENTS BANK LIMITED"
    },
    {
      key: "jp_morgan_bank",
      label: "JP MORGAN BANK"
    },
    {
      key: "jsc_vtb_bank",
      label: "JSC VTB BANK"
    },
    {
      key: "kallappanna_awade_ichalkaranji_janata_sahakari_bank_limited",
      label: "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED"
    },
    {
      key: "kalupur_commercial_cooperative_bank",
      label: "KALUPUR COMMERCIAL COOPERATIVE BANK"
    },
    {
      key: "karnataka_bank_limited",
      label: "KARNATAKA BANK LIMITED"
    },
    {
      key: "karnataka_gramin_bank",
      label: "KARNATAKA GRAMIN BANK"
    },
    {
      key: "karnataka_vikas_grameena_bank",
      label: "KARNATAKA VIKAS GRAMEENA BANK"
    },
    {
      key: "karur_vysya_bank",
      label: "KARUR VYSYA BANK"
    },
    {
      key: "keb_hana_bank",
      label: "KEB Hana Bank"
    },
    {
      key: "kerala_gramin_bank",
      label: "KERALA GRAMIN BANK"
    },
    {
      key: "kookmin_bank",
      label: "KOOKMIN BANK"
    },
    {
      key: "kotak_mahindra_bank_limited",
      label: "KOTAK MAHINDRA BANK LIMITED"
    },
    {
      key: "maharashtra_gramin_bank",
      label: "Maharashtra Gramin Bank"
    },
    {
      key: "maharashtra_state_cooperative_bank",
      label: "MAHARASHTRA STATE COOPERATIVE BANK"
    },
    {
      key: "mahesh_sahakari_bank_ltd_pune",
      label: "Mahesh Sahakari Bank Ltd Pune"
    },
    {
      key: "mashreqbank_psc",
      label: "MASHREQBANK PSC"
    },
    {
      key: "mizuho_bank_ltd",
      label: "MIZUHO BANK LTD"
    },
    {
      key: "model_cooperative_bank_ltd",
      label: "MODEL COOPERATIVE BANK LTD"
    },
    {
      key: "mufg_bank_ltd",
      label: "MUFG BANK LTD"
    },
    {
      key: "nagpur_nagarik_sahakari_bank_limited",
      label: "NAGPUR NAGARIK SAHAKARI BANK LIMITED"
    },
    {
      key: "national_bank_for_agriculture_and_rural_development",
      label: "NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT"
    },
    {
      key: "nav_jeevan_co_op_bank_ltd",
      label: "NAV JEEVAN CO OP BANK LTD"
    },
    {
      key: "new_india_cooperative_bank_limited",
      label: "NEW INDIA COOPERATIVE BANK LIMITED"
    },
    {
      key: "nkgsb_cooperative_bank_limited",
      label: "NKGSB COOPERATIVE BANK LIMITED"
    },
    {
      key: "north_east_small_finance_bank_limited",
      label: "NORTH EAST SMALL FINANCE BANK LIMITED"
    },
    {
      key: "nsdl_payments_bank_limited",
      label: "NSDL Payments Bank Limited"
    },
    {
      key: "nutan_nagarik_sahakari_bank_limited",
      label: "NUTAN NAGARIK SAHAKARI BANK LIMITED"
    },
    {
      key: "pavana_sahakari_bank_ltd",
      label: "Pavana Sahakari Bank LTD"
    },
    {
      key: "paytm_payments_bank_ltd",
      label: "PAYTM PAYMENTS BANK LTD"
    },
    {
      key: "prime_cooperative_bank_limited",
      label: "PRIME COOPERATIVE BANK LIMITED"
    },
    {
      key: "pt_bank_maybank_indonesia_tbk",
      label: "PT BANK MAYBANK INDONESIA TBK"
    },
    {
      key: "punjab_and_sind_bank",
      label: "PUNJAB AND SIND BANK"
    },
    {
      key: "punjab_national_bank",
      label: "PUNJAB NATIONAL BANK"
    },
    {
      key: "qatar_national_bank_saq",
      label: "QATAR NATIONAL BANK SAQ"
    },
    {
      key: "rabobank_international",
      label: "RABOBANK INTERNATIONAL"
    },
    {
      key: "rajarambapu_sahakari_bank_limited",
      label: "RAJARAMBAPU SAHAKARI BANK LIMITED"
    },
    {
      key: "rajarshi_shahu_sahakari_bank_ltd_pune",
      label: "RAJARSHI SHAHU SAHAKARI BANK LTD PUNE"
    },
    {
      key: "rajasthan_marudhara_gramin_bank",
      label: "RAJASTHAN MARUDHARA GRAMIN BANK"
    },
    {
      key: "rajgurunagar_sahakari_bank_limited",
      label: "RAJGURUNAGAR SAHAKARI BANK LIMITED"
    },
    {
      key: "rajkot_nagrik_sahakari_bank_limited",
      label: "RAJKOT NAGRIK SAHAKARI BANK LIMITED"
    },
    {
      key: "rajnandgaon_district_central_co_operative_bank_ltd",
      label: "Rajnandgaon District Central Co-operative Bank Ltd"
    },
    {
      key: "rbl_bank_limited",
      label: "RBL Bank Limited"
    },
    {
      key: "reserve_bank_of_india",
      label: "RESERVE BANK OF INDIA"
    },
    {
      key: "samarth_sahakari_bank_ltd",
      label: "SAMARTH SAHAKARI BANK LTD"
    },
    {
      key: "sant_sopankaka_sahakari_bank_ltd",
      label: "SANT SOPANKAKA SAHAKARI BANK LTD"
    },
    {
      key: "saptagiri_grameena_bank",
      label: "Saptagiri Grameena Bank"
    },
    {
      key: "saraspur_nagrik_co_operative_bank_ltd_saraspur",
      label: "Saraspur Nagrik Co operative Bank Ltd Saraspur"
    },
    {
      key: "saraswat_cooperative_bank_limited",
      label: "SARASWAT COOPERATIVE BANK LIMITED"
    },
    {
      key: "satara_sahakari_bank_ltd",
      label: "Satara Sahakari Bank Ltd"
    },
    {
      key: "saurashtra_gramin_bank",
      label: "Saurashtra Gramin Bank"
    },
    {
      key: "sber_bank",
      label: "SBER BANK"
    },
    {
      key: "sbm_bank_india_limited",
      label: "SBM BANK INDIA LIMITED"
    },
    {
      key: "shikshak_sahakari_bank_limited",
      label: "SHIKSHAK SAHAKARI BANK LIMITED"
    },
    {
      key: "shinhan_bank",
      label: "SHINHAN BANK"
    },
    {
      key: "shivalik_small_finance_bank_limited",
      label: "Shivalik Small Finance Bank Limited"
    },
    {
      key: "shree_kadi_nagarik_sahakari_bank_limited",
      label: "Shree Kadi Nagarik Sahakari Bank Limited"
    },
    {
      key: "shri_chhatrapati_rajashri_shahu_urban_cooperative_bank_limited",
      label: "SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LIMITED"
    },
    {
      key: "shri_veershaiv_co_op_bank_ltd",
      label: "Shri Veershaiv Co Op Bank Ltd"
    },
    {
      key: "sir_m_visvesvaraya_co_operative_bank_ltd",
      label: "SIR M VISVESVARAYA CO OPERATIVE BANK LTD"
    },
    {
      key: "smriti_nagrik_sahakari_bank_maryadit",
      label: "Smriti Nagrik Sahakari Bank Maryadit"
    },
    {
      key: "societe_generale",
      label: "SOCIETE GENERALE"
    },
    {
      key: "solapur_janata_sahakari_bank_limited",
      label: "SOLAPUR JANATA SAHAKARI BANK LIMITED"
    },
    {
      key: "south_indian_bank",
      label: "SOUTH INDIAN BANK"
    },
    {
      key: "sree_charan_souhardha_co_operative_bank_ltd",
      label: "SREE CHARAN SOUHARDHA CO OPERATIVE BANK LTD"
    },
    {
      key: "standard_chartered_bank",
      label: "STANDARD CHARTERED BANK"
    },
    {
      key: "state_bank_of_india",
      label: "STATE BANK OF INDIA"
    },
    {
      key: "suco_souharda_sahakari_bank_ltd",
      label: "Suco Souharda Sahakari Bank Ltd"
    },
    {
      key: "sumitomo_mitsui_banking_corporation",
      label: "SUMITOMO MITSUI BANKING CORPORATION"
    },
    {
      key: "surat_national_cooperative_bank_limited",
      label: "SURAT NATIONAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "suryoday_small_finance_bank_limited",
      label: "SURYODAY SMALL FINANCE BANK LIMITED"
    },
    {
      key: "sutex_cooperative_bank_limited",
      label: "SUTEX COOPERATIVE BANK LIMITED"
    },
    {
      key: "tamilnad_mercantile_bank_limited",
      label: "TAMILNAD MERCANTILE BANK LIMITED"
    },
    {
      key: "telangana_state_coop_apex_bank",
      label: "TELANGANA STATE COOP APEX BANK"
    },
    {
      key: "textile_traders_co_operative_bank_ltd",
      label: "TEXTILE TRADERS CO OPERATIVE BANK LTD"
    },
    {
      key: "the_ap_mahesh_cooperative_urban_bank_limited",
      label: "THE AP MAHESH COOPERATIVE URBAN BANK LIMITED"
    },
    {
      key: "the_ahmednagar_district_central_co_operative_bank_ltd",
      label: "THE AHMEDNAGAR DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      key: "the_ajara_urban_co_op_bank_ltd_ajara",
      label: "The Ajara Urban Co op Bank Ltd Ajara"
    },
    {
      key: "the_akola_district_central_cooperative_bank",
      label: "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK"
    },
    {
      key: "the_akola_urban_cooperative_bank_limited",
      label: "The Akola Urban Cooperative Bank Limited"
    },
    {
      key: "the_andhra_pradesh_state_cooperative_bank_limited",
      label: "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_banaskantha_mercantile_cooperative_bank_ltd",
      label: "The Banaskantha Mercantile Cooperative Bank Ltd"
    },
    {
      key: "the_bank_of_nova_scotia",
      label: "THE BANK OF NOVA SCOTIA"
    },
    {
      key: "the_baramati_sahakari_bank_ltd",
      label: "THE BARAMATI SAHAKARI BANK LTD"
    },
    {
      key: "the_burdwan_central_co_operative_bank_ltd",
      label: "The Burdwan Central Co-operative Bank Ltd"
    },
    {
      key: "the_cooperative_bank_of_rajkot_limited",
      label: "The Cooperative Bank Of Rajkot Limited"
    },
    {
      key: "the_cosmos_co_operative_bank_limited",
      label: "THE COSMOS CO OPERATIVE BANK LIMITED"
    },
    {
      key: "the_delhi_state_cooperative_bank_limited",
      label: "THE DELHI STATE COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_gadchiroli_district_central_cooperative_bank_limited",
      label: "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_greater_bombay_cooperative_bank_limited",
      label: "THE GREATER BOMBAY COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_gujarat_state_cooperative_bank_limited",
      label: "THE GUJARAT STATE COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_hasti_coop_bank_ltd",
      label: "THE HASTI COOP BANK LTD"
    },
    {
      key: "the_jalgaon_peopels_cooperative_bank_limited",
      label: "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_kalyan_janata_sahakari_bank_ltd",
      label: "THE KALYAN JANATA SAHAKARI BANK LTD"
    },
    {
      key: "the_kangra_central_cooperative_bank_limited",
      label: "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_kangra_cooperative_bank_limited",
      label: "THE KANGRA COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_karad_urban_cooperative_bank_limited",
      label: "THE KARAD URBAN COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_karanataka_state_cooperative_apex_bank_limited",
      label: "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED"
    },
    {
      key: "the_kerala_state_co_operative_bank_ltd",
      label: "The Kerala State Co Operative Bank Ltd"
    },
    {
      key: "the_kolhapur_urban_co_op_bank_ltd",
      label: "The Kolhapur Urban Co-op Bank Ltd"
    },
    {
      key: "the_kurmanchal_nagar_sahakari_bank_limited",
      label: "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED"
    },
    {
      key: "the_malad_sahakari_bank_ltd",
      label: "The Malad Sahakari Bank Ltd"
    },
    {
      key: "the_meghalaya_co_operative_apex_bank_ltd",
      label: "The Meghalaya Co-operative Apex Bank Ltd"
    },
    {
      key: "the_mehsana_urban_cooperative_bank",
      label: "THE MEHSANA URBAN COOPERATIVE BANK"
    },
    {
      key: "the_mumbai_district_central_cooperative_bank_limited",
      label: "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_municipal_cooperative_bank_limited",
      label: "THE MUNICIPAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_muslim_co_operative_bank_ltd",
      label: "The Muslim Co-operative Bank Ltd"
    },
    {
      key: "the_nainital_bank_limited",
      label: "THE NAINITAL BANK LIMITED"
    },
    {
      key: "the_nasik_merchants_cooperative_bank_limited",
      label: "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_navnirman_co_operative_bank_limited",
      label: "THE NAVNIRMAN CO-OPERATIVE BANK LIMITED"
    },
    {
      key: "the_nawanagar_cooperative_bank_ltd",
      label: "THE NAWANAGAR COOPERATIVE BANK LTD"
    },
    {
      key: "the_nilambur_co_operative_urban_bank_ltd_nilambur",
      label: "THE NILAMBUR CO OPERATIVE URBAN BANK LTD NILAMBUR"
    },
    {
      key: "the_odisha_state_cooperative_bank_ltd",
      label: "THE ODISHA STATE COOPERATIVE BANK LTD"
    },
    {
      key: "the_pandharpur_urban_co_op_bank_ltd_pandharpur",
      label: "THE PANDHARPUR URBAN CO OP BANK LTD PANDHARPUR"
    },
    {
      key: "the_punjab_state_cooperative_bank_ltd",
      label: "THE PUNJAB STATE COOPERATIVE BANK LTD"
    },
    {
      key: "the_pusad_urban_cooperative_bank_ltd_pusad",
      label: "The Pusad Urban Cooperative Bank Ltd Pusad"
    },
    {
      key: "the_rajasthan_state_cooperative_bank_limited",
      label: "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_satara_district_central_cooperative_bank_ltd",
      label: "THE SATARA DISTRICT CENTRAL COOPERATIVE BANK LTD"
    },
    {
      key: "the_shamrao_vithal_cooperative_bank",
      label: "THE SHAMRAO VITHAL COOPERATIVE BANK"
    },
    {
      key: "the_sindhudurg_district_central_coop_bank_ltd",
      label: "THE SINDHUDURG DISTRICT CENTRAL COOP BANK LTD"
    },
    {
      key: "the_surat_district_cooperative_bank_limited",
      label: "THE SURAT DISTRICT COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_surath_peoples_cooperative_bank_limited",
      label: "THE SURATH PEOPLES COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_tamil_nadu_state_apex_cooperative_bank",
      label: "THE TAMIL NADU STATE APEX COOPERATIVE BANK"
    },
    {
      key: "the_thane_bharat_sahakari_bank_limited",
      label: "THE THANE BHARAT SAHAKARI BANK LIMITED"
    },
    {
      key: "the_thane_district_central_cooperative_bank_limited",
      label: "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_udaipur_urban_co_operative_bank_ltd",
      label: "THE UDAIPUR URBAN CO OPERATIVE BANK LTD"
    },
    {
      key: "the_urban_co_operative_bank_ltd_no_one_seven_five_eight_perinthalmanna",
      label: "THE URBAN CO OPERATIVE BANK Ltd No ONE SEVEN FIVE EIGHT PERINTHALMANNA"
    },
    {
      key: "the_varachha_cooperative_bank_limited",
      label: "THE VARACHHA COOPERATIVE BANK LIMITED"
    },
    {
      key: "the_vijay_co_operative_bank_limited",
      label: "The Vijay Co Operative Bank Limited"
    },
    {
      key: "the_vishweshwar_sahakari_bank_limited",
      label: "THE VISHWESHWAR SAHAKARI BANK LIMITED"
    },
    {
      key: "the_west_bengal_state_cooperative_bank",
      label: "THE WEST BENGAL STATE COOPERATIVE BANK"
    },
    {
      key: "the_zoroastrian_cooperative_bank_limited",
      label: "THE ZOROASTRIAN COOPERATIVE BANK LIMITED"
    },
    {
      key: "tjsb_sahakari_bank_ltd",
      label: "TJSB SAHAKARI BANK LTD"
    },
    {
      key: "tumkur_grain_merchants_cooperative_bank_limited",
      label: "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED"
    },
    {
      key: "uco_bank",
      label: "UCO BANK"
    },
    {
      key: "ujjivan_small_finance_bank_limited",
      label: "Ujjivan Small Finance Bank Limited"
    },
    {
      key: "union_bank_of_india",
      label: "UNION BANK OF INDIA"
    },
    {
      key: "united_overseas_bank_limited",
      label: "UNITED OVERSEAS BANK LIMITED"
    },
    {
      key: "unity_small_finance_bank_limited",
      label: "Unity Small finance Bank Limited"
    },
    {
      key: "urban_co_operative_bank_ltd_bareilly",
      label: "Urban Co operative Bank Ltd Bareilly"
    },
    {
      key: "utkarsh_small_finance_bank",
      label: "UTKARSH SMALL FINANCE BANK"
    },
    {
      key: "uttar_pradesh_cooperative_bank_ltd",
      label: "UTTAR PRADESH COOPERATIVE BANK LTD"
    },
    {
      key: "vasai_janata_sahakari_bank_ltd",
      label: "VASAI JANATA SAHAKARI BANK LTD"
    },
    {
      key: "vasai_vikas_sahakari_bank_limited",
      label: "VASAI VIKAS SAHAKARI BANK LIMITED"
    },
    {
      key: "woori_bank",
      label: "WOORI BANK"
    },
    {
      key: "yes_bank",
      label: "YES BANK"
    },
    {
      key: "zila_sahakri_bank_limited_ghaziabad",
      label: "ZILA SAHAKRI BANK LIMITED GHAZIABAD"
    }
  ],

  CUSTOMER_APPLICATION_STATUS: [
    "PENDING",
    "APPLIED",
    "UNDER_WRITING",
    "SANCTIONED",
    "POST_SANCTIONED",
    "APPROVED",
    "PRE_DISBURSEMENT",
    "DISBURSED",
    "REJECTED",
    "QUERY",
    "AGREEMENT",
    "ESIGN",
  ],
  CUSTOMER_APPLICATION_STATUS_MAP: {
    PENDING: 'PENDING',
    APPLIED: 'APPLIED',
    UNDER_WRITING: 'UNDER_WRITING',
    SANCTIONED: 'SANCTIONED',
    POST_SANCTIONED: 'POST_SANCTIONED',
    APPROVED: 'APPROVED',
    PRE_DISBURSEMENT: 'PRE_DISBURSEMENT',
    DISBURSED: 'DISBURSED',
    REJECTED: 'REJECTED',
    QUERY: 'QUERY',
    AGREEMENT: 'AGREEMENT',
    ESIGNED: 'ESIGNED'
  },

  STATUS_IN_PROGRESS: [
    { key: "QUERY", label: "Query" },
    { key: "UNDER_WRITING", label: "Underwriting" },
  ],

  STATUS_SANCTIONED: [
    { key: "SANCTIONED", label: "Sanctioned" },
    { key: "POST_SANCTIONED", label: "Post Sanctioned" },
  ],

  STATUS_APPROVED: [
    { key: "APPROVED", label: "Approved" },
    { key: "AGREEMENT", label: "Agreement" },
    { key: "ESIGNED", label: "E-Signed" },
  ],
  ACTIVE_LOAN_SEARCH_OPTIONS: [
    { key: "ACTIVE", label: "Active" },
    { key: "OVERDUE", label: "Overdue" },
  ],
  APPLICATION_ROUTE_MAP: {
    pendingapplications: "APPLIED",
    inprogress: "PROGRESS",
    sanctioned: "SANCTIONED",
    approved: "APPROVED",
    rejected: "REJECTED"
  },
  STATIC_STATES: [
    { key: "AN", label: "Andaman and Nicobar Islands" },
    { key: "AP", label: "Andhra Pradesh" },
    { key: "AR", label: "Arunachal Pradesh" },
    { key: "AS", label: "Assam" },
    { key: "BR", label: "Bihar" },
    { key: "CG", label: "Chhattisgarh" },
    { key: "CH", label: "Chandigarh" },
    { key: "DD", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { key: "DL", label: "Delhi" },
    { key: "DN", label: "Dadra and Nagar Haveli" },
    { key: "GA", label: "Goa" },
    { key: "GJ", label: "Gujarat" },
    { key: "HP", label: "Himachal Pradesh" },
    { key: "HR", label: "Haryana" },
    { key: "JH", label: "Jharkhand" },
    { key: "JK", label: "Jammu and Kashmir" },
    { key: "KA", label: "Karnataka" },
    { key: "KL", label: "Kerala" },
    { key: "LD", label: "Lakshadweep" },
    { key: "MH", label: "Maharashtra" },
    { key: "ML", label: "Meghalaya" },
    { key: "MN", label: "Manipur" },
    { key: "MP", label: "Madhya Pradesh" },
    { key: "MZ", label: "Mizoram" },
    { key: "NL", label: "Nagaland" },
    { key: "OR", label: "Odisha" },
    { key: "PB", label: "Punjab" },
    { key: "PY", label: "Puducherry" },
    { key: "RJ", label: "Rajasthan" },
    { key: "SK", label: "Sikkim" },
    { key: "TG", label: "Telangana" },
    { key: "TN", label: "Tamil Nadu" },
    { key: "TR", label: "Tripura" },
    { key: "UL", label: "Uttarakhand" },
    { key: "UP", label: "Uttar Pradesh" },
    { key: "WB", label: "West Bengal" }
  ],
  PROCESSING_FEE_PERCENTAGES: [
    { key: 5, label: "5 %" },
    { key: 6, label: "6 %" },
    { key: 7, label: "7 %" },
    { key: 8, label: "8 %" },
    { key: 9, label: "9 %" },
    { key: 10, label: "10 %" },
  ],
  SOURCING_CHANNELS: [
    { key: "DSA", label: "DSA" },
    { key: "DIGITAL", label: "Digital" },
    { key: "TELE_CALLING", label: "Tele Calling" },
    { key: "SALES_OFFICER", label: "Sales Officer" },
  ],
  SOURCING_CHANNEL_MAP: {
    dsa: "DSA",
    digital: "DIGITAL",
    tele_calling: "TELE_CALLING",
    sales_officer: "SALES_OFFICER",
  },
  SOURCING_CHANNEL_KEY_MAP: [
    "dsa",
    "digital",
    "tele_calling",
    "sales_officer",
  ],
  REPAYMENT_MODES: [
    { key: "OFFLINE", label: "Offline" },
    { key: "WAIVER", label: "Waiver" },
  ],
  REPAYMENT_STATUS: [
    { key: "SUCCESS", label: "Success" },
    { key: "FAILED", label: "Failed" },
  ],
  AUTHENTICATION_METHODS: [
    { key: "L", label: "Legacy" },
    { key: "A", label: "Advanced" },
  ],
  MONTH_BY_NUMBERS: [
    { key: "01", label: "Jan" },
    { key: "02", label: "Feb" },
    { key: "03", label: "Mar" },
    { key: "04", label: "Apr" },
    { key: "05", label: "May" },
    { key: "06", label: "Jun" },
    { key: "07", label: "Jul" },
    { key: "08", label: "Aug" },
    { key: "09", label: "Sep" },
    { key: "10", label: "Oct" },
    { key: "11", label: "Nov" },
    { key: "12", label: "Dec" },
  ],

  OVERDUE_PORTFOLIO_RANGE_MAP: {
    overdue30: "0-30",
    overdue60: "30-60",
    overdue90: "60-90",
    overdue180: "90-180",
    overdue180Plus: "180+"
  },
  OVERDUE_PORTFOLIO_RANGE_KEY_MAP: [
    "overdue30",
    "overdue60",
    "overdue90",
    "overdue180",
    "overdue180Plus",
  ],
  CUSTOMER_FILTER_STATUS_MAP: {
    totalUsers: "",
    pending: "PENDING",
    completed: "COMPLETED",
    applied: "APPLIED"
  },
  CUSTOMER_FILTER_STATUS_KEY_MAP: [
    "pending",
    "completed",
    "applied"
  ],
  DASHBOARD_LEADS_FILTER_STATUS_MAP: {
    totalleads: "",
    offline: "OFFLINE",
    enquiry: "ENQUIRY",
    digital: "DIGITAL",
  },
  DASHBOARD_APPLICATION_STATUS_MAP: {
    totalApplication: "",
    pendingapplications: "PENDING",
    underwriting: "UNDERWRITING",
    sanctioned: "SANCTIONED",
    approved: "APPROVED",
    agreement: "AGREEMENT",
    disbursed: "DISBURSED",
    rejected: "REJECTED",
  },
  DASHBOARD_APPLICATION_STATUS_KEY_MAP: [
    "totalApplication",
    "pendingapplications",
    "underwriting",
    "sanctioned",
    "approved",
    "agreement",
    "disbursed",
    "rejected",
  ],
  DISBURSEMENT_STATUS_MAP: {
    totalDisbursement: "",
    dsa: "DSA",
    digital: "DIGITAL",
    telecalling: "TELE_CALLING",
    salesofficer: "SALES_OFFICER",
  }
  ,
  PAYMENT_TYPES: [
    { key: "IMPS", label: "IMPS" },
  ],
};
