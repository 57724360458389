import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SVGICON } from '../../constant/theme';


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function LogoutPage(props) {
  function onLogout() {
    if (props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
      console.log("Customer Logout", props);
      // props.apis.apiCustomerLogout().then(res => {
      //   if(props?.$store?.persona === _enum.ROUTE_PERSONAS.customer){
      //     props.navigate("/open/customer/login");
      //   }else{
      //     props.navigate("/open/login");
      //   }
      // })
      const resolve = () => {
        console.log('logout', "DESTROY_SESSION");
        app?.props?.store("auth", false);
        app?.props?.store("user", false);
        app?.props?.store("persona", "");
        localStorage.clear();
        if (props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
          props.navigate("/open/customer/login");
        } else {
          props.navigate("/open/login");
        }
      }
      resolve();
    } else {
      // props.apis.apiLogout().then(res => {
      //   console.log("Admin Logout", props);
      //   if(props?.$store?.persona === _enum.ROUTE_PERSONAS.customer){
      //     props.navigate("/open/customer/login");
      //   }else{
      //     props.navigate("/open/login");
      //   }
      // })
      const resolve = () => {
        console.log('logout', "DESTROY_SESSION");
        app?.props?.store("auth", false);
        app?.props?.store("user", false);
        app?.props?.store("persona", "");
        localStorage.clear();
        if (props?.$store?.persona === _enum.ROUTE_PERSONAS.customer) {
          props.navigate("/open/customer/login");
        } else {
          props.navigate("/open/login");
        }
      }
      resolve();
    }
  }
  return (
    <>
      <button className="dropdown-item ai-icon ms-1 logout-btn" onClick={onLogout}>
        {SVGICON.Logout} {" "}
        <span className="ms-2">Logout </span>
      </button>
    </>
  )
}

export default constore(LogoutPage);