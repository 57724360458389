import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Accordion, Button, Card, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const MyApplications = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [applications, setApplications] = useState([]);
  const [activeApplRecord, setActiveApplRecord] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [journey, setJourney] = useState([]);
  const columns = [
    {
      dataIndex: "applicationId",
      title: "Application ID",
      render: (text, record) => {
        return (
          <>
            <div>
              <h6
                className="text-primary fw-bold cursor_pointer"
                onClick={(...args) => handleShow(...args, record)}
              >
                {props?.$store?.user?.customerName}
              </h6>
              <span className="text-primary">{text || "-"}</span>
            </div>
          </>
        );
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmt",
      title: "Sanctioned Loan Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedTenure",
      title: "Sanctioned Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Applied On",
      render: (text, record) => {
        if (record?.createdAt) {
          let date = new Date(record?.createdAt).dateToDDMMYYYY(
            new Date(record?.createdAt)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let status = record?.status || "pending";
        let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

        return (
          <Dropdown className="status-dropdown pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              {status?.toTitleCase()}
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setActiveApplRecord(null);
  };

  const handleShow = (e, record) => {
    console.log({ record });
    setActiveApplRecord(record);
    setShow(true);
    // getJourney(record.applicationId);
  };

  const getApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetCustomerApplications(payload).then(({ data, total }) => {
      setApplications(data);
      setIsFetching(false);
    });
  };

  const getJourney = (applicationId) => {
    props.apis
      .apiGetCustomerApplicationJourney({ applicationId: applicationId })
      .then((res) => {
        setJourney([...res]?.reverse());
        // let mokeres = [
        //   { status: "PENDING", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "APPLIED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "UNDER_WRITING", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "SANCTIONED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "POST_SANCTIONED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "APPROVED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "PRE_DISBURSEMENT", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "DISBURSED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "REJECTED", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "QUERY", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "AGREEMENT", createdAt: "2024-05-15T10:40:22.833Z" },
        //   { status: "ESIGNED", createdAt: "2024-05-15T10:40:22.833Z" },
        // ];
        // setJourney([
        //   {
        //     status: "ACTIVE",
        //     createdAt: "2024-05-15T10:40:22.833Z",
        //   },
        //   {
        //     status: "PENDING",
        //     createdAt: "2024-05-15T10:40:22.833Z",
        //   },
        // ]);
      });
  };

  const viewApplication = (record) => {
    console.log({ record });
    navigate(`/app/myapplicationview?id=${record.applicationId}`);
  };

  const handleCustomerApplRejection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let urldata = {
      applicationId: activeApplRecord?.applicationId,
    };
    console.log({ urldata });

    swal2
      .fire({
        title: "Reason for rejection",
        input: "textarea",
        inputAttributes: {
          autocapitalize: "off",
          name: "rejectedReason",
          required: true,
        },
        showCancelButton: true,
        confirmButtonText: "Reject",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return "Reason for rejection is required";
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          let payload = {
            rejectedReason: result.value,
          };
          console.log({ payload });

          let urldata = {
            applicationId: activeApplRecord?.applicationId,
          };

          props.apis
            .apiCustomerRejectSanctionedApplication(payload, urldata)
            .then((res) => {
              swal2.fire({
                text: "Application Rejected successfully !!",
                icon: "success",
                iconColor: "#FF4D4D",
                timer: 4000,
              });
              handleClose();
              getApplications();
            });
        }
      });
  };

  const handleCustomerApplAccept = () => {
    navigate(
      `/app/customerpostsanctiondocs?id=${activeApplRecord?.applicationId}`
    );
  };

  useEffect(() => {
    console.log({ activeApplRecord });
    if (activeApplRecord) {
      getJourney(activeApplRecord?.applicationId);
    }
  }, [activeApplRecord]);

  useEffect(() => {
    getApplications();
  }, []);
  return (
    <div>
      <MainPagetitle
        mainTitle="My Application"
        pageTitle={"My Application"}
        parentTitle={"Home"}
        pageIcon={imagepaths.greyUserCheckIconActive}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Application Status</h4>
                </div>
                <AppTable
                  data={applications}
                  columns={columns}
                  reorder={true}
                  showPagination={false}
                  editable={false}
                  viewAction={(...args) => viewApplication(...args)}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="application_status_modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="heading_title">
            My Application Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={Object.className({
              "decision_container mb-4": true,
              "d-none":
                activeApplRecord?.status !==
                _enum.CUSTOMER_APPLICATION_STATUS_MAP.SANCTIONED,
            })}
          >
            <img src={imagepaths.applStatusImg} alt="loan image" />
            <div className="content_box">
              <div className="details">
                <p>
                  Loan applied for Rs.{" "}
                  <span>{activeApplRecord?.loanAmount?.cur()}</span>
                </p>
                <p className="text-primary">
                  Got Recommendation of Rs.{" "}
                  <span>{activeApplRecord?.sanctionedLoanAmt?.cur()}</span>
                </p>
              </div>
              <div className="footer_details">
                <p className="me-3">
                  Tenure: <span>{activeApplRecord?.sanctionedTenure} Days</span>
                </p>
                <p>
                  ROI: <span>{activeApplRecord?.sanctionedIRR} %</span>
                </p>
              </div>
              <div className="nav_container justify-content-start">
                <button
                  type="button"
                  className="btn btn-lg btn-primary-lite me-3"
                  onClick={(...args) => handleCustomerApplRejection(...args)}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={(...args) => handleCustomerApplAccept(...args)}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
          <div class="timeline-container">
            <ul class="tl">
              {journey.map((mile) => (
                <li className="tl-item done">
                  <div className="item-icon"></div>
                  <div className="item-text">
                    {mile?.status === "QUERY" && (
                      <button
                        type="button"
                        className="link-btn"
                        onClick={(...args) =>
                          navigate(
                            `/app/myapplicationqueries?id=${activeApplRecord?.applicationId}`
                          )
                        }
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-circle"></i>
                        </span>
                        Query raised by underwriter
                      </button>
                    )}
                    <div className="item-title">{mile?.status}</div>
                    <div className="item-detail">
                      {moment(mile.createdAt).format("DD-MM-YY hh:mm a")}
                    </div>
                  </div>
                </li>
              ))}

              {/* <li className="tl-item done">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Underwriting</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item done">
                <div className="item-icon"></div>
                <div className="item-text">
                  <button
                    type="button"
                    className="link-btn"
                    onClick={(...args) =>
                      navigate(`/app/myapplicationqueries?id=${activeApplId}`)
                    }
                  >
                    <span className="me-2">
                      <i className="fa-solid fa-circle"></i>
                    </span>
                    Query raised by underwriter
                  </button>
                  <div className="item-title">Queries</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item done">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Sanction</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item completed">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Post Sanction</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item completed">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">References</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item completed">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Bank Account Details</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item completed">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Agreement</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li>

              <li className="tl-item completed">
                <div className="item-icon"></div>
                <div className="item-text">
                  <div className="item-title">Disbursement</div>
                  <div className="item-detail">26-02-24 10:00 a.m.</div>
                </div>
              </li> */}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default constore(MyApplications);
