export default {

    // LEADS
    ADD_LEAD: {
        url: "/api/v1/leads",
        method: "post",
    },
    ADD_CUSTOMER_LEAD: {
        url: "/api/v1/leads/customer",
        method: "post",
    },
    GET_LEADS: {
        url: "/api/v1/leads",
        method: "get",
    },
    UPDATE_LEAD: {
        url: "/api/v1/leads/{leadId}",
        method: "put",
    },
    GET_LEADS_BY_ID: {
        url: "/api/v1/leads/{leadId}",
        method: "get",
    },
    DELETE_LEAD: {
        url: "/api/v1/leads/{leadId}",
        method: "delete",
    },
    ASSIGN_DEPT_TO_LEAD: {
        url: "/api/v1/leads/assignment",
        method: "put",
    },
    GET_CUSTOMER_LOAN_LOGS: {
        url: "/api/v1/customer/getLogs/{customerId}",
        method: "get",
    },
    GET_LEAD_HISTORY: {
        url: "/api/v1/assigned-lead-history",
        method: "get",
    },
    // BULK FILES
    BULK_UPLOAD_LEAD: {
        url: "/api/v1/leads/bulk-upload",
        method: "post",
    },
    DOWNLOAD_LEADS_LISTS: {
        url: "/api/v1/leads/bulk-download",
        method: "post",
    },
    DOWNLOAD_LEADS_TEMPLATE: {
        url: "/api/v1/leads/bulk-upload/template",
        method: "get",
    },
    // SALES DASHBOARD API'S
    GET_TOTAL_LEADS_DATA: {
        url: "/api/v1/dashboard/sales/leads/all",
        method: "get",
    },
    GET_PENDING_LEADS_DATA: {
        url: "/api/v1/dashboard/sales/leads/open",
        method: "get",
    },
    GET_COMPLETED_LEADS_DATA: {
        url: "/api/v1/dashboard/sales/leads/completed",
        method: "get",
    },
    GET_INTERESTED_LEADS_DATA: {
        url: "/api/v1/dashboard/sales/leads/interested",
        method: "get",
    },

}