import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import pages from "../pages";
import App from "../App";
import ThemeContextProvider from "../context/ThemeContext";

export const getComponent = (o) => ({
  path: o.path,
  element: (
    <ThemeContextProvider>
      <App path={o.path}>
        <o.Element />
      </App>
    </ThemeContextProvider>
  )
})

export const getRouter = (values, to = "/open/login") => (createBrowserRouter([
  ...values.map(getComponent),
  {
    path: "*",
    title: "*",
    element: <Navigate to={to} />,
  }
]));
export default getRouter(Object.values(pages));
