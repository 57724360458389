import { Switch } from "antd";
import React from "react";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function AppAccordian(props) {
  let {
    data = [],
    id = "accordionExample",
    handleEdit,
    handleView,
    handleDelete,
    handleToggle,
    crudaction = true,
    toggleaction = true,
    readonly = false,
    statusaction = true,
    targetType = "",
    baseroute = "/",
    showlinks = false,
    params = props.urlparams,
  } = props;
  let linksArr = [];
  // console.log(baseroute);
  const accordian = useRef();

  useEffect(() => {
    accordian?.current
      ?.querySelector(".accordion-item>.accordion-header>button")
      ?.click();
    console.log(data[0]);
  }, [data]);

  let getPathLink = (i, path) => {
    let baselink = `/lms/courses/module/${path}`;

    let link =
      baselink +
      Object.encodeuri({
        course_id: data[i]?.sessiondetails?.course_id,
        course_name: data[i]?.sessiondetails?.course_name,
        module_id: data[i]?.record?.module_id,
        ...(data[i]?.sessiondetails?.readonly
          ? { readonly: data[i]?.sessiondetails?.readonly }
          : {}),
        ...(data[i]?.sessiondetails?.pathFromModule
          ? { pathFromModule: data[i]?.sessiondetails?.pathFromModule }
          : {}),
      });

    // let ppt = '/lms/courses/module/pptpage';
    // let handout = '/lms/courses/module/assessmentspage';

    return link;
  };
  return (
    <div ref={accordian} className="accordion custom_accordion" id={id}>
      {data.map(
        (
          {
            Content = () => <div>Accordian Content</div>,
            Title = () => (
              <button className="btn btn-link" type="button">
                Collapsible Group Item #1
              </button>
            ),
            Status = () => <></>,
            Links = () => <></>,
          },
          i
        ) => (
          <div className="accordion-item" key={i}>
            <h2 className="accordion-header" id={"heading" + i}>
              <button
                className="accordion-button collapsed text-base"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse" + i}
                aria-expanded="true"
                aria-controls={"collapse" + i}
              >
                <div className="button_content">
                  <div className="title">
                    <Title />
                  </div>
                  <div className="d-flex mx-4">
                    <div className="status_box">
                      {statusaction && (
                        <span className="d-flex align-item-center">
                          <i
                            className={Object.className({
                              "fa fa-check-circle-o active":
                                data[i]?.record.is_active === true,
                              "fa fa-ban disabled":
                                data[i]?.record.is_active === false,
                            })}
                          ></i>
                          <div className="status_text">
                            {data[i]?.record.is_active ? "Active" : "Disabled"}
                          </div>
                        </span>
                      )}
                      <span>
                        {data[i]?.record.completion_status}% Completed
                      </span>
                      {toggleaction && (
                        <span>
                          <Switch
                            {...(data[i]?.record?.is_active
                              ? {
                                  defaultChecked: true,
                                }
                              : {})}
                            onChange={(...args) =>
                              handleToggle(...args, data[i].record)
                            }
                          />
                        </span>
                      )}
                    </div>
                    {crudaction && (
                      <div className="crud_box">
                        <button
                          type="button"
                          className={Object.className({
                            "edit ms-2": true,
                            "d-none": !readonly,
                          })}
                          onClick={(...args) =>
                            handleEdit(...args, data[i]?.record)
                          }
                        >
                          <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button
                          type="button"
                          className={Object.className({
                            "delete ms-2": true,
                            "d-none": readonly,
                          })}
                          onClick={(...args) =>
                            handleView(...args, data[i]?.record)
                          }
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          className={Object.className({
                            "delete ms-2": true,
                            "d-none": !readonly,
                          })}
                          onClick={(...args) =>
                            handleDelete(...args, data[i]?.record)
                          }
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={"collapse" + i}
              className="accordion-collapse collapse"
              aria-labelledby={"heading" + i}
              data-bs-parent={"#" + id}
            >
              <div className="accordion-body px-lg-5">
                <Content />
                <div className="link_box_container">
                  {showlinks && (
                    <>
                      <Link
                        to={getPathLink(i, "videopage")}
                        className="link_box"
                      >
                        <img
                          src={imagepaths.videoIcon}
                          alt="link icon"
                          className="me-2"
                        />
                        Video
                      </Link>
                      <Link to={getPathLink(i, "pptpage")} className="link_box">
                        <img
                          src={imagepaths.pptIcon}
                          alt="link icon"
                          className="me-2"
                        />
                        PPT
                      </Link>
                      <Link
                        to={getPathLink(i, "assessmentspage")}
                        className="link_box"
                      >
                        <img
                          src={imagepaths.notesIcon}
                          alt="link icon"
                          className="me-2"
                        />
                        Assessments
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default AppAccordian;
