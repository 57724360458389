import React, { Component } from "react";

export class LoanDetailsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        page: 1,
      },
    };
    // search fields
    this.pendingFields = [
      {
        name: "customerName",
        label: "Name",
        type: "text",
        placeholder: "Customer Name",
      },
    ];
    this.activeLoansFields = [
      {
        name: "customerName",
        label: "Name",
        type: "text",
        placeholder: "Customer Name",
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options: _enum.ACTIVE_LOAN_SEARCH_OPTIONS,
        placeholder: "Status",
      },
      {
        name: "startDate",
        label: "Start Date",
        type: "date",
        placeholder: "Start Date",
      },
      {
        name: "endDate",
        label: "End Date",
        type: "date",
        placeholder: "End Date",
      },
    ];
    this.closedLoansFields = [
      {
        name: "customerName",
        label: "Name",
        type: "text",
        placeholder: "Customer Name",
      },
      {
        name: "startDate",
        label: "Start Date",
        type: "date",
        placeholder: "Start Date",
      },
      {
        name: "endDate",
        label: "End Date",
        type: "date",
        placeholder: "End Date",
      },
    ];
  }

  componentDidMount() {}

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    return (
      <>
        <AppForm
          className="row filter-row flex-auto mx-auto position-relative"
          ref="form"
        >
          {this.props.activeKey === "pending" ? (
            <>
              <div className="d-none d-xl-block col-sm-6 col-md-2 col-lg-4 col-xl-3"></div>
              {this.pendingFields?.map((field, i) => (
                <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={i}>
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
            </>
          ) : null}
          {this.props.activeKey === "active" ? (
            <>
              <div className="d-none col-sm-6 col-md-2 col-lg-4 col-xl-3"></div>
              {this.activeLoansFields?.map((field, i) => (
                <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={i}>
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
            </>
          ) : null}
          {this.props.activeKey === "closed" ? (
            <>
              <div className="d-none col-sm-6 col-md-2 col-lg-4 col-xl-3"></div>
              {this.closedLoansFields?.map((field, i) => (
                <div className="col-6 col-md-4 col-lg-3 col-xl-3" key={i}>
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
            </>
          ) : null}
          <div className="col-6 col-md-1 col-lg-1 p-0 d-flex align-items-start justify-content-center">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-6 col-md-1 col-lg-1 p-0">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(LoanDetailsSearch);
