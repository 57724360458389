import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from "./jsx/page-layouts";


const Navigate = (props) => {
  let navigate = useNavigate();
  window.navigate = (...args) => {
    navigate(...args);
  };
  return props.children;
};

function getLayoutComponent(currentpath) {
  let layoutName = currentpath.basename();
  if (layoutName == "open") {
    return Layout;
  } else {
    try {
      return require(`./jsx/page-layouts/${layoutName}`).default;
    } catch (err) {
      return Layout;
    }
  }
}

function App(props) {

  const user = () => {
    return props?.$store?.user || {};
  }

  const persona = () => {
    return props?.$store?.persona;
  }

  const loggedpath = () => {
    switch (persona) {
      case "customer":
        return "/open/customer/login";
      default:
        return "/open/login";
    }
  }
  const token = () => { return props?.$store.auth; }
  const redirectpath = () => { return token ? loggedpath : "/open/login"; }

  useEffect(() => {
    redirectpath();
  }, [])


  const CustomLayout = getLayoutComponent(window.location.pathname);

  props.$setNode({
    props
  });
  return (
    <Navigate>
      {
        CustomLayout && (
          <Suspense>
            <CustomLayout>
              {props.children}
            </CustomLayout>
          </Suspense>
        )
      }
    </Navigate>
  );
};

setTimeout(() => {
  window.root.vnode = window.App;
  window.app = root.vnode;
});
export default constore(App);

