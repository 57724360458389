export const SalesMenuList = [
    {
        title: "My Leads",
        iconStyle: imagepaths.SingleUserIcon,
        iconActive: imagepaths.SingleUserIconActive,
        to: "/app/leads",
        id: "leads",
    },
    {
        title: "Assigned to Opps",
        iconStyle: imagepaths.applicationCheckImg,
        iconActive: imagepaths.applicationCheckActiveImg,
        to: "/app/applications",
        id: "applications",
    },
];
