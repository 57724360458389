import React from 'react';
import { Link } from 'react-router-dom';

const MainPagetitle = ({ mainTitle, back, backLink, pageIcon }) => {

	const getPath = () => {
		return window.location.pathname.replace("/app/", "").toLowerCase()
	}

	return (
		<>
			<div className="page-titles">
				<ol className="breadcrumb">
					<li className={`d-flex align-items-center mm-active`}>

						{back ?
							<div className="d-flex flex-row align-items-center">
								<img className="menu-icon-img m-0" src={pageIcon ? pageIcon : imagepaths.Dashboard} height="15px" alt="" />
								<Link
									to={backLink}
									className='ms-2'>
									<span className='h-line text-muted'>{mainTitle} |</span>
									{back}
								</Link>
							</div>
							:
							<div className="d-flex flex-row align-items-center">
								<img src={pageIcon ? pageIcon : imagepaths.DashboardActive} className="menu-icon-img m-0 active" height="15px" alt="" />
								<span className="bc-title ms-2">{mainTitle}</span>
							</div>
						}
					</li>
				</ol>
			</div>
		</>
	);
};

export default MainPagetitle;