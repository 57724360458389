import React from 'react'
import { useState } from 'react';

function AppDropdown(props) {
    let { options=[],value="" } = props;
    let [selected, setSelected] = useState(value);
    return (
        <div className="dropdown action-label text-center">
            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                {selected}
            </a>
            <div className="dropdown-menu dropdown-menu-right">
                {
                    options.map((option)=>(
                        <a className="dropdown-item" href="#" onClick={()=>setSelected(option)}>
                            {option}
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default AppDropdown