import * as bootstrap from 'bootstrap';
import swal2 from "sweetalert2";
import { toast } from "react-toastify";

import "../imagepaths";
import "../other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../css/style.css";
import "../css/addonclass.css";
import "react-toastify/dist/ReactToastify.css";

window.Modal = bootstrap.Modal;
window.swal2 = swal2;
window.toast = toast;