import React, { useEffect, useState } from "react";

import { Button, Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import LoanDetailsSearch from "./LoanDetailsSearch/LoanDetailsSearch";

const LoanDetails = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const isCollections =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.collections;
  // const isCollections = true;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);

  const disbursementColumns = [
    {
      dataIndex: "applicationLoanAccount",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">
              {record?.applicationLoanAccount?.borrowerName}
            </h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "LAN No.",
      render: (text, record) => {
        return <>{record?.applicationLoanAccount?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctioned_amount",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return (
          <>
            {record?.applicationLoanAccount?.sanctionedLoanAmount?.cur() || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "roi",
      title: "ROI",
      render: (text, record) => {
        return (
          <>{`${record?.applicationLoanAccount?.sanctionedLoanIRR}%` || "-"}</>
        );
      },
    },
    {
      dataIndex: "tenure",
      title: "Sanctioned Tenure",
      render: (text, record) => {
        return (
          <>{record?.applicationLoanAccount?.sanctionedLoanTenure || "-"}</>
        );
      },
    },
    {
      dataIndex: "processingFee",
      title: "Processing Fees",
      render: (text, record) => {
        return (
          <>{record?.applicationLoanAccount?.sanctionedLoanPF?.cur() || "-"}</>
        );
      },
    },
    {
      dataIndex: "underwriter",
      title: "Underwriter",
      render: (text, record) => {
        return <>{record?.underwriter?.name || "-"}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.status?.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const activeColumns = [
    {
      dataIndex: "borrowerName",
      title: "Customer Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record.borrowerName}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "LAN No.",
      render: (text, record) => {
        return <>{record?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanStartDate",
      title: "Loan Start Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "sanctionedLoanEndDate",
      title: "Loan End Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanPaymentMode",
      title: "Payment Type",
    },
    {
      dataIndex: "application",
      title: "Channel",
      render: (text, record) => {
        return <>{record?.application?.channel || "-"}</>;
      },
    },
    {
      dataIndex: "loanAccountStatus",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.application?.status?.toLowerCase()] ||
          "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.application?.status?.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "signzyLoanAgreementLink",
      title: "KFS",
      render: (text, record) => {
        return record?.isLoanAgreementSigned === true ? (
          <div className="docs_card">
            <a
              href={text}
              target="_blank"
              className="text-primary text-underline"
            >
              Download
            </a>
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Loan Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.loanAccountStatus?.toLowerCase()] ||
          "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.loanAccountStatus?.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const closedColumns = [
    {
      dataIndex: "borrowerName",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record.borrowerName}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "Application no",
      render: (text, record) => {
        return <>{record?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanStartDate",
      title: "Loan Start Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "sanctionedLoanEndDate",
      title: "Loan End Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "tenure",
      title: "Tenure",
      render: (text, record) => {
        return <>{record?.sanctionedLoanTenure || "-"}</>;
      },
    },
    {
      dataIndex: "waiverAmount",
      title: "Waiver",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "application",
      title: "Channel",
      render: (text, record) => {
        return <>{record?.application?.channel || "-"}</>;
      },
    },
    {
      dataIndex: "totalRepaymentAmtTillDate",
      title: "Total Paid Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "loanClosingDate",
      title: "Loan Closer Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
  ];
  const [columns, setColumns] = useState(disbursementColumns);
  const [activeKey, setActiveKey] = useState("");
  const filterTabData = [
    {
      key: "pending",
      name: "Pending for Disbursement",
    },
    {
      key: "active",
      name: "Active Loans",
    },
    {
      key: "closed",
      name: "Closed Loans",
    },
  ];

  const handleSelect = (e, key) => {
    let payload = {
      page: 1,
      limit: 10,
    };
    setSearch(payload);
    setActiveKey(key);
    navigate(`/app/loandetails?activetab=${key}`);
  };

  const onPageChange = (pageData) => {
    setSearch((prevState) => ({
      ...prevState,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    }));
  };

  const getPendingDisbursedApplications = (params = {}) => {
    let payload = { ...params };

    props.apis
      .apiGetAllUnderWriterPredisbursedApplications(payload)
      .then(async ({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getActiveApplications = (params = {}) => {
    let payload = { ...params };

    props.apis.apiGetAllActiveLoans(payload).then(({ data, total }) => {
      console.log({ data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const getClosedApplications = (params = {}) => {
    let payload = { ...params };

    props.apis.apiGetAllClosedLoans(payload).then(({ data, total }) => {
      console.log({ data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const edit = (record) => {
    console.log({ record });
    let { applicationLoanAccount } = record;
    let path;
    if (activeKey === "pending") {
      path = `/app/loandetailspendingview?id=${applicationLoanAccount?.applicationId}&lanid=${applicationLoanAccount?.loanAccountNumber}&name=${applicationLoanAccount?.borrowerName}&status=${activeKey}&frompath=loandetails`;
    } else if (activeKey === "active") {
      path = `/app/loandetailsactiveview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails`;
    }
    navigate(path);
  };

  const view = (record) => {
    console.log({ record });
    let { applicationLoanAccount } = record;

    let path;
    if (activeKey === "pending") {
      path = `/app/loandetailspendingview?id=${applicationLoanAccount?.applicationId}&lanid=${applicationLoanAccount?.loanAccountNumber}&name=${applicationLoanAccount?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    } else if (activeKey === "active") {
      path = `/app/loandetailsactiveview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    } else if (activeKey === "closed") {
      path = `/app/loandetailsclosedview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    }
    navigate(path);
  };

  const handleManualDisbursement = (record) => {
    console.log({ record });
    let { applicationId, loanAccountNumber, borrowerName } =
      record?.applicationLoanAccount;
    let path = `/app/manualdisbursementview?id=${applicationId}&lanid=${loanAccountNumber}&name=${borrowerName}&status=${activeKey}`;
    navigate(path);
  };

  const updatePendingRecord = (record) => {
    let id = record?.applicationLoanAccount?.loanAccountNumber;

    props.apis.apiGetTransactionStatus({ refereceId: id }).then((res) => {
      console.log({ res });
    });
  };
  // ----------------------------------------------------------------

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (record) => {
    setShow(true);
    setCurrentNoc(record);
  };
  const [currentNoc, setCurrentNoc] = useState({});

  const generateNOC = () => {
    let payload = {
      loanAccountNumber: currentNoc?.loanAccountNumber,
    };
    props.apis.apiGenerateNocToCustomer(payload).then((res) => {
      if (res) {
        swal2.fire({
          title: "NOC Generated Successfully",
          icon: "success",
          iconColor: "#DE3232",
          timer: 3000,
          confirmButtonText: "Close",
        });
        handleClose();
      }
    });
  };

  const handleViewLogs = (record) => {
    console.log({ viewLogs: record });
  };
  // ----------------------------------------------------------------

  const downloadLoanDetails = () => {
    let payload = {};
    payload.status = activeKey?.toUpperCase();

    let startDateValue = document.querySelector("[name='startDate']")?.value;
    let endDateValue = document.querySelector("[name='endDate']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }

    props.apis.apiExportLoanDetails(payload).then(async (res) => {
      // console.log({ res });
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `loan_details_${new Date().toDate()}.xlsx`
        );
        // console.log(link);
        link.click();
      }
    });
  };
  // ----------------------------------------------------------------

  const getData = (payload) => {
    // clear table data from previous request
    setTableData([]);

    if (activeKey === "pending") {
      setColumns(disbursementColumns);
      getPendingDisbursedApplications(payload);
    } else if (activeKey === "active") {
      setColumns(activeColumns);
      getActiveApplications(payload);
    } else if (activeKey === "closed") {
      setColumns(closedColumns);
      getClosedApplications(payload);
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  useEffect(() => {
    setColumns([]);
    setTableData([]);
    setRowcount(0);

    let payload = {
      ...search,
    };

    const str = window.location.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    console.log({ params });

    if (params.hasOwnProperty("status")) {
      payload.status = params?.status;
    } else if (params.hasOwnProperty("channel")) {
      payload.channel = params?.channel;
    }

    console.log({ payload, search });
    getData(payload);
  }, [activeKey, search]);

  useEffect(() => {
    let paramsActiveTab = props.urlparams.params.activetab;
    if (paramsActiveTab) {
      setActiveKey(paramsActiveTab);
    } else if (isCollections) {
      setActiveKey("active");
      navigate(`/app/loandetails?activetab=active`);
    } else {
      setActiveKey("pending");
      navigate(`/app/loandetails?activetab=pending`);
    }
    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Loan details"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="filter_tab_container">
          {filterTabData
            ?.filter((v) => (isCollections ? v.key !== "pending" : v))
            ?.map((btn) => {
              return (
                <>
                  <button
                    type="button"
                    id={btn.key}
                    className={Object.className({
                      active: activeKey === btn.key,
                    })}
                    onClick={(...args) => handleSelect(...args, btn.key)}
                  >
                    {btn.name}
                  </button>
                </>
              );
            })}
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <LoanDetailsSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    activeKey={activeKey}
                  />
                </div>
                <div
                  className={Object.className({
                    "header_btn_box my-3": true,
                    hidden:
                      !isAdmin ||
                      (activeKey !== "active" && activeKey !== "closed"),
                  })}
                >
                  <a
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadLoanDetails(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Bulk Download</span>
                  </a>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(e) => view(e)}
                  {...(!isAdmin
                    ? { editable: false }
                    : isAdmin && activeKey === "pending"
                    ? {
                        updateable: true,
                        updateAction: (e) => updatePendingRecord(e),
                        editAction: (e) => edit(e),
                        manualDisbursement: true,
                        manualDisbursementAction: (e) =>
                          handleManualDisbursement(e),
                      }
                    : isAdmin && activeKey === "closed"
                    ? {
                        issueNocLink: true,
                        editable: false,
                        issueNocLinkAction: (...args) => handleShow(...args),
                      }
                    : isCollections
                    ? {
                        viewlogs: true,
                        editable: true,
                        viewLogsAction: (...args) => handleViewLogs(...args),
                        editAction: (e) => edit(e),
                      }
                    : {
                        editAction: (e) => edit(e),
                      })}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PREVIEW MODAL */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        className="preview_agreement_modal noc_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="py-0">
          <div className="heading_box">
            <Modal.Title>AFI- Dhanvriddhi Loans</Modal.Title>
            <Modal.Title>No Objection Certificate</Modal.Title>
          </div>
          <p className="date">
            Date : <span>{new Date().dateToDDMMYYYY(new Date())}</span>
          </p>
          <p className="loanNum">
            Loan No. : <span>{currentNoc?.loanAccountNumber}</span>
          </p>
          <br />
          <br />
          <p className="para_title">Mr/Ms</p>
          <p className="paragraph_text">
            This is to certify that
            <span>{currentNoc?.borrowerName}</span> who had taken a short-term
            loan from Ayaan Finserve India Private Limited for Rs.
            <span>{currentNoc?.sanctionedLoanAmount?.cur()}</span>. on
            <span>
              {new Date(currentNoc?.sanctionedLoanStartDate).dateToDDMMYYYY(
                new Date(currentNoc?.sanctionedLoanStartDate)
              )}
            </span>
            has repaid Rs.
            <span>{currentNoc?.sanctionedLoanAmount?.cur()}</span>. on
            <span>
              {new Date(currentNoc?.loanClosingDate).dateToDDMMYYYY(
                new Date(currentNoc?.loanClosingDate)
              )}
            </span>
            This is the full amount which was due from him/her, including
            interest. Hence, there are no more dues from
            <span>{currentNoc?.borrowerName}</span> against loan taken by her
            from Ayaan Finserve India Private Limited.
          </p>
          <br />
          <br />

          <img src={imagepaths.nocLogo} alt="Ayaan Stamp" />
          <br />
          <br />

          <p>Ayaan Finserve India Private Limited</p>
          <p>A-118 , Ground Floor , Vikas Marg, Shakarpur, Delhi- 110092</p>
          <p>Email - info@afiloans.co.in</p>
          <p>Website - https://afiloans.co.in</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-primary-lite"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={(...args) => generateNOC(...args)}
          >
            Generate
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default constore(LoanDetails);
